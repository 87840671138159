import { AuthContext } from '@/api/auth';
import {
  chargeSinglePaymentViaVivaPay,
  getMember,
  getMemberContractByMemberId,
} from '@/api/mutation';
import PrimaryOutlinedButton from '@/common/buttons/PrimaryOutlinedButton';
import ModalBase from '@/common/modals/ModalBase';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { Auth, Storage } from 'aws-amplify';
import { Link, navigate } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { toastError, toastSuccess } from '@/common/toasts/toast';
import ModalMakePayment from '@/common/payment/modalMakePayment';
import ThreeDot from '@/common/loading/ThreeDot';

function Sidebar() {
  const [chargeSinglePayment] = useMutation(chargeSinglePaymentViaVivaPay);
  const [outStandingModalPay, setOutStandingModalPay] = useState(false);
  const sidebarMenu = [
    { name: 'dashboard', link: 'dashboard', id: '' },
    // { name: 'my bookings', link: 'bookings', id: 'bookings' },
    // { name: 'timetable', link: 'timetable', id: 'timetable' },
    {
      name: 'Classes',
      link: '/dashboard/class/timetable',
      id: '',
      tabs: [
        { name: 'TIMETABLE', link: 'dashboard/class/timetable' },
        { name: 'MY BOOKINGS', link: 'dashboard/class/bookings' },
      ],
    },
    { name: 'my membership', link: 'memberships', id: 'memberships' },
    {
      name: 'my profile',
      link: 'account',
      id: 'account',
      tabs: [
        { name: 'General', link: 'dashboard/account' },
        { name: 'Password', link: 'dashboard/account/password' },
        { name: 'Biling', link: 'dashboard/account/biling' },
        { name: 'Notifications', link: 'dashboard/account/notifications' },
      ],
    },
    // { name: 'upgrade', link: 'upgrade', id: 'upgrade' },
    {
      name: 'member benefits',
      link: 'benefits',
      id: 'benefits',
      isNotReady: true,
    },
    {
      name: 'Club Search',
      link: 'club-search',
      id: 'club-search',
      isNotReady: true,
    },
    // { name: 'log out', link: '', id: 'logout' },
  ];
  const [expand, setExpand] = useState<boolean>(false);
  const [classExpand, setExpandClass] = useState<boolean>(false);
  const [getMembership] = useLazyQuery(getMemberContractByMemberId);
  // const [userInfoMemberShip, setUserInfoMemberShip] = useState();
  const { userInfoMemberShip, setUserInfoMemberShip, setUserInfo } =
    React.useContext(AuthContext);
  useEffect(() => {
    console.log(userInfoMemberShip);
  }, [userInfoMemberShip]);
  const mountedFunction = () => {
    setfetching(true);
    try {
      // Amplify.configure({
      //   Auth: {
      //     region: `${process.env.AWS_REGION}`,
      //     aws_project_region: `${process.env.AWS_REGION}`,
      //     aws_appsync_graphqlEndpoint: `${process.env.AWS_ENDPOINT}`,
      //     aws_appsync_region: `${process.env.AWS_REGION}`,
      //     aws_appsync_authenticationType: `${process.env.AWS_SIGNIN_AUTH_TYPE}`,
      //     aws_user_pools_id: `${process.env.AWS_USER_POOLS_ID}`,
      //     aws_user_pools_web_client_id: `${process.env.AWS_USER_POOLS_WEB_CLIENT_ID}`,
      //     aws_user_files_s3_bucket: `${process.env.AWS_S3_BUCKET_NAME}`,
      //     aws_user_files_s3_bucket_region: `${process.env.AWS_S3_BUCKET_REGION}`,
      //   },
      // });
      // const memberInfo = JSON.parse(localStorage.getItem("currentUserDetail"));
      Auth.currentAuthenticatedUser()
        .then((user) => {
          client
            .query({
              query: getMember,
              variables: {
                memberId: user.attributes['custom:memberId'],
              },
            })
            .then(async (res) => {
              const data = res.data.getMember;
              const imgUrl = data?.imageUrl
                ? await Storage.get(data.imageUrl)
                : '/icons/Dashboard/profile.svg';
              setUserInfo({ ...data, imageUrl: imgUrl });
              localStorage.setItem(
                'currentUserDetail',
                JSON.stringify(res.data.getMember)
              );
            });
          getMembership({
            variables: {
              memberId: user.attributes['custom:memberId'],
            },
          })
            .then((membership) => {
              setUserInfoMemberShip(
                membership.data.getMemberContractByMemberId.items.filter(
                  (i) => {
                    if (i.expiryDateTime != null) {
                      if (
                        new Date(i.expiryDateTime).valueOf() <
                        new Date().valueOf()
                      ) {
                        return false;
                      }
                    }

                    if (i.endDateTime != null) {
                      if (
                        new Date(i.endDateTime).valueOf() < new Date().valueOf()
                      ) {
                        return false;
                      }
                    }

                    return true;
                  }
                )
              );
            })
            .catch((error) => {
              console.log(error);
              setfetching(true);
            })
            .finally(() => {
              setfetching(false);
            });
        })
        .catch((e) => {});
    } catch (e) {
      console.log(e);
      navigate('/');
    }
  };
  useEffect(() => {
    if (!userInfoMemberShip) mountedFunction();
  }, []);
  const client = useApolloClient();

  const refresh = useContext(AuthContext).refreshData;
  const setRefresh = useContext(AuthContext).setRefreshData;
  useEffect(() => {
    if (refresh) {
      client
        .refetchQueries({
          include: ['getMember'], // Consider using "active" instead!
        })
        .then(() => {
          mountedFunction();
          setRefresh(false);
        });
    }
  }, [refresh]);

  const Init = React.useContext(AuthContext).Init;

  const userInfo = React.useContext(AuthContext).userInfo;

  const [modal, setModal] = useState(false);

  const membershipStatus = React.useMemo(() => {
    const result: Record<string, any> = {
      status: null,
      // remainingDay: null,
      // requestDate: null,
      // cancellationDate: null,
      // suspendEndDate: null,
      color: '',
      title: '',
    };

    const membershipItem = userInfoMemberShip?.[0];

    const activeSuspensionItem = membershipItem?.suspensions?.items?.filter(
      (e) => e.cancelledDateTime === null && e.suspensionStatus === 'APPROVED'
    )?.[0];

    const currentDate = moment().format('YYYY-MM-DD');
    console.log(
      membershipItem,
      membershipItem?.expiryDateTime !== null,
      moment(currentDate).isSame(membershipItem?.expiryDateTime),
      moment(currentDate).isAfter(membershipItem?.expiryDateTime)
    );
    if (
      !membershipItem ||
      (membershipItem?.expiryDateTime !== null &&
        (moment(currentDate).isSame(membershipItem?.expiryDateTime) ||
          moment(currentDate).isAfter(membershipItem?.expiryDateTime)))
    ) {
      result.status = 'canceled';
      result.color = 'bg-red-500';
      result.title = 'no Active membership';
    } else if (
      membershipItem?.expiryDateTime !== null &&
      moment(currentDate).isBefore(membershipItem?.expiryDateTime)
    ) {
      result.status = 'pendingCancellation';
      result.color = 'bg-red-500';
      result.title = 'membership cancellation pending';
      result.remainingDay = moment(membershipItem?.expiryDateTime).diff(
        currentDate,
        'days'
      );
    } else if (
      activeSuspensionItem &&
      activeSuspensionItem.suspensionStatus.toLowerCase() !== 'rejected' &&
      activeSuspensionItem &&
      moment(currentDate).isBefore(
        activeSuspensionItem.suspensionStartDateTime
      ) &&
      moment(currentDate).isBefore(
        activeSuspensionItem.suspensionEndDateTime
      ) &&
      currentDate.split('T')[0] !==
        activeSuspensionItem.suspensionStartDateTime.split('T')[0]
    ) {
      result.status = 'pendingSuspension';
      result.color = 'bg-[#FF6900]';
      result.title = 'membership suspension pending';
    } else if (
      activeSuspensionItem &&
      activeSuspensionItem.suspensionStatus.toLowerCase() === 'approved' &&
      (moment(currentDate).isBetween(
        activeSuspensionItem.suspensionStartDateTime,
        activeSuspensionItem.suspensionEndDateTime,
        undefined,
        '[]'
      ) ||
        currentDate.split('T')[0] ===
          activeSuspensionItem.suspensionStartDateTime.split('T')[0] ||
        currentDate.split('T')[0] ===
          activeSuspensionItem.suspensionEndDateTime.split('T')[0])
    ) {
      result.status = 'suspended';
      result.color = 'bg-[#FF6900]';
      result.title = 'membership suspended';
    } else if (
      membershipItem.isPending ||
      (currentDate.split('T')[0] !==
        membershipItem.startDateTime.split('T')[0] &&
        moment(currentDate).isBefore(membershipItem.startDateTime))
    ) {
      result.title = userInfoMemberShip?.length + ' future  membership';
      result.color = 'bg-[#FF6900]';
      result.status = 'future';
    } else {
      result.color = 'bg-primary';
      result.status = 'active';
      result.title = userInfoMemberShip?.length + ' active membership';
    }

    return result;
  }, [userInfoMemberShip]);
  const [confirmPay, setConfirmPay] = useState(false);
  const [loading, setLoading] = useState(false);
  const handlePay = async () => {
    setLoading(true);
    try {
      await chargeSinglePayment({
        variables: {
          amount: userInfo?.outstandingBalance,
          memberId: userInfo?.memberId,
        },
      });
      toastSuccess('Thank you for paying outstanding balance');
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };
  const [fetching, setfetching] = useState(false);
  return (
    <>
      {outStandingModalPay && (
        <ModalMakePayment
          changeMembershipData={{
            newMemberShipId: '',
            changeMembershipType: '',
            locationId: userInfoMemberShip[0]?.locationId,
          }}
          setModalMakePayment={setOutStandingModalPay}
          amount={userInfo?.outstandingBalance}
          paymentType="outstanding"
          openModalOutside={setOutStandingModalPay}
          setStep={() => {}}
        />
      )}
      <div className="right-[26px] pl-[20px] rounded-l-full backdrop-blur-md top-[36px] absolute flex sm:hidden gap-[20px] items-center z-30">
        {/*<div className="relative">*/}
        {/*  <svg*/}
        {/*    width="23"*/}
        {/*    height="26"*/}
        {/*    viewBox="0 0 23 26"*/}
        {/*    fill="none"*/}
        {/*    xmlns="http://www.w3.org/2000/svg"*/}
        {/*  >*/}
        {/*    <path*/}
        {/*      d="M22.7376 20.1096C21.9933 19.4461 21.3417 18.6854 20.8003 17.8482C20.2093 16.6924 19.8551 15.4302 19.7584 14.1357V10.323C19.7635 8.28973 19.026 6.3246 17.6843 4.79679C16.3427 3.26899 14.4893 2.28368 12.4725 2.02601V1.03037C12.4725 0.757098 12.3639 0.49502 12.1707 0.301788C11.9775 0.108557 11.7154 0 11.4421 0C11.1688 0 10.9068 0.108557 10.7135 0.301788C10.5203 0.49502 10.4117 0.757098 10.4117 1.03037V2.04144C8.41295 2.3177 6.58199 3.30896 5.25799 4.83163C3.93399 6.3543 3.20667 8.3052 3.21074 10.323V14.1357C3.11407 15.4302 2.75983 16.6924 2.16879 17.8482C1.63692 18.6835 0.995788 19.4441 0.262416 20.1096C0.180088 20.1819 0.114106 20.2709 0.0688591 20.3707C0.0236124 20.4705 0.000138769 20.5788 0 20.6884V21.7381C0 21.9428 0.0813157 22.1391 0.226058 22.2838C0.370801 22.4286 0.567115 22.5099 0.771812 22.5099H22.2282C22.4329 22.5099 22.6292 22.4286 22.7739 22.2838C22.9187 22.1391 23 21.9428 23 21.7381V20.6884C22.9999 20.5788 22.9764 20.4705 22.9311 20.3707C22.8859 20.2709 22.8199 20.1819 22.7376 20.1096ZM1.60537 20.9663C2.32347 20.2726 2.95573 19.4952 3.48859 18.6508C4.23309 17.255 4.66748 15.7149 4.76208 14.1357V10.323C4.73147 9.41845 4.8832 8.517 5.20822 7.67233C5.53325 6.82765 6.02493 6.05701 6.65398 5.40631C7.28304 4.75561 8.0366 4.23815 8.8698 3.88474C9.70301 3.53133 10.5988 3.34921 11.5039 3.34921C12.4089 3.34921 13.3047 3.53133 14.1379 3.88474C14.9711 4.23815 15.7247 4.75561 16.3537 5.40631C16.9828 6.05701 17.4745 6.82765 17.7995 7.67233C18.1245 8.517 18.2762 9.41845 18.2456 10.323V14.1357C18.3402 15.7149 18.7746 17.255 19.5191 18.6508C20.052 19.4952 20.6842 20.2726 21.4023 20.9663H1.60537Z"*/}
        {/*      fill="white"*/}
        {/*    />*/}
        {/*    <path*/}
        {/*      d="M11.5392 25.0877C12.0254 25.0765 12.4919 24.8938 12.8563 24.5717C13.2207 24.2497 13.4595 23.8091 13.5304 23.328H9.4707C9.54362 23.8222 9.79357 24.2731 10.1741 24.5968C10.5545 24.9205 11.0397 25.095 11.5392 25.0877Z"*/}
        {/*      fill="white"*/}
        {/*    />*/}
        {/*  </svg>*/}

        {/*  <div className="w-[11px] h-[11px] bg-[#FF0000] rounded-full absolute top-0"></div>*/}
        {/*</div>*/}
        <div className="cursor-pointer" onClick={() => navigate('/dashboard/account')}>
          <img
            className="rounded-full border border-white max-w-[40px] max-h-[40px] w-[40px] h-[40px]"
            src={userInfo?.imageUrl}
            alt="user avatar"
          />
        </div>
      </div>
      <div className="w-[339px] h-[calc(100vh-40px)] bg-[#13181b] border-r border-gray sm:hidden">
        <div
          className="ml-[24px] mt-11 mb-6 w-[269px] h-[26px]"
          onClick={() => navigate('/')}
        >
          <img
            className="block sm:hidden  mx-auto w-full h-full object-contain"
            src="/icons/pf-logo-line.svg"
          />
        </div>
        {fetching ? (
          ''
        ) : (
          <div className="ml-[24px] mb-5">
            <p className="text-xs text-[#848484] leading-[19px]">Member name</p>
            <p className=" text-white font-bold text-[25px] leading-[25px] font-bicyclette">
              {userInfo?.surname && userInfo?.givenName
                ? userInfo?.givenName + ' ' + userInfo?.surname
                : 'name'}
            </p>
          </div>
        )}
        <div className="mb-[40px] ml-[26px] ">
          {fetching ? (
            <>
              <div className=" relative " data-title="dot-pulse">
                <div className="stage w-[200px]">
                  <div className="dot-pulse"></div>
                </div>
              </div>
            </>
          ) : (
            <>
              {' '}
              {userInfo?.isBlocked ? (
                <div
                  className={`flex uppercase items-center mb-2 justify-center w-max px-3 py-1 rounded-[2px] h-[18px] text-white text-[10px] text-center font-bold uppercase bg-textError`}
                >
                  YOUR MEMBER CARD IS BEING BLOCKED
                </div>
              ) : (
                <div
                  className={`flex items-center justify-center w-max px-3 py-1 rounded-[2px] h-[18px] text-white text-[10px] text-center font-bold uppercase ${membershipStatus.color}`}
                >
                  {membershipStatus.title}
                </div>
              )}
              {userInfo?.outstandingBalance && (
                <div
                  onClick={() => {
                    setOutStandingModalPay(true);
                  }}
                  className={`flex uppercase mt-2 items-center mb-2 justify-center w-max px-3 py-1 rounded-[2px] h-[18px] text-white text-[10px] text-center font-bold uppercase bg-textError`}
                >
                  outstanding:${userInfo?.outstandingBalance}
                </div>
              )}
              {membershipStatus?.remainingDay && (
                <p className="text-textSecondary text-left text-[10px] font-bold uppercase mt-2">
                  {membershipStatus?.remainingDay} DAYS REMAINING
                </p>
              )}
            </>
          )}
        </div>

        <div className="flex flex-col h-[calc(100vh-290px)] overflow-auto hiddenScrollWidth ">
          {sidebarMenu.map((item: any, index: number) => (
            <div key={index} className="">
              {item.name == 'Classes' ? (
                <div>
                  <div
                    onClick={() => {
                      userInfoMemberShip?.length > 0 && setExpandClass(true);
                      userInfoMemberShip?.length > 0 && setExpand(false);
                      // navigate(item.link);
                    }}
                    key={index}
                    className={`cursor-pointer tracking-[1.4px] border-b border-[rgba(255,255,255,0.4)] pl-[24px] flex items-center h-[43px] text-sm font-medium leading-[19.6px] uppercase
                      ${
                        window.location.pathname.split('/').includes('class')
                          ? 'border-r-[5px] border-r-primary bg-[#292929] text-white'
                          : userInfoMemberShip?.length > 0
                          ? 'hover:bg-[#1F2528] text-white'
                          : 'text-[#595d5f]'
                      }
                      `}
                  >
                    {item.name}
                  </div>
                  <div
                    className={`overflow-hidden ${
                      classExpand
                        ? 'h-auto opacity-100  transition-all'
                        : 'h-0 opacity-0 transition-all'
                    }`}
                  >
                    {item.tabs.map((item: any, index: number) => (
                      <Link to={`/${item.link}`} key={index}>
                        <p
                          className={`h-[40px]  flex items-center ${
                            window.location.pathname == '/' + item.link
                              ? 'bg-[#292929] font-bold '
                              : 'font-normal'
                          } pl-[26px] border-b border-[rgba(255,255,255,0.4)]  text-white capitalize text-sm flex items-center`}
                        >
                          {item.name}
                        </p>
                      </Link>
                    ))}
                  </div>
                </div>
              ) : (
                <Link
                  key={index}
                  to={
                    userInfoMemberShip?.length > 0 &&
                    `${item.isNotReady ? '' : '/dashboard'}${
                      index != 0 && !item.isNotReady ? '/' : ''
                    }${item.isNotReady ? '' : item.id}`
                  }
                >
                  <div
                    key={index}
                    className={` h-[43px] cursor-pointer border-b border-[rgba(255,255,255,0.4)] pl-6 font-medium text-sm flex items-center tracking-[0.1em] uppercase 
                    ${index === 0 ? 'border-y' : ''} ${
                      window.location.pathname.split('/')[
                        window.location.pathname.split('/').length - 1
                      ] === item.link
                        ? 'border-r-[5px] border-r-primary bg-[#292929] text-white'
                        : userInfoMemberShip?.length > 0
                        ? 'text-white hover:bg-[#1F2528] '
                        : window.location.pathname.split('/')[
                            window.location.pathname.split('/').length - 1
                          ] === 'dashboard' &&
                          window.location.pathname.split('/')[
                            window.location.pathname.split('/').length - 1
                          ] === item.link
                        ? ' text-white hover:bg-[#1F2528] '
                        : 'text-[#595d5f] '
                    } ${
                      item.isNotReady
                        ? 'grid grid-cols-3  pr-[19px] items-center'
                        : ''
                    }`}
                  >
                    <div
                      className={` font-medium text-sm tracking-[1.4px] ${
                        item.isNotReady ? 'col-span-2' : ''
                      }`}
                    >
                      {item.name}
                    </div>
                    {item.isNotReady && (
                      <p className="text-center w-[84px] h-[14px] flex items-center justify-center text-[#848484] text-[8px] border border-[#848484] rounded-[4px]">
                        Coming Soon!
                      </p>
                    )}
                  </div>
                </Link>
              )}
            </div>
          ))}
          {/* <div className="mx-6 mb-6 mt-auto">
        <img src={adSquare} />
      </div> */}
          <div
            onClick={() => setModal(true)}
            className={`cursor-pointer h-[46px]  tracking-[1.4px] font-medium text-sm mt-auto text-white hover:bg-[#1F2528] flex justify-self-end border-t justify-between items-center  border-b border-[rgba(255,255,255,0.4)] pl-[26px] pt-[12px] pb-[12px] leading-[19.6px] uppercase                   `}
          >
            LOG OUT
            {/* <img className="w-6 h-6 mr-[17px]" src={logoutIcon} /> */}
            <svg
              className="w-6 h-6 mr-[17px]"
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.1429 5.71429V2.5C12.1429 1.67157 11.4713 1 10.6429 1H2.5C1.67157 1 1 1.67157 1 2.5V17.5C1 18.3284 1.67157 19 2.5 19H10.6429C11.4713 19 12.1429 18.3284 12.1429 17.5V13.4286"
                stroke="white"
              />
              <path
                d="M16.5645 5.51074L20.7148 9.66112L16.5645 13.8115"
                stroke="white"
              />
              <path d="M20.7143 9.57129L7 9.57129" stroke="white" />
            </svg>
          </div>
          {/*<div*/}
          {/*  onClick={() => {}}*/}
          {/*  className={`cursor-pointer h-[46px]  hover:bg-[#1F2528] text-white flex justify-self-end border-t border-t-transparent justify-between items-center  border-b border-[rgba(255,255,255,0.4)] pl-[26px] pt-[12px] pb-[12px] leading-[19.6px] tracking-[1.4px] uppercase text-sm font-medium`}*/}
          {/*>*/}
          {/*  help{' '}*/}
          {/*  <svg*/}
          {/*    className="mr-6"*/}
          {/*    width="19"*/}
          {/*    height="19"*/}
          {/*    viewBox="0 0 19 19"*/}
          {/*    fill="none"*/}
          {/*    xmlns="http://www.w3.org/2000/svg"*/}
          {/*  >*/}
          {/*    <circle cx="9.34772" cy="9.34772" r="8.65241" stroke="white" />*/}
          {/*    <path*/}
          {/*      d="M6.95703 7.55272C6.95703 7.55272 6.99057 6.75337 7.73825 6.11244C8.1822 5.7319 8.71535 5.62169 9.19307 5.61491C9.62933 5.60934 10.0192 5.68612 10.2524 5.80433C10.6509 6.00731 11.4291 6.50146 11.4291 7.55272C11.4291 8.65896 10.7495 9.16037 9.97685 9.71289C9.20425 10.2654 9.00674 10.8073 9.00674 11.4283"*/}
          {/*      stroke="white"*/}
          {/*      strokeMiterlimit="10"*/}
          {/*      strokeLinecap="round"*/}
          {/*    />*/}
          {/*    <path*/}
          {/*      d="M9.19135 13.2172C9.43834 13.2172 9.63856 13.0169 9.63856 12.77C9.63856 12.523 9.43834 12.3228 9.19135 12.3228C8.94436 12.3228 8.74414 12.523 8.74414 12.77C8.74414 13.0169 8.94436 13.2172 9.19135 13.2172Z"*/}
          {/*      fill="white"*/}
          {/*    />*/}
          {/*  </svg>*/}
          {/*</div>*/}
        </div>
        {modal && (
          <ModalBase closeModal={setModal}>
            <div className="sm:w-unset w-[700px] h-[400px] flex flex-col items-center justify-center">
              <h2 className="text-[40px] font-bold text-center sm:px-6 sm:text-2xl px-24 leading-[90%] text-white uppercase font-bicyclette">
                are you sure you
                <br /> want to log out?
              </h2>
              <div className="flex items-center mt-[30px] justify-center gap-[20px] ">
                <PrimaryOutlinedButton
                  onClick={() => {
                    window.localStorage.removeItem('userInfo');
                    window.localStorage.removeItem('login');
                    navigate('/login');
                    Auth.signOut().then((res) => {
                      Init();
                    });
                  }}
                  className="rounded-md w-[108px] !px-0 uppercase"
                >
                  log out
                </PrimaryOutlinedButton>
                <PrimaryOutlinedButton
                  onClick={() => setModal(false)}
                  className="rounded-md w-[108px] !px-0 uppercase"
                >
                  cancel
                </PrimaryOutlinedButton>
              </div>
            </div>
          </ModalBase>
        )}
      </div>
    </>
  );
}

export default Sidebar;
