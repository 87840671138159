import React from 'react';
import arrowRight from '../../assets/images/arrowRight.svg';
import arrowLeft from '../../assets/images/arrowLeft.svg';
interface IProps {
  text: string;
  isRight: boolean;
  onclick: () => void;
  noArr: boolean;
  className:string;
}
function ArrowButton(props: IProps) {
  const { isRight, text, onclick, noArr, className } = props;
  return (
    <>
      <div
        className={` ${
          !isRight ? 'flex-row-reverse' : 'flex-row'
        } border border-white hover:bg-primary h-full transition-all flex items-center justify-between rounded`}
        onClick={() => {
          onclick && onclick();
        }}
      >
        {text && (
          <button className={`text-[11px] gotham text-white uppercase px-10 h-[40px] ${className}`}>
            {text}
          </button>
        )}
        {!noArr && (
          <span
            className={`${
              isRight ? 'border-l border-l-white' : 'border-r border-r-white'
            } ${
              !text ? ' !border-r-0 ' : ' '
            } px-3 flex  h-[40px] items-center justify-center`}
          >
            <img
              className="w-auto h-auto"
              src={isRight ? arrowRight : arrowLeft}
              alt="icon"
            />
          </span>
        )}
      </div>
    </>
  );
}

export default ArrowButton;
