import ArrowButton from '@/common/buttons/ArrowButton';
import CheckboxActive from '@/common/buttons/CheckboxActive';
import MobileButton from '@/common/buttons/MobileButton';
import InputBase from '@/common/inputs/InputBase';
import { toastError } from '@/common/toasts/toast';
import { Amplify, Auth } from 'aws-amplify';
import { navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { breakpoints } from '@/constants';
import useWindowSize from '@/hooks/useWindowSize';

import { AuthContext } from '@/api/auth';

function Login() {
  const Init = React.useContext(AuthContext).Init;

  const { width } = useWindowSize();
  const [status, setStatus] = useState('login');
  // const status = "login";

  const [account, setAccount] = useState({ email: '', password: '' });
  const [isChecked, setChecked] = useState(false);

  const handleChangeValue = (e) => {
    setAccount({ ...account, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (account.password && account.email) {
        await Auth.signIn(account.email, account.password);
        await Init();
        window.localStorage.setItem('userInfo', 'Deval');
        window.localStorage.setItem('login', 'yes');
        navigate(`/dashboard`);
      } else {
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const [isLoading, setLoading] = useState(false);

  return (
    <>
      {isLoading && (
        <div className="fixed flex items-center h-[100vh] w-[100vw] z-50 bg-[rgba(212,212,212,0.2)] ">
          <div className="animate-spin h-12 w-12  border-r-2 border-2 border-gray border-r-[#2757FF] rounded-full m-auto "></div>
        </div>
      )}
      <div
        style={{
          background: `${
            width && width <= breakpoints.sm
              ? 'url(/images/LoginPage/login-mobile-background.png),linear-gradient(to bottom, #000 0%, #13181b 50%, #16182b 66%, #151947 100%)'
              : ''
          }`,
        }}
        className="bg-richBlack sm:bg-none h-[100vh] md:px-6 px-0 w-[100vw] flex items-center justify-center !bg-center !bg-no-repeat !bg-cover"
      >
        <div className="flex flex-col gap-6 sm:gap-7 z-20">
          <div className="hidden sm:block h-24 w-fit mb-8 mt-4">
            <img
              className="block w-full object-contain"
              src="/icons/PF-logo_stacked.png"
              alt="FF-logo_stacked"
            />
          </div>
          <div className='w-[495px] h-[47px]'>
            <img
              className="block sm:hidden  mx-auto w-full h-full object-contain"
              src="/icons/pf-logo-line.svg"
            />
          </div>

          <p className="hidden sm:block text-primary text-4xl leading-[38.5px] font-bicyclette font-bold uppercase">
            member portal login
          </p>
          <div className="block sm:hidden text-white uppercase md:text-[28px] text-[40px] leading-[80%] text-center mb-6 md:mt-0 mt-6 font-bicyclette font-bold">
            Member log in
          </div>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              handleSubmit();
            }}
            className="flex flex-col gap-6"
          >
            <button hidden type="submit"></button>
            <InputBase
              onchange={handleChangeValue}
              nameInput="email"
              holder="Email address"
              style="lg:w-full"
              isRequired={true}
              revertColor
            />
            <div>
              <InputBase
                onchange={handleChangeValue}
                nameInput="password"
                holder="Password"
                style="lg:w-full"
                isRequired={true}
                type="password"
                revertColor
              />
              <p className="block sm:hidden mt-[8px] underline text-gray cursor-pointer hover:text-white text-xs text-end">
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    navigate('/forgot-password');
                  }}
                >
                  Forgot your password?
                </span>
              </p>
            </div>
          </form>
          <div className="flex justify-between items-center sm:mb-8">
            <div className="flex gap-[12px] items-center">
              <CheckboxActive />
              <p className="text-white text-sm ">Remember me</p>
            </div>
            <div className="block sm:hidden">
              <ArrowButton
                text="NEXT"
                isRight={true}
                onclick={() => handleSubmit()}
              />
            </div>
          </div>

          <MobileButton
            onClick={handleSubmit}
            className="uppercase hidden sm:block"
          >
            LOG IN
          </MobileButton>

          <p
            onClick={() => navigate('/forgot-password')}
            className="hidden sm:block text-textSecondary text-center text-base leading-[47.5px] font-normal underline cursor-pointer"
          >
            Forgot your password?
          </p>
          <p className="block  text-white text-[16px] font-medium text-center">
            Don't have an account?{' '}
            <span
              className="cursor-pointer text-[16px] font-medium underline"
              onClick={() => navigate('/register')}
            >
              Join now
            </span>
          </p>
        </div>
        <div className="block sm:hidden absolute bottom-0 w-[100vw] h-[44vh] bg-gradient-to-t to-[rgba(36,51,213,0.0)] from-[rgba(36,51,213,0.34)]"></div>
      </div>
    </>
  );
}

export default Login;
