import React, { useState } from 'react';
interface IProps {
  text: string;
  isRight: boolean;
  onclick: () => void;
  noArr: boolean;
}
function ArrowButtonSecondary(props: IProps) {
  const { isRight, text, onclick, noArr } = props;
  const [effected, setEffected] = useState<boolean>(false);
  return (
    <>
      <div
        onMouseEnter={() => setEffected(true)}
        onMouseLeave={() => setEffected(false)}
        className={` ${
          !isRight ? 'flex-row-reverse' : 'flex-row'
        } border border-primary h-full hover:border-white bg-white hover:bg-primary transition-all flex items-center justify-between rounded`}
        onClick={() => {
          onclick && onclick();
        }}
      >
        <button
          className={`text-[11px] gotham  uppercase px-10 h-[40px] ${
            effected ? 'text-white' : 'text-primary'
          }`}
        >
          {text}
        </button>
        {!noArr && (
          <span
            className={`${
              isRight
                ? `border-l ${
                    !effected ? 'border-l-primary' : 'border-l-white'
                  }`
                : `border-r ${
                    !effected ? 'border-r-primary' : 'border-r-white'
                  }`
            } px-3 flex  h-[40px] items-center justify-center`}
          >
            {!isRight ? (
              <svg
                width="20"
                height="10"
                viewBox="0 0 20 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.49609 9L1.00408 4.82884L5.49609 0.65768"
                  stroke={effected ? 'white' : '#2757FF'}
                  stroke-width="1.2"
                />
                <path
                  d="M1.23682 5.02734L19.4023 5.02734"
                  stroke={effected ? 'white' : '#2757FF'}
                  stroke-width="1.2"
                />
              </svg>
            ) : (
              <svg
                width="20"
                height="10"
                viewBox="0 0 20 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.1055 1L18.5975 5.17116L14.1055 9.34232"
                  stroke={effected ? 'white' : '#2757FF'}
                  stroke-width="1.2"
                />
                <path
                  d="M18.3647 4.97266H0.199219"
                  stroke={effected ? 'white' : '#2757FF'}
                  stroke-width="1.2"
                />
              </svg>
            )}
          </span>
        )}
      </div>
    </>
  );
}

export default ArrowButtonSecondary;
