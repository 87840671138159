import { breakpoints } from '@/constants';
import useWindowSize from '@/hooks/useWindowSize';
import React from 'react';

function Footer() {
  const { width } = useWindowSize();

  return (
    <div className="h-[40px] sm:h-[78px] px-6 py-3 sm:py-0 bg-[#414141] sm:bg-richBlack flex items-center justify-between sm:flex-col sm:gap-y-2 sm:justify-center">
      <div className="text-[#5F5D5D] text-[10px] leading-[160%] sm:text-sm">
        © Plus Fitness 2023
      </div>
      {width && width <= breakpoints.sm ? (
        <div className="max-h-2 mb-2">
          <img className='block w-full object-contain' src="/icons/footer-logo-mobile.png" alt="footer-logo-mobile" />
        </div>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="115"
          height="13"
          viewBox="0 0 115 13"
          fill="none"
        >
          <path
            d="M35.9096 12.851L39.8294 12.847L34.3845 0.389893L32.6172 4.59199L35.9096 12.851Z"
            fill="white"
          />
          <path
            d="M30.5085 0.00397737L27.2578 8.13969L28.9735 12.3855L30.0617 9.8261L33.5268 9.81218L32.391 6.70386L31.3842 6.71579L34.2417 0L30.5085 0.00397737Z"
            fill="white"
          />
          <path
            d="M19.9219 0.0158182L25.4959 12.8389L28.7803 12.8349L27.1143 8.49558L23.7544 0.0118408L19.9219 0.0158182Z"
            fill="white"
          />
          <path
            d="M10.5866 0.0418548L7.33594 8.17757L9.04964 12.4234L14.3198 0.0358887L10.5866 0.0418548Z"
            fill="white"
          />
          <path
            d="M0 0.0534158L5.57401 12.8765L8.85845 12.8725L7.1924 8.53318L3.8325 0.0494385L0 0.0534158Z"
            fill="white"
          />
          <path
            d="M19.0205 0.0319404L15.3945 0.036377L15.4102 12.8654L19.0362 12.8609L19.0205 0.0319404Z"
            fill="white"
          />
          <path
            d="M43.2417 0.107422H41.7266V12.7952H50.3149V11.3455H43.2417V0.107422Z"
            fill="white"
          />
          <path
            d="M53.894 7.12351H60.7965V5.67376H53.894V1.55519H61.571V0.107422H52.3789V12.7952H61.6563V11.3455H53.894V7.12351Z"
            fill="white"
          />
          <path
            d="M65.5464 0.107422H64.0312V12.7952H65.5464V0.107422Z"
            fill="white"
          />
          <path
            d="M73.183 5.70962C70.2044 5.09511 69.9602 4.29964 69.9602 3.40672V3.37291C69.9602 2.2493 71.0523 1.43394 72.5595 1.43394C73.7982 1.41203 74.9978 1.86851 75.9095 2.70869L76.1478 2.9175L77.099 1.73423L76.8607 1.53536C75.5242 0.459484 74.2792 0.0219727 72.5933 0.0219727C70.2402 0.0219727 68.4649 1.49957 68.4649 3.45842V3.49223C68.4649 5.44711 69.7378 6.5389 72.7184 7.14346C75.6017 7.72415 75.836 8.49974 75.836 9.37675V9.41255C75.836 10.6237 74.726 11.4689 73.1354 11.4689C71.5448 11.4689 70.395 10.9955 69.0805 9.78245L68.8482 9.56767L67.8672 10.6972L68.0916 10.8961C69.6246 12.2683 71.1655 12.8848 73.0857 12.8848C75.5858 12.8848 77.3313 11.4211 77.3313 9.32903V9.29522C77.3313 7.40199 76.0525 6.29628 73.1811 5.71558"
            fill="white"
          />
          <path
            d="M88.4406 7.46755C88.4406 10.1026 87.1281 11.5523 84.7412 11.5523C82.3543 11.5523 80.9683 10.033 80.9683 7.37607V0.107422H79.4531V7.46556C79.4531 10.8682 81.4647 12.9822 84.7035 12.9822C87.9422 12.9822 89.9538 10.8284 89.9538 7.36215V0.107422H88.4367L88.4406 7.46755Z"
            fill="white"
          />
          <path
            d="M102.711 3.88195V3.84814C102.713 2.92792 102.353 2.04401 101.708 1.38814C100.882 0.560842 99.5933 0.107422 98.098 0.107422H92.7266V12.7952H94.2417V7.79568H97.7207L101.559 12.7952H103.479L99.4305 7.5471C101.496 7.10163 102.711 5.75728 102.711 3.88195ZM94.2417 1.55519H98.0464C100.018 1.55519 101.196 2.42623 101.196 3.88195V3.91576C101.196 5.40329 99.9388 6.36383 97.9948 6.36383H94.2417V1.55519Z"
            fill="white"
          />
          <path
            d="M106.687 11.3455V7.12351H113.589V5.67376H106.687V1.55519H114.364V0.107422H105.172V12.7952H114.449V11.3455H106.687Z"
            fill="white"
          />
        </svg>
      )}
    </div>
  );
}

export default Footer;
