import { breakpoints } from '@/constants';
import useWindowSize from '@/hooks/useWindowSize';
import React, { useEffect, useState } from 'react';
function CheckboxActive(props: any) {
  const { onChecked, isDashboard, isChecked } = props;
  const [isAccepted, setIsAccepted] = useState(false);
  useEffect(() => {
    if (typeof onChecked == 'function') {
      onChecked(isAccepted);
    }
  }, [isAccepted]);
  useEffect(() => {
    setIsAccepted(isChecked);
  }, [isChecked]);
  const width = useWindowSize();
  return (
    <button
      onClick={() => setIsAccepted(!isAccepted)}
      style={
        isAccepted
          ? width && width > breakpoints.sm
            ? isDashboard
              ? { border: '1px solid white', background: 'white' }
              : { border: '1px solid #2757FF' }
            : { border: '1px solid white', background: 'white' }
          : { border: '1px solid white', backgroundColor: '' }
      }
      className={`flex-none text-white font-bold rounded h-[30px] w-[30px] border-t border-l border-r border-white flex justify-center items-center`}
    >
      {isAccepted ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="10"
          viewBox="0 0 12 10"
          fill="none"
        >
          <path
            d="M1 4.23077L4.5 8L11 1"
            stroke={isDashboard ? 'black' : '#2757FF'}
            strokeWidth="1.88498"
          />
        </svg>
      ) : (
        ''
      )}
    </button>
  );
}

export default CheckboxActive;
