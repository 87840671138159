import React, { useState, createContext, useEffect, useRef } from 'react';
import { Amplify, Auth } from 'aws-amplify';
import { ApolloLink } from 'apollo-link';
import { createAuthLink } from 'aws-appsync-auth-link';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from '@apollo/client/link/context';
import { navigate } from 'gatsby';
import { ApolloClient, InMemoryCache } from '@apollo/client';

const AuthContext = createContext<any>(null);
interface iProps {
  children: React.ReactNode;
}
const AuthProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState();
  const [refreshClient, setRefresh] = useState(false);
  const [refreshData, setRefreshData] = useState(false);

  const [client, setClient] = useState<any>(null);

  const userIp = useRef();
  const fetchIpFromApi = async (url: string) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      return data;
    } catch (error) {
      console.log('Error fetching IP:', error);
      return null;
    }
  };

  const handleUserIp = async () => {
    try {
      const ipifyUrl = 'https://api.ipify.org?format=json';
      const geolocationDbUrl = 'https://geolocation-db.com/json/';

      const ipifyData = await fetchIpFromApi(ipifyUrl);
      if (ipifyData) {
        return ipifyData.ip;
      } else {
        const geolocationDbData = await fetchIpFromApi(geolocationDbUrl);
        if (
          geolocationDbData &&
          geolocationDbData.data &&
          geolocationDbData.data.IPv4
        ) {
          return geolocationDbData.data.IPv4;
        } else {
          console.log('Could not retrieve IP from any source.');
          return null;
        }
      }
    } catch (error) {
      console.log('Error:', error);
      return null;
    }
  };
  const Init = async () => {
    const ip = await handleUserIp();
    userIp.current = ip;
    const getToken = async () => {
      try {
        const jwtToken = (await Auth.currentSession())
          .getIdToken()
          .getJwtToken();
        return jwtToken;
      } catch (e) {
        return '';
      }
    };
    const token = await getToken();

    Amplify.configure({
      region: `${process.env.AWS_REGION}`,
      identityPoolId: `${process.env.AWS_COGNITO_IDENTITY_POOL_ID}`,
      aws_project_region: `${process.env.AWS_REGION}`,
      aws_appsync_graphqlEndpoint: `${process.env.AWS_ENDPOINT}`,
      aws_appsync_region: `${process.env.AWS_REGION}`,
      aws_appsync_authenticationType: token
        ? `${process.env.AWS_SIGNIN_AUTH_TYPE}`
        : `${process.env.AWS_AUTH_TYPE}`,
      aws_user_pools_id: `${process.env.AWS_USER_POOLS_ID}`,
      aws_cognito_identity_pool_id: `${process.env.AWS_COGNITO_IDENTITY_POOL_ID}`,
      aws_user_pools_web_client_id: `${process.env.AWS_USER_POOLS_WEB_CLIENT_ID}`,
      aws_user_files_s3_bucket: `${process.env.AWS_S3_BUCKET_NAME}`,
      aws_user_files_s3_bucket_region: `${process.env.AWS_S3_BUCKET_REGION}`,
    });

    const authLink = createAuthLink({
      url: `${process.env.AWS_ENDPOINT}`,
      region: `${process.env.AWS_REGION}`,
      auth: {
        //@ts-ignore
        type: token
          ? `${process.env.AWS_SIGNIN_AUTH_TYPE}`
          : `${process.env.AWS_AUTH_TYPE}`,
        credentials: () => ({
          accessKeyId: `${process.env.ACCESS_KEY_ID}`,
          secretAccessKey: `${process.env.SECRET_ACCESS_KEY}`,
        }),
      },
    });

    const requestMiddleware = setContext(async (_, { headers }) => {
      const token = await getToken();
      return {
        headers: {
          ...headers,
          authorization: token ? `${token}` : '',
        },
      };
    });

    const responseMiddleWare = new ApolloLink((operation, forward) => {
      console.log('api call response');
      return forward(operation).map((response) => {
        return response;
      });
    });

    const client = async () => {
      const token = await getToken();
      if (token) {
        navigate('/dashboard');
        return new ApolloClient({
          //@ts-ignore
          link: ApolloLink.from([
            //@ts-ignore
            requestMiddleware,
            responseMiddleWare,
            createHttpLink({ uri: process.env.AWS_ENDPOINT }),
          ]),
          cache: new InMemoryCache(),
        });
      }
      setUserInfo(undefined);
      setUserInfoMemberShip(undefined);
      return new ApolloClient({
        //@ts-ignore
        link: ApolloLink.from([
          authLink,
          createHttpLink({ uri: process.env.AWS_ENDPOINT }),
        ]),
        cache: new InMemoryCache(),
      });
    };
    const current = await client();
    setClient(current);
  };
  useEffect(() => {
    Init();
  }, []);
  const [userInfoMemberShip, setUserInfoMemberShip] = useState();
  return (
    <AuthContext.Provider
      value={{
        Init,
        client,
        refreshClient,
        setRefresh,
        refreshData,
        setRefreshData,
        userInfo,
        setUserInfo,
        userInfoMemberShip,
        setUserInfoMemberShip,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export { AuthContext, AuthProvider };
