import React, { useState } from 'react';
import moment from 'moment';
import InputBase from '@/common/inputs/InputBase';
import ArrowButton from '@/common/buttons/ArrowButton';
import { toastError } from '@/common/toasts/toast';
import CheckboxActive from '@/common/buttons/CheckboxActive';

interface SlideProps {
  onNext: () => void;
  onPrev: () => void;
}

const BankDetail: React.FC<SlideProps> = ({
                                         onPrev,
                                         info,
                                         setUseBank,
                                         useBank,
                                         handleCallApiPrePayment
                                       }) => {

  const [paymentInfoBank, setPaymentInfoBank] = useState({
    holderName: '',
    accountNumber: '',
    bsb: '',
  });
  const [isCheckConfirm, setIsCheckConfirm] = useState<boolean>(false)
  const setDataBank = (e) => {
    setPaymentInfoBank({ ...paymentInfoBank, [e.target.name]: e.target.value });
  };
  const handleCheckUseBank = (e) =>{
    setUseBank(e.target.checked)
    if(!e.target.checked){
      setPaymentInfoBank({
        holderName: '',
        accountNumber: '',
        bsb: '',
      })
    }
  }
  const onHandleNext = async () => {
    try {
      if (Object.values(paymentInfoBank).some((i) => i == '') && useBank ) {
        throw Error('Please fill all of the bank info');
      }
      if(useBank && !isCheckConfirm){
        throw Error('Please accept terms and conditions');
      }
      await handleCallApiPrePayment({
        ...info,
        memberDetails: {
          ...info.memberDetails,
        },
        ...(useBank) ? { bankAccountDetails: paymentInfoBank }:{bankAccountDetails:{holderName:'',accountNumber:'', bsb:''}},
      })
    } catch (e) {
      toastError(e.message);
    }
  }

  return (
    <div
      id="scroll-hidden"
      className="h-full md:mx-0 overflow-y-scroll pb-[70px] sm:pb-[150px]"
    >
      <div className="flex items-center mt-7">
        <div>
          <input id="default-checkbox" type="checkbox"  className="w-4 h-4 lg:" onChange={handleCheckUseBank} />
        </div>
          <label htmlFor="default-checkbox" className="ml-2 text-start text-sm font-normal text-white dark:text-gray-300  tracking-[1px]">
            Select this option if you would like to enter your Bank/Savings Account number to pay your subsequent fortnightly fees rather than the above credit card.
          </label>
      </div>
      {useBank &&
        <div className='mt-8'>
          <div className="mt-[15px] grid w-full md:grid-cols-1 grid-cols-2 gap-x-9 gap-y-[13px]">
            <InputBase
              holder="Acount name*"
              nameInput='holderName'
              style="lg:w-full"
              isRequired={true}
              onchange={(e) => setDataBank(e)}
            />
            <InputBase
              holder="Account number*"
              style="lg:w-full"
              nameInput='accountNumber'
              isRequired={true}
              onchange={(e) => setDataBank(e)}
            />
            <InputBase
              holder="BSB*"
              style="lg:w-full"
              nameInput='bsb'
              isRequired={true}
              onchange={(e) => setDataBank(e)}
            />

          </div>
          <div className="flex items-center gap-x-4 mb-7 mt-9">
             <CheckboxActive onChecked={() => {
               setIsCheckConfirm(!isCheckConfirm
               );
             }} />
            <p className="text-gray text-sm text-start  font-normal">
              You have chosen to pay by direct debit. This agreement outlines the terms and conditions applicable to Your direct
              debit request. You agree to these terms and conditions unless You notify us otherwise before the first debit day.
              Definitions Account means the account held at Your financial institution from which We are authorised to arrange
              funds to be debited. Agreement means this Direct Debit Request Service Agreement between us and You including the direct
              debit request. Business Day and Banking Day means a day other than a Saturday or a Sunday or a listed public holiday.
            </p>
          </div>
        </div>

      }

      {/* Button to actions */}
      <div className="mt-[35px] w-full flex items-center justify-between">
        <div onClick={() => onPrev()}>
          <div className="md:hidden flex">
            <ArrowButton text="back" isRight={false} />
          </div>
          <div className="md:flex hidden">
            <ArrowButton text="" isRight={false} />
          </div>
        </div>
        <div
          onClick={() => onHandleNext()}
        >
          <div className="md:hidden flex">
            <ArrowButton text="Next" isRight={true} />
          </div>
          <div className="md:flex hidden">
            <ArrowButton text="Next" isRight={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BankDetail;
