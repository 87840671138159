import React, { useContext, useState } from 'react';
import DashBoardLayout from '../../../components/layout/DashBoardLayout';
import arrowLeftShort from '../../../assets/images/icons/arrowLeftShort.svg';
import { Link } from 'gatsby';
import InputBase from '../../../../common/inputs/InputBase';
import { toastError, toastSuccess } from '@/common/toasts/toast';
import { Auth } from 'aws-amplify';
import ThreeDot from '@/common/loading/ThreeDot';
// import { Auth } from "aws-amplify";
// import { toast } from "react-toastify";

function Password() {
  const resetPassword = async () => {
    try {
      setLoading(true);
      if (
        !Boolean(
          formData &&
            Object.values(formData).every((v) => v != '' && v) &&
            formData.newPassword === formData.confirmNewPassword
        )
      ) {
        throw new Error(
          'Please fill in all inputs and ensure that the new password matches the confirm new password.'
        );
      }
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(
        user,
        formData.oldPassword,
        formData.newPassword
      );
      toastSuccess('Successfully update password !');
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };
  const [formData, setFormData] = useState({});
  const setData = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const [isLoading, setLoading] = useState(false);
  return (
    <div className=" ">
      {/* Layout */}{' '}
      <div className="mt-[39px] sm:mt-8 sm:pb-20 relative border-[1.5px] sm:border-0 border-[rgba(255,255,255,0.4)]  rounded-md pb-[25px] hover:bg-[#1F2528] sm:hover:bg-transparent">
        {isLoading && <ThreeDot />}
        <div className="sm:hidden border-b font-bicyclette border-white tracking-[1.4px] text-sm text-white px-6 font-medium  py-[20px] uppercase">
          reset password
        </div>

        <form className="mt-[37px] px-[27px] grid grid-cols-2 gap-x-[30px] gap-y-[60px] sm:grid-cols-1 sm:gap-y-5 sm:mt-0">
          <div>
            <InputBase
              type="password"
              holder="Existing password"
              style="lg:w-full"
              isRequired={true}
              nameInput="oldPassword"
              onchange={setData}
            />
          </div>
          <div className="blank sm:hidden"></div>
          <InputBase
            type="password"
            holder="New password"
            style="lg:w-full"
            isRequired={true}
            nameInput="newPassword"
            onchange={setData}
          />
          <InputBase
            type="password"
            holder="Confirm new password"
            style="lg:w-full"
            isRequired={true}
            nameInput="confirmNewPassword"
            onchange={setData}
          />
          <div className="gap-[25pxx]">
            <div className=""></div>
            <div
              onClick={() => {
                resetPassword();
              }}
              className="w-[158px] cursor-pointer font-medium  flex justify-center items-center text-[11px] sm:font-bold hover:text-white hover:bg-primary h-[37px] border-b-gray border text-gray hover:border-primary sm:text-[12px] gotham rounded sm:border-white sm:text-white sm:w-[234px] sm:h-[34px] sm:mt-7"
            >
              CHANGE PASSWORD
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Password;
