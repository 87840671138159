import React, { useState, useRef, useEffect } from 'react';
import { navigate } from 'gatsby';
import Details from '../components/joining-process/Details';
import Payment from '../components/joining-process/Payment';
import logo from '../assets//images/logo.svg';
import Memberships from '@/components/joining-process/Memberships';
import Health from '@/components/joining-process/Health';
import YourMembership from '@/components/joining-process/YourMembership';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import Congrats from '@/components/joining-process/Congrats';
import {
  fullMemberData,
  healthQuestionsByBrandId,
  listBrands,
  listMemberships,
  preRegisterMutation,
  SignupMemberOver18,
  registerMutationUnder18,
  preRegisterMutationUnder18,
} from '@/api/mutation';
import { useLazyQuery, useMutation } from '@apollo/client';
import { toastError, toastSuccess } from '@/common/toasts/toast';
import { Auth } from 'aws-amplify';
import InputBase from '@/common/inputs/InputBase';
import ArrowButton from '@/common/buttons/ArrowButton';
import ModalBase from '@/common/modals/ModalBase';
import MyReactQuill from '@/components/MyReactQuill';
import useFetch from '@/hooks/useFetch';
import useWindowSize from '@/hooks/useWindowSize';
import { breakpoints } from '@/constants';
import moment from 'moment';
import BankDetail from '@/components/joining-process/BankDetail';

interface IDataTermsAndConditionPage {
  title: string;
  content: string;
}

function register() {
  const [percent, setPercent] = useState<number>(20);
  const [current, setCurrent] = useState<number>(0);
  const [isApiSignUpFail, setIsApiSignUpFail] = useState<boolean>(false);
  const swiperRef = useRef<Swiper | null>(null);
  const handleSlideClick = (index: number) => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(index);
    }
  };
  const handleSlideChange = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      const currentIndex = swiperRef.current.swiper.activeIndex;
      setCurrent(currentIndex);
      if (membershipType === 'weekly' ) {
        currentIndex <= 5 && setPercent(20 * (currentIndex + 1));
      } else {
        currentIndex <= 4 && setPercent(25 * (currentIndex + 1));
      }
    }
  };
  const [input, setInput] = useState({
    memberDetails: {
      address: '',
      brandId: '',
      country: '',
      dob: '',
      email: '',
      gender: '',
      givenName: '',
      homeLocationId: '',
      joinLocationId: '',
      mobileNumber: '',
      postCode: 2000,
      state: '',
      suburb: '',
      surname: '',
    },
    membershipId: '',
    membershipLocationId: '',
  });
  const [contact, setContact] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phone: '',
    gender: '',
    dob: '',
    postCode: '',
  });
  const [useBank, setUseBank] = useState<boolean>(false);
  const qr = preRegisterMutation;
  const [signupMemberPrePaymentOver18] = useMutation(qr);

  const qru18 = preRegisterMutationUnder18;
  const [signupMemberPrePaymentUnder18] = useMutation(qru18);

  const su = registerMutationUnder18;
  const [signupMemberUnder18] = useMutation(su);

  const fn = SignupMemberOver18;
  const [signupMemberOver18] = useMutation(fn);
  const handleCallApiPrePayment = async (inputsData: any) => {
    let data = {};
    try {
      const userAge = moment(new Date()).diff(
        moment(inputsData.memberDetails?.dob),
        'years'
      );
      setLoading(true);
      let payload = { ...inputsData };
      delete payload?.cardDetails;
      delete payload?.paymentType;
      delete payload?.prospectId;
      delete payload?.membershipStartDate;
      delete payload?.memberId;
      if (userAge < 18) {
        payload = { ...payload };
        data = await signupMemberPrePaymentUnder18({
          variables: {
            input: { ...payload },
          },
        });
        if (!data?.data.signupMemberViaWebPreparePaymentUnder18.prospectId) {
          localStorage.removeItem('payment');
          throw Error(
            data?.data.signupMemberViaWebPreparePaymentUnder18.message
          );
        } else {
          localStorage.setItem(
            'payment',
            JSON.stringify(data?.data?.signupMemberViaWebPreparePaymentUnder18)
          );
          if(membershipType === 'weekly' )handleSlideClick(4);
          else handleSlideClick(3);
        }
      } else {
        data = await signupMemberPrePaymentOver18({
          variables: {
            input: { ...payload },
          },
        });

        if (!data?.data.signupMemberViaWebPreparePaymentOver18.prospectId) {
          localStorage.removeItem('payment');
          throw Error(
            data?.data.signupMemberViaWebPreparePaymentOver18.message
          );
        } else {
          localStorage.setItem(
            'payment',
            JSON.stringify(data?.data?.signupMemberViaWebPreparePaymentOver18)
          );
          if(membershipType === 'weekly' )handleSlideClick(4);
          else handleSlideClick(3);
        }
      }

      return data;
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
    return data;
  };
  const handleCallApiUnder18 = async (inputsData: any) => {
    let data = {};
    try {
      setIsLoadingSignUpUnder18(true);
      let payload = { ...inputsData };
      delete payload?.paymentType;
      payload = { ...payload };
      data = await signupMemberUnder18({
        variables: {
          input: { ...payload },
        },
      });
      if (data?.data.signupMemberViaWebUnder18.error) {
        setIsLoadingSignUpUnder18(false);
        setIsPaymentSuccess(false);
        throw Error(data?.data.signupMemberViaWebUnder18.error);
      } else if (data?.data.signupMemberViaWebUnder18.memberId) {
        setSuccessLoadingUnder18(true);
        setTimeout(() => {
          setSuccessLoadingUnder18(false);
          handleSlideClick(5);
          setIsPaymentSuccess(false);
          toastSuccess('successfully sign up account');
        }, 3000);

      }
      else if (data?.data.signupMemberViaWebUnder18.memberId){
        setIsPaymentSuccess(false);
      }
      return data;
    } catch (e) {
      toastError(e.message);
      setIsPaymentSuccess(false);
    } finally {
      setIsLoadingSignUpUnder18(false);
    }
    return data;
  };
  const handleCallApiOver18 = async (inputsData: any) => {
    try {
      setIsLoadingSignUpOver18(true);
      let payload = { ...inputsData };
      const data = await signupMemberOver18({
        variables: {
          input: { ...payload },
        },
      });
      if (data?.data.signupMemberViaWebOver18.error) {
        setIsLoadingSignUpOver18(false);
        setIsPaymentSuccess(false);
        throw Error(data?.data.signupMemberViaWebOver18.error);
      } else if (data?.data.signupMemberViaWebOver18.memberId) {
        setSuccessLoadingOver18(true);
        setTimeout(() => {
          setSuccessLoadingOver18(false);
          handleSlideClick(5);
          setIsPaymentSuccess(false);
          toastSuccess('successfully sign up account');
        }, 3000);
        toastSuccess('successfully sign up account');
      }
      else if (!data?.data.signupMemberViaWebOver18.memberId){
        setIsPaymentSuccess(false);

      }
    } catch (e) {
      toastError(e.message);
      setIsPaymentSuccess(false);
    } finally {
      setIsLoadingSignUpOver18(false);
    }
  };
  const [isRedirectLink, setRedirectLink] = useState(null);
  const [confirmData, setConfirmData] = useState();
  const [listMemberShipApi] = useLazyQuery(listMemberships);
  const [healthQuestionnaires] = useLazyQuery(healthQuestionsByBrandId);
  const [listTimelines, setListTimeLines] = useState([
    'details',
    'membership',
    'health',
    'payment',
  ]);
  const [listBrand] = useLazyQuery(listBrands);
  const [membershipType, setMembershipType] = React.useState<string>('');
  useEffect(() => {
    listBrand();
    healthQuestionnaires().then((res) => console.log({ res }));
    const parseQueryString = () => {
      var str = window.location.search;
      var objURL = {};

      str.replace(
        new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
        function ($0, $1, $2, $3) {
          objURL[$1] = $3;
        }
      );
      return objURL;
    };
    const qr = parseQueryString();
    if (
      Object.keys(qr).length &&
      qr.inviteToken &&
      qr.email &&
      qr.brandId &&
      qr.memberId
    ) {
      setRedirectLink(true);
      setConfirmData(qr);
    } else {
      setRedirectLink(false);
    }
  }, []);

  useEffect(() => {
    if (membershipType === 'weekly' ) {
      setListTimeLines([
        'details',
        'membership',
        'health',
        'bank account',
        'payment',
      ]);
    }
    if (membershipType === 'pif') {
      setListTimeLines(['details', 'membership', 'health', 'payment']);
    }
  }, [ membershipType]);
  const [qrMember] = useLazyQuery(fullMemberData);
  const mapInfo = (e) => {
    setConfirmInfo({ ...confirmInfo, [e.target.name]: e.target.value });
  };
  const [confirmInfo, setConfirmInfo] = useState({});

  const [isUnderAge, setIsUnderAge] = React.useState<boolean>(false);

  const handleUnderAge = () => {
    setIsUnderAge(true);
  };

  const confirmSignUp = async () => {
    
    try {
      if(confirmInfo.password!==confirmInfo.confirm) throw Error("password doesn't match please double check")
      setLoading(true);
      const data = await qrMember({
        variables: {
          memberId: confirmData.memberId,
        },
      });

      await Auth.signUp({
        username: confirmData.email,
        password: confirmInfo.password,
        attributes: {
          email: confirmData.email,
          'custom:inviteToken': confirmData.inviteToken,
          'custom:brandId': confirmData.brandId,
          'custom:memberId': confirmData.memberId,
          'custom:mobileNumber': data.data.getMember.mobileNumber,
        },
      });
      // alert("successfully confirm user");
      navigate('/');
      // const user = await Auth.signIn("dupiffoyimme-3304@yopmail.com", "Tranqhuy8110");
      toastSuccess('successfully confirm !');
    } catch (e) {
      // alert(e.message);
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };
  const [isLoading, setLoading] = useState(false);
  const [isLoadingSignupOver18, setIsLoadingSignUpOver18] =
    useState<boolean>(false);
  const [isLoadingSignupUnder18, setIsLoadingSignUpUnder18] =
    useState<boolean>(false);
  const [successLoadingUnder18, setSuccessLoadingUnder18] =
    useState<boolean>(false);
  const [successLoadingOver18, setSuccessLoadingOver18] =
    useState<boolean>(false);
  const [TAndC, setTAndC] = useState<boolean>(false);
  const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = useState<boolean>(false);

  const { data: dataTermAndCondition } = useFetch({
    params: 'term-and-condition',
    populate: '',
  });
  const { data: dataPrivacyPolicy } = useFetch({
    params: 'privacy-policy',
    populate: '',
  });

  const termAndCondition: IDataTermsAndConditionPage =
    dataTermAndCondition?.data?.attributes;
  const privacyPolicyData: IDataTermsAndConditionPage =
    dataPrivacyPolicy?.data?.attributes;

  const { width } = useWindowSize();

  return (
    <>
      {TAndC && (
        <ModalBase noBaseHeader onClickOutSide={() => setTAndC(false)}>
          <div className="h-[800px] overflow-y-auto" id="scroll-hidden">
            <section className="bg-dark pt-6 pb-20">
              <div className="containerPage">
                <h1 className="heading heading-underline text-white">
                  {termAndCondition?.title}
                </h1>
                <div className="contentWrapper">
                  <MyReactQuill content={termAndCondition?.content} />
                </div>
              </div>
            </section>
          </div>
        </ModalBase>
      )}

      {privacyPolicy && (
        <ModalBase noBaseHeader onClickOutSide={() => setPrivacyPolicy(false)}>
          <div className="h-[800px] overflow-y-auto" id="scroll-hidden">
            <section className="bg-dark pt-6 pb-20">
              <div className="containerPage">
                <h1 className="heading heading-underline text-white">
                  {privacyPolicyData?.title}
                </h1>
                <div className="contentWrapper">
                  <MyReactQuill content={privacyPolicyData?.content} />
                </div>
              </div>
            </section>
          </div>
        </ModalBase>
      )}

      {isLoading && !isLoadingSignupUnder18 && !isLoadingSignupOver18 && (
        <div className="fixed flex items-center h-[100vh] w-[100vw] z-50 bg-[rgba(212,212,212,0.2)] ">
          <div className="animate-spin h-12 w-12  border-r-2 border-2 border-gray border-r-[#2757FF] rounded-full m-auto "></div>
        </div>
      )}

      {isLoadingSignupUnder18 &&
        <div className={`fixed flex items-center transition-opacity ease-in-out duration-500 ${isLoadingSignupUnder18 ? 'opacity-100': 'opacity-0'}  h-[100vh] w-[100vw] z-50 bg-[rgba(14,13,13,0.6)]`}>
          <div className='m-auto h-[400px] w-[35%] sm:w-[350px] lg:w-[400px] bg-richBlack flex justify-center items-center px-[35px]'>
            <div className='mb-8'>
              <div className="animate-spin h-12 w-12  border-r-2 border-2 border-gray border-r-[#2757FF] rounded-full mx-auto "></div>
              <div className='mt-5 text-center text-2xl text-primary  loading'>Saving Card Details</div>
              <div className='mt-5 text-center  text-white tracking-[1.4px] leading-[25px]'>We securely save your card details to process your payment once your membership has been approved by your parent/guardian.</div>
            </div>
          </div>
        </div>
      }
      {isPaymentSuccess &&
        <div
          className={`fixed flex items-center transition-opacity ease-in-out duration-500 ${
            successLoadingUnder18 || successLoadingOver18
              ? 'opacity-100 '
              : 'opacity-0'
          }  h-[100vh] w-[100vw] z-50 bg-[rgba(14,13,13,0.6)]`}
        >
          <div className="m-auto h-[400px] w-[35%] sm:w-[350px] lg:w-[400px] bg-richBlack flex justify-center items-center px-[35px]">
            <div className="mb-8">
              {(successLoadingUnder18 || successLoadingOver18 )&& (
                <div className="success-animation">
                  <svg
                    className="checkmark"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 52 52"
                  >
                    <circle
                      className="checkmark__circle"
                      cx="26"
                      cy="26"
                      r="25"
                      fill="none"
                    />
                    <path
                      className="checkmark__check"
                      fill="none"
                      d="M14.1 27.2l7.1 7.2 16.7-16.8"
                    />
                  </svg>
                </div>
              )}
              <div className="mt-5 text-center text-2xl text-primary  ">
                {successLoadingUnder18 &&'Card Details Saved'}
                {successLoadingOver18 && 'Payment Successful'}
              </div>
              <div className="mt-5 text-center  text-white tracking-[1.4px] leading-[25px]">
                {successLoadingUnder18 && 'Thank you! Your card details have been saved securely. Once your membership is approved, we will process the payment using this card.'}
                {successLoadingOver18 &&'Thank you! Your payment has been processed; please check your email to complete your signup.'}
              </div>
              <div className="mt-5 text-center  text-white tracking-[1.4px]  leading-[25px]">
                {successLoadingUnder18 && 'Please check with your parent/guardian to access their email and approve the consent form.'}
              </div>
            </div>
          </div>
        </div>
      }

      {isLoadingSignupOver18 && (
        <div className={`fixed flex items-center  transition-opacity ease-in-out duration-500 ${isLoadingSignupOver18 ? 'opacity-100': 'opacity-0'} h-[100vh] w-[100vw] z-50 bg-[rgba(14,13,13,0.6)]`}>
          <div className="m-auto h-[300px] w-[35%] sm:w-[350px] lg:w-[400px] bg-richBlack flex justify-center items-center">
            <div className="mb-8">
              <div className="animate-spin h-12 w-12  border-r-2 border-2 border-gray border-r-[#2757FF] rounded-full mx-auto "></div>
              <div className="mt-5 text-center text-2xl text-primary  loading">
                Processing your payment and signing up
              </div>
              <div className="mt-5 text-center  text-white">
                Processing time may take up to 60 seconds
              </div>
            </div>
          </div>
        </div>
      )}
      {!isRedirectLink && (
        <div id="registerPage" className="min-h-[100vh] bg-black">
          <div className=" mx-auto max-w-[1440px] relative">
            <div className="py-[37px] sm:!mx-[0px] xl:mx-10">
              <img
                className="lg:mx-6 sm:!mx-[14px] mx-0  w-auto h-[36px] md:h-[21px]"
                src={logo}
                alt="logo"
              />
            </div>
            {/* Navigation */}
            {!isUnderAge && (
              <div
                style={{ width: percent + '%' }}
                className={`hidden h-[6px] md:flex  transition-transform bg-primary z-10 ${
                  (
                    membershipType === 'weekly'
                      ? current == 5
                      : current == 4
                  )
                    ? 'md:hidden block'
                    : ' '
                }`}
              ></div>
            )}
            <div className="flex items-start justify-between pb-[11px]  lg:w-full w-[960px] sm:!px-[0px] xl:px-10">
              {!isUnderAge && current < 5 && (
                <>
                  <div className="md:hidden lg:mx-6 mx-0 flex items-center cursor-pointer md:justify-evenly justify-between w-full gap-x-8">
                    {listTimelines.map((item: any, index: number) => (
                      <div
                        onClick={() =>
                          current >= index ? handleSlideClick(index) : null
                        }
                        key={index}
                        className="md:px-0 flex items-center w-1/4"
                      >
                        <div
                          className={`w-[34px] h-[34px] rounded-[50%] text-white flex items-center justify-center font-bold text-sm ${
                            current >= index && 'bg-primary text-black'
                          } ${
                            current < index &&
                            'border border-white  opacity-60 text-white'
                          } ${
                            current > index &&
                            'border border-primary text-white'
                          } `}
                        >
                          {index + 1}
                        </div>
                        <p
                          className={`md:hidden ml-[15px] uppercase  tracking-widest font-bold gotham  text-[10px] ${
                            current < index ? 'text-gray' : 'text-white'
                          }`}
                        >
                          {item}
                        </p>
                      </div>
                    ))}
                  </div>
                  <div className="md:flex  hidden items-center justify-between w-full mx-6 sm:mx-[14px] mt-[20px] cursor-pointer">
                    {listTimelines.map((mobileStep, mobileIndex) => {
                      return (
                        <div
                          key={mobileStep}
                          className={`${
                            current >= mobileIndex
                              ? 'bg-primary '
                              : 'bg-richBlack '
                          } relative w-9 h-9 rounded-full border border-primary flex items-center justify-center font-bicyclette font-bold leading-[18px] text-white text-xl text-center after:content-[' '] ${
                            mobileIndex === 3
                              ? ' '
                              : ' after:absolute after:left-[calc(100%+1px)] after:top-1/2 after:h-[2px] after:w-[calc(40vw)] after:bg-textSecondary'
                          }  `}
                        >
                          {mobileIndex + 1}
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
              {current >= 5 && (
                <h2 className="text-primary md:hidden flex uppercase heading2">
                  congrats, you’ve just joined plus fitness
                </h2>
              )}
              {/* Close */}
              <div
                onClick={() => {
                  if (isUnderAge && width && width <= breakpoints.sm) {
                    setIsUnderAge(false);
                  } else {
                    navigate('/');
                  }
                }}
                //  to={isUnderAge && width && width <= breakpoints.sm ? '' : '/'}
              >
                <div className="cursor-pointer absolute top-[25px] right-0 sm:right-4">
                  <svg
                    width="41"
                    height="41"
                    viewBox="0 0 41 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M29.7926 29.7929C24.4542 35.1314 15.8318 35.1543 10.5341 29.8565C5.23632 24.5588 5.25924 15.9364 10.5977 10.598C15.9362 5.25952 24.5585 5.2366 29.8563 10.5344C35.154 15.8321 35.1311 24.4545 29.7926 29.7929Z"
                      stroke="white"
                      strokeWidth="1.32496"
                    />
                    <path
                      d="M24.7275 15.1323L15.3977 24.4622"
                      stroke="white"
                      strokeWidth="1.32496"
                    />
                    <path
                      d="M24.8311 24.5654L15.5598 15.2942"
                      stroke="white"
                      strokeWidth="1.32496"
                    />
                  </svg>
                </div>
              </div>
            </div>

            <div className="flex items-start  w-full gap-8 max-w-[1440px] justify-between mx-auto">
              <div className="flex flex-col lg:w-full w-[960px]">
                <div
                  className="sm:!mx-[14px] xl:px-10"
                  onClick={() => {
                    handleSlideClick(current + 1);
                  }}
                >
                  <div
                    style={{ width: percent > 100 ? '100%' : percent + '%' }}
                    className={`flex h-[6px] md:hidden  transition-transform bg-primary z-10 ${
                      (
                        membershipType === 'weekly' 
                          ? current == 5
                          : current == 4
                      )
                        ? 'md:hidden block'
                        : ' '
                    }`}
                  ></div>
                </div>
                <div
                  className={`xl:w-auto w-[960px] sm:!mx-[14px] xl:mx-10 ${
                    current == 4
                      ? 'h-[calc(100vh-150px)]'
                      : 'h-[calc(100vh-161px)]'
                  }  overflow-hidden  `}
                >
                  <Swiper
                    allowTouchMove={false}
                    ref={swiperRef}
                    slidesPerView={1}
                    initialSlide={current}
                    onSlideChange={handleSlideChange}
                  >
                    <SwiperSlide>
                      <Details
                        onPrev={() => true}
                        onNext={() => handleSlideClick(1)}
                        info={input}
                        setInfo={setInput}
                        onUnderAge={handleUnderAge}
                        isUnderAge={isUnderAge}
                        setContact={setContact}
                        contact={contact}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <Memberships
                        onPrev={() => handleSlideClick(0)}
                        onNext={() => handleSlideClick(2)}
                        info={input}
                        setInfo={setInput}
                        setMembershipType={setMembershipType}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <Health
                        onPrev={() => handleSlideClick(1)}
                        onNext={() => handleSlideClick(3)}
                        info={input}
                        setInfo={setInput}
                        openModalTC={() => setTAndC(true)}
                        openModalPP={() => setPrivacyPolicy(true)}
                        setContact={setContact}
                        contact={contact}
                        handleCallApiPrePayment={handleCallApiPrePayment}
                        membershipType={membershipType}
                      />
                    </SwiperSlide>

                    {membershipType === 'weekly'&&
                      <SwiperSlide>
                        <BankDetail
                          onPrev={() => handleSlideClick(2)}
                          onNext={() => handleSlideClick(4)}
                          info={input}
                          setInfo={setInput}
                          handleCallApiPrePayment={handleCallApiPrePayment}
                          setUseBank={setUseBank}
                          useBank={useBank}
                        />
                      </SwiperSlide>
                    }
                    <SwiperSlide>
                      <Payment
                        onPrev={() =>(membershipType === 'weekly' ) ? handleSlideClick(3):handleSlideClick(2)}
                        onNext={() => (membershipType === 'weekly' ) ? handleSlideClick(5):handleSlideClick(4)}
                        info={input}
                        setInfo={setInput}
                        handleCallApiOver18={handleCallApiOver18}
                        handleCallApiUnder18={handleCallApiUnder18}
                        contact={contact}
                        isApiSignUpFail={isApiSignUpFail}
                        useBank={useBank}
                        setIsPaymentSuccess={setIsPaymentSuccess}
                        membershipType={membershipType}
                      />
                    </SwiperSlide>
                    <SwiperSlide>
                      <Congrats info={input} moveSlide={handleSlideClick} />
                    </SwiperSlide>
                  </Swiper>
                </div>
              </div>
              <YourMembership
                slideMove={(v: number) => handleSlideClick(v)}
                current={current}
                info={input}
                setInfo={setInput}
                contact={contact}
                membershipType={membershipType}
              />
            </div>
          </div>
        </div>
      )}
      {isRedirectLink && (
        <div className="bg-black h-[100vh] w-[100vw] flex items-center justify-center relative">
          <div className="flex flex-col gap-6 z-10 sm:mx-6">
            <div className="w-[495px] h-[47px]">
              <img
                className="w-full h-full object-contain"
                src="/icons/pf-logo-line.svg"
              />
            </div>
            <div className="text-white uppercase text-[40px] leading-[36px] text-center my-6 ">
              Member signup
            </div>
            <InputBase
              nameInput="Email address"
              holder="Email address"
              style="lg:w-full"
              isRequired={true}
              type="text"
              value={confirmData.email}
              disabled={true}
            />
            <InputBase
              onchange={(e) => mapInfo(e)}
              nameInput="password"
              holder="Password*"
              style="lg:w-full"
              isRequired={true}
              type="password"
            />
            <InputBase
              onchange={(e) => mapInfo(e)}
              nameInput="confirm"
              holder="Confirm Password*"
              style="lg:w-full"
              isRequired={true}
              type="password"
            />
            <div className="flex justify-end items-center">
              <ArrowButton
                text="NEXT"
                isRight={true}
                onclick={() => confirmSignUp()}
              />
            </div>
          </div>
          <div className="absolute bottom-0 w-[100vw] h-[44vh] bg-gradient-to-t to-[rgba(36,51,213,0.0)] from-[rgba(36,51,213,0.34)]"></div>
        </div>
      )}
    </>
  );
}

export default register;
