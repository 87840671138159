import React, { useContext, useState, useEffect } from 'react';
// import arrowLeftShort from "../../../assets/images/icons/arrowLeftShort.svg";
import { Link, navigate } from 'gatsby';
import PrimaryOutlinedButton from '@/common/buttons/PrimaryOutlinedButton';
import MembershipsList from '../../../../components/joining-process/Memberships';
import { AuthContext } from '@/api/auth';
import ModalBase from '@/common/modals/ModalBase';
import tick from '../../../../assets/images/tick.svg';
import { toastError, toastSuccess } from '@/common/toasts/toast';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import {
  changeMembership,
  fullMemberData,
  getMember,
  getMemberContractByMemberId,
  listMembershipId,
  updateMemberInfo,
} from '@/api/mutation';
import moment from 'moment';
import ArrowButton from '@/common/buttons/ArrowButton';
import InputAnimated from '@/common/inputs/InputAnimated';
// import SearchClubNearby from "./billing/ChangeClub";
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import CheckboxActive from '@/common/buttons/CheckboxActive';
import InputBase from '@/common/inputs/InputBase';
import InputNumber from '@/common/inputs/InputNumber';
import congratsImg from '@/assets/images/congrats.png';
import { Auth, Storage } from 'aws-amplify';
// import SelectPlan from "./billing/SelectPlan";
// import Upgrade from "./billing/Upgrade";
// import ConfirmDetail from "./billing/ConfirmDetail";
// import YourPayment from "./billing/YourPayment";
// import Congrats from "./billing/Congrats";
import arrowRight from '../../../../assets/images/arrowRight.svg';

import appstore from '@/assets/images/appstore.png';
import googlePlay from '@/assets/images/chplay.png';
import useWindowSize from '@/hooks/useWindowSize';
import { breakpoints } from '@/constants';
import Switch from '@/common/buttons/Switch';
import MyReactQuill from '@/components/MyReactQuill';
import useFetch from '@/hooks/useFetch';
import ModalMakePayment from '@/common/payment/modalMakePayment';
import { Modal } from 'react-responsive-modal';
import ThreeDot from '@/common/loading/ThreeDot';

interface IDataTermsAndConditionPage {
  title: string;
  content: string;
}

function ChangeClub({ willRefetch, membership, moveTab }) {
  const { width } = useWindowSize();
  const setDataAccount = (e) => {
    if (e.target.name === 'cardCvv') {
      paymentInfoCard.cardType == 'American express'
        ? (e.target.maxLength = '4')
        : (e.target.maxLength = '3');
    }
    if (e.target.name === 'cardExpiryDate') {
      if (e.target.value.length === 2) {
        e.target.maxLength = '5';
        e.target.value = e.target.value + '/';
      } else if (
        e.target.value.length === 3 &&
        e.target.value.charAt(2) === '/'
      )
        e.target.value = e.target.value.replace('/', '');
    }
    if (e.target.name === 'cardNumber') {
      let cardTypeDetect = '';
      if (/^4[0-9]{12}(?:[0-9]{3})?$/.test(e.target.value.trim())) {
        cardTypeDetect = 'Visa';
      } else if (
        /^5[1-5][0-9]{5,}|222[1-9][0-9]{3,}|22[3-9][0-9]{4,}|2[3-6][0-9]{5,}|27[01][0-9]{4,}|2720[0-9]{3,}$/.test(
          e.target.value.trim()
        )
      ) {
        cardTypeDetect = 'Mastercard';
      } else if (/^3[47][0-9]{13}$/.test(e.target.value.trim())) {
        cardTypeDetect = 'American express';
      }
      setPaymentInfoCard({
        ...paymentInfoCard,
        [e.target.name]: e.target.value,
        cardType: cardTypeDetect,
      });
    } else
      setPaymentInfoCard({
        ...paymentInfoCard,
        [e.target.name]: e.target.value,
      });
    // { ...setPaymentInfo, [e.target.name]: e.target.value };
  };
  const [paymentInfoCard, setPaymentInfoCard] = useState({
    cardNumber: '',
    cardExpiryDate: '',
    cardType: '',
    cardCvv: '',
    cardHolderName: '',
  });
  const [TAndC, setTAndC] = useState<boolean>(false);
  const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false);

  const { data: dataTermAndCondition } = useFetch({
    params: 'term-and-condition',
    populate: '',
  });
  const { data: dataPrivacyPolicy } = useFetch({
    params: 'privacy-policy',
    populate: '',
  });

  const termAndCondition: IDataTermsAndConditionPage =
    dataTermAndCondition?.data?.attributes;
  const privacyPolicyData: IDataTermsAndConditionPage =
    dataPrivacyPolicy?.data?.attributes;

  const userInfo = useContext(AuthContext).userInfo;
  const setUserInfo = useContext(AuthContext).setUserInfo;
  const [step, setStep] = useState(1);
  const [info, setInfo] = useState({
    memberDetails: {
      dob: userInfo?.dob,
      homeLocationId: userInfo?.homeLocationId,
      membershipId: membership?.[0]?.membershipId,
    },
  });
  const [isStart, setIsStart] = useState(false);

  const [updatedInfo, setUpdatedInfo] = useState({ ...userInfo });

  const [prefixEmergencyNumber, setPrefixEmergencyNumber] = React.useState<
    '+61' | '+64'
  >('+61');

  const handleChange = (e: any) => {
    let name = e.target.name;
    let value = e.target.value;

    if (name == 'emergencyContactNumber') {
      value = prefixEmergencyNumber + value;
      if (value.charAt(3) == '0') value = value.slice(0, 3) + value.slice(4);
    }
    setUpdatedInfo({ ...updatedInfo, [name]: value });
  };
  const [listMemberShipApi] = useLazyQuery(listMembershipId);
  const [modal, setModal] = useState(false);
  const [membershipDetail, setMembershipDetail] = useState({});
  const [newMembershipLocationId, setNewMembershipLocationId] = useState('');
  const [loadingModal, setLoadingModal] = useState(false);
  const [changeMembershipApi] = useMutation(changeMembership);
  const [membershipArr, setMembershipArr] = useState<any>([]);
  const [membershipCurrent, setMembershipCurrent] = useState<any>({});
  const [isPIF, setIsPIF] = useState<boolean>();
  const [newMembershipType, setNewMembershipType] = useState('');
  const [membershipType, setMembershipType] = React.useState<string>('');
  const [openModalPayment, setModalPayment] = useState(false);
  const [addMembershipPaymentModal, setAddMembershipPaymentModal] =
    useState(false);

  const [amountChangeMembership, setAmountChangeMembership] = useState('');
  const [changeMembershipData, setChangeMembershipData] = useState({
    changeMembershipType: '',
    newMembershipId: '',
    locationId: '',
  });
  const [nextDirect, setNextDirect] = useState('');
  const [currentMembership, setCurrentMembership] = useState();
  const [newLocationId, setNewLocationId] = useState('');
  React.useEffect(() => {
    listMemberShipApi().then((membership) => {
      membership = membership.data;
      if (membership) {
        const ageInfo = moment(new Date()).diff(
          moment(info?.memberDetails?.dob),
          'years'
        );
        sortData(
          membership.getMembershipByBrandId.items.filter(
            (i) => i.id === info?.memberDetails.membershipId
          )[0],
          ageInfo,
          false
        );
        setCurrentMembership(
          membership.getMembershipByBrandId.items.filter(
            (i) => i.id === info?.memberDetails.membershipId
          )[0]
        );
      }
    });
    handleFetchData();
  }, []);
  const sortData = (items, age, isNewMemberships) => {
    const upgrade = ['CL ', 'HR ', 'GU ', 'LO ', ' + ', 'RB '];
    if (items) {
      const lowerClassName = items.membershipName.toLowerCase();
      const lowerDesc = items.description.toLowerCase();
      const result = upgrade.some((substr) =>
        lowerDesc.includes(substr.toLowerCase())
      );

      if (!result && items.isActive) {
        if (
          // true
          (age === 16 && lowerClassName.includes('flexi')) ||
          (age > 16 &&
            (lowerClassName.includes('flexi') ||
              lowerClassName.includes('power') ||
              (!changeMembership && lowerClassName.includes('guest')))) ||
          lowerDesc.includes('pif') ||
          items.recurring ||
          (age <= 13 && lowerClassName.includes('junior')) ||
          (age > 13 && age < 16 && lowerClassName.includes('youth'))
        ) {
          if (lowerDesc.includes('pif')) {
            setIsPIF(true);
            if (isNewMemberships) {
              setNewMembershipType('pif');
            }
          } else if (
            items.paymentFrequency.type === 'WEEKS' &&
            !lowerDesc.includes('student')
          ) {
            setIsPIF(false);
            if (isNewMemberships) {
              setNewMembershipType('weekly');
            }
          }
        }
      }

      // setOptions(['weekly',"annual","student"]);S
    }
  };
  const handleSelect = (v) => {
    // setModal(true)
    setInfo({
      memberDetails: {
        ...info.memberDetails,
        NewMembershipId: v.memberDetails.NewMembershipId,
      },
    });
    setMembershipDetail({
      ...v.membershipDetails,
      membershipNew: v.memberDetails,
    });
    const membershipIsActive =
      v.membershipDetails.membershipLocations.items.filter((i) => i.isActive);
    setNewMembershipLocationId(membershipIsActive[0].id);
    const ageInfo = moment(new Date()).diff(
      moment(info?.memberDetails?.dob),
      'years'
    );
    sortData(v.membershipDetails, ageInfo, true);
  };
  const handleCallApiChangeMembership = () => {
    setModal(true);
  };

  function createMarkup(data) {
    return { __html: data };
  }

  const [isShowGender, setShowGender] = useState(false);
  const [selectedGender, setSelectedGender] = useState(userInfo?.gender);

  const setRefresh = useContext(AuthContext).setRefreshData;
  const [updateMemberApi] = useMutation(updateMemberInfo);

  const handleUpdateInfo = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const payload = {};
      for (let i = 0; i < Object.keys(userInfo).length; i++) {
        if (!(Object.values(updatedInfo)[i] === Object.values(userInfo)[i])) {
          payload[Object.keys(userInfo)[i]] = Object.values(updatedInfo)[i];
        }
      }

      payload.memberId = user.attributes['custom:memberId'];

      delete payload.imageUrl;

      if (
        !updatedInfo.emergencyContactName ||
        !updatedInfo.emergencyContactNumber ||
        !updatedInfo.emergencyContactRelation
      ) {
        toastError('Please provide emergency contact');
        return;
      }
      const data = await updateMemberApi({ variables: { input: payload } });
    } catch (e) {
      toastError(e.message);
    } finally {
      setRefresh(true);
    }
  };
  const client = useApolloClient();
  client.refetchQueries({
    include: ['getMember'], // Consider using "active" instead!
  });
  const [accept, setAccept] = useState(false);
  const [getMemberApi] = useLazyQuery(getMember);
  const [getMembership] = useLazyQuery(getMemberContractByMemberId);

  const handleChangeMembership = async () => {
    try {
      setLoadingModal(true);
      const userContracts = await getMembership({
        variables: {
          memberId: userInfo.memberId,
        },
      });
      await handleUpdateInfo();

      await changeMembershipApi({
        variables: {
          memberId: userInfo.memberId,
          membershipLocationId: newMembershipLocationId,
          newMembershipId: membershipDetail.membershipNew.membershipId,
          oldMemberContractId:
            userContracts.data.getMemberContractByMemberId.items.filter((i) => {
              if (i.expiryDateTime != null) {
                if (
                  new Date(i.expiryDateTime).valueOf() < new Date().valueOf()
                ) {
                  return false;
                }
              }
              if (i.endDateTime != null) {
                if (new Date(i.endDateTime).valueOf() < new Date().valueOf()) {
                  return false;
                }
              }
              return true;
            })[0].id,
          getInformation: true,
          // paymentType: paymentMethod,
        },
      });
      willRefetch(true);

      const memberDataApi = await getMemberApi({
        variables: { memberId: userInfo.memberId },
      });
      const memberData = memberDataApi.data.getMember;
      const imgUrl = memberData?.imageUrl
        ? await Storage.get(memberData.imageUrl)
        : '/icons/Dashboard/profile.svg';
      setUserInfo({ ...memberData, imageUrl: imgUrl });

      // client
      //       .query({
      //         query: getMember,
      //         variables: {
      //           memberId: user.attributes['custom:memberId'],
      //         },
      //       })
      //       .then(async (res) => {
      //         const data = res.data.getMember;
      //         const imgUrl = data?.imageUrl
      //           ? await Storage.get(data.imageUrl)
      //           : '/icons/Dashboard/profile.svg';
      //         setUserInfo({ ...data, imageUrl: imgUrl });
      //         localStorage.setItem(
      //           'currentUserDetail',
      //           JSON.stringify(res.data.getMember)
      //         );
      //       });
      //   })
      toastSuccess('Change membership and payment success');
      setInfo({
        memberDetails: {
          ...info.memberDetails,
          membershipId: membershipDetail.membershipNew.membershipId,
        },
      });
      setStep(6);
      setModal(false);
    } catch (e) {
      toastError(e.message);
      setStep(1);
    } finally {
      setLoadingModal(false);
    }
  };
  const nearestDate = (dateArray, targetDate) => {
    // Convert targetDate to Date object if it's not already
    if (!targetDate) {
      targetDate = new Date();
    } else if (!(targetDate instanceof Date)) {
      targetDate = new Date(targetDate);
    }
    const futureDates = dateArray.filter(
      (date) => new Date(date.debitDate) > targetDate
    );

    // If there are no future dates, return null
    if (futureDates.length === 0) {
      return null;
    }

    // Calculate the absolute difference between targetDate and each future date
    const diffs = futureDates.map((date) =>
      Math.abs(new Date(date.debitDate) - targetDate)
    );

    // Find the index of the minimum absolute difference
    const minDiffIndex = diffs.indexOf(Math.min(...diffs));

    // Return the future date at that index
    return futureDates[minDiffIndex];
  };
  const handleFetchData = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const userContracts = await getMembership({
      variables: {
        memberId: user.attributes['custom:memberId'],
      },
    });
    const dataFilter = nearestDate(
      userContracts.data.getMemberContractByMemberId.items[0]?.billings?.items?.filter(
        (i) => !i.isProcessed
      )
    );
    if (
      userContracts.data.getMemberContractByMemberId.items[0]?.billings?.items
        .length > 0
    ) {
      setNextDirect(moment(dataFilter?.debitDate).format('DD/MM/YYYY'));
    }
  };
  // console.log(minDate,'sort')

  const handleOpenMakePaymentModal = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setLoadingModal(true);
      const userContracts = await getMembership({
        variables: {
          memberId: user.attributes['custom:memberId'],
        },
      });
      await handleUpdateInfo();
      let data;
      if (membership.length)
        data = await changeMembershipApi({
          variables: {
            memberId: user.attributes['custom:memberId'],
            membershipLocationId: newMembershipLocationId,
            newMembershipId: membershipDetail.membershipNew.membershipId,
            oldMemberContractId:
              userContracts.data.getMemberContractByMemberId.items.filter(
                (i) => {
                  if (i.expiryDateTime != null) {
                    if (
                      new Date(i.expiryDateTime).valueOf() <
                      new Date().valueOf()
                    ) {
                      return false;
                    }
                  }
                  if (i.endDateTime != null) {
                    if (
                      new Date(i.endDateTime).valueOf() < new Date().valueOf()
                    ) {
                      return false;
                    }
                  }
                  return true;
                }
              )[0]?.id ?? '',
            getInformation: true,
            // paymentType: paymentMethod,
          },
        });
      // else
      //   data = await addMembershipToMember({
      //     variables: {
      //       memberId: userInfo.memberId,
      //       membershipLocationId: newMembershipLocationId,
      //       // paymentType: paymentType,
      //       // cardDetails: cardDetails,
      //       // accountDetails: accountDetails,
      //       // paymentId: paymentId,
      //       membershipStartDate: membershipStartDate,
      //     },
      //     // onCompleted: () => {
      //     // OnSubmitProps.setSubmitting(false);
      //     // OnSubmitProps.resetForm();
      //     // toast.success('Membership added to member successfully');
      //     // setIndex(1);
      //     // navigate(`/dashboard/memberdetails/${member?.getMember?.memberId}`);
      //     // },
      //     // onError: () => {
      //     // OnSubmitProps.setSubmitting(false);
      //     // toast.error('Error: Membership cannot be added');
      //     // },
      //   });
      willRefetch(true);

      // const memberDataApi = await getMemberApi({
      //   variables: { memberId: userInfo.memberId },
      // });
      // const memberData = memberDataApi.data.getMember;
      // const imgUrl = memberData?.imageUrl
      //   ? await Storage.get(memberData.imageUrl)
      //   : '/icons/Dashboard/profile.svg';
      // setUserInfo({ ...memberData, imageUrl: imgUrl });
      // setInfo({
      //   memberDetails: {
      //     ...info.memberDetails,
      //     membershipId: membershipDetail.membershipNew.membershipId,
      //   },
      // });
      const downGrade =
        currentMembership?.costPrice > membershipDetail?.costPrice;
      const upGrade =
        currentMembership?.costPrice <= membershipDetail?.costPrice;
      setNewLocationId(
        membershipDetail?.membershipLocations.items[0].locationId
      );
      if (!membership.length) {
        console.log('abcdef');
      } else if (downGrade) {
        setAmountChangeMembership(data.data.changeMembership.downgradeFee);
        setChangeMembershipData({
          ...changeMembershipData,
          changeMembershipType: 'downGrade',
          newMemberShipId: membershipDetail.membershipNew.NewMembershipId,
          locationId:
            userContracts.data.getMemberContractByMemberId.items[0].locationId,
        });
      } else if (upGrade) {
        setAmountChangeMembership(
          data.data.changeMembership.upfrontPaymentAmount
        );
        setChangeMembershipData({
          ...changeMembershipData,
          changeMembershipType: 'upGrade',
          newMemberShipId: membershipDetail.membershipNew.NewMembershipId,
          locationId:
            userContracts.data.getMemberContractByMemberId.items[0].locationId,
        });
      } else if (isPIF && newMembershipType === 'pif') {
        setAmountChangeMembership(
          data.data.changeMembership.upfrontPaymentAmount
        );
        if (downGrade) {
          setChangeMembershipData({
            ...changeMembershipData,
            changeMembershipType: 'downGrade',
            newMemberShipId: membershipDetail.membershipNew.NewMembershipId,
            locationId:
              userContracts.data.getMemberContractByMemberId.items[0]
                .locationId,
          });
        } else if (upGrade) {
          setChangeMembershipData({
            ...changeMembershipData,
            changeMembershipType: 'upGrade',
            newMemberShipId: membershipDetail.membershipNew.NewMembershipId,
            locationId:
              userContracts.data.getMemberContractByMemberId.items[0]
                .locationId,
          });
        }
      }
      if (!membership.length) setAddMembershipPaymentModal(true);
      else setModalPayment(true);
      setStep(6);
      setModal(false);
    } catch (e) {
      toastError(e.message);
      setStep(1);
    } finally {
      setLoadingModal(false);
    }
  };

  const ConfirmMembershipInfo = () => {
    return (
      <div>
        <p className="sm:hidden uppercase font-bold leading-[90%] text-[30px] text-white h-[46px]">
          Confirm your new membership
        </p>
        <div className="border-b border-[rgba(255,255,255,0.4)]">
          <p className="sm:hidden text-white font-medium text-sm leading-[140%] tracking-[1.4px] uppercase border-b-[#d3d3d3] border-b-2 pb-[20px]">
            MEMBERSHIP PLAN
          </p>
          <div className="flex justify-between pr-[13px] items-center">
            <p className="sm:hidden text-white font-normal leading-[160%] text-xs mt-6">
              Membership Plan
            </p>
            <p className="sm:hidden text-[rgba(255,255,255,0.57)] font-normal leading-[160%] text-xs mt-6 underline">
              Edit
            </p>
          </div>
          <div>
            <div className="flex justify-between pr-[13px] items-center mt-[16px] sm:pt-4 sm:pb-[6px] ">
              <p className="text-white text-[11px] font-medium sm:text-base sm:font-normal">
                {membershipDetail.membershipName}
              </p>
              <p className="text-white text-[11px] font-medium sm:text-base sm:font-normal">
                $
                {(
                  membershipDetail.costPrice /
                  membershipDetail.paymentFrequency.amount
                ).toFixed(2)}{' '}
                {membershipDetail.paymentFrequency.type === 'WEEKS' &&
                  'per week'}
              </p>
            </div>
            <div className=" justify-between pr-[13px] items-center sm:pb-4 hidden sm:flex ">
              <p className="text-white text-[11px] font-medium sm:text-base sm:font-normal">
                Activation Fee
              </p>
              <p className="text-white text-[11px] font-medium sm:text-base sm:font-normal">
                ${membershipDetail.joiningFee + membershipDetail.joiningFee2}
              </p>
            </div>
          </div>
          <p
            className={`mt-[6px] sm:text-base text-start h-[34px] mb-[7px] sm:mb-[20px] leading-[132%]  ${
              newMembershipType == 'weekly'
                ? 'text-[rgba(255,255,255,0.6)] text-center text-xs font-normal'
                : 'text-white text-center text-base font-medium'
            }`}
          >
            {newMembershipType == 'weekly' ? (
              <>
                ${membershipDetail.costPrice} fortnightly Direct Debit <br />{' '}
                {membershipDetail?.contractLength
                  ? membershipDetail?.contractLength?.amount +
                    ' ' +
                    membershipDetail?.contractLength?.type
                  : ''}
              </>
            ) : (
              'SINGLE PAYMENT'
            )}
            {}
          </p>
        </div>
        <div className="border-b border-[rgba(255,255,255,0.4)]">
          <div className="pt-[17px] pb-[20px] pr-[13px] flex justify-between items-center">
            <div className="flex flex-col gap-[11px]">
              <p className="text-white font-normal text-xs sm:text-base sm:text-textSecondary sm:leading-[21.36px] sm:font-normal">
                Start date
              </p>
              <p className="text-white font-medium text-[11px] leading-[140%] uppercase sm:text-base sm:leading-[21.36px] sm:font-normal">
                {moment().format('DD MMMM YYYY')}
              </p>
            </div>
          </div>
        </div>
        <div className="flex justify-between pr-[13px] items-center py-[16px] border-b border-[rgba(255,255,255,0.4)] sm:py-4 sm:flex-col sm:items-start sm:gap-y-1">
          <p className="text-white text-[12px] font-medium sm:text-base sm:text-textSecondary sm:leading-[21.36px] sm:font-normal">
            Cost per fortnight
          </p>
          <p className="text-white text-[11px] font-medium sm:text-base sm:leading-[21.36px] sm:font-normal">
            ${membershipDetail.costPrice}
          </p>
        </div>
        {/*{membershipDetail?.costPrice +*/}
        {/*  membershipDetail?.joiningFee +*/}
        {/*  membershipDetail?.joiningFee2 +*/}
        {/*  membershipDetail?.joiningFee3}*/}
        <div className="flex justify-between pr-[13px] items-center py-[7px] border-b border-[rgba(255,255,255,0.4)] sm:py-4 sm:flex-col sm:items-start sm:gap-y-1">
          <p className="text-white text-[12px] font-medium sm:text-base sm:text-textSecondary sm:leading-[21.36px] sm:font-normal">
            Promo code
          </p>
          <input
            className="sm:hidden text-white text-[11px] border-solid border-[1px] border-[rgba(255,255,255,0.40)] bg-transparent font-semibold rounded-md px-[8px] py-[9px] w-[142px]"
            defaultValue="PF-"
          />
          <p className="sm:block hidden text-white font-medium text-[12px] leading-[140%] uppercase sm:text-base sm:leading-[21.36px] sm:font-normal">
            PF-XXXXXX
          </p>
        </div>
        {!isPIF && (
          <div className="flex justify-between pr-[13px] items-center py-[12px] border-b border-[rgba(255,255,255,0.4)] sm:py-4 sm:flex-col sm:items-start sm:gap-y-1">
            <p className="text-white text-[12px] font-medium sm:text-base sm:text-textSecondary sm:leading-[21.36px] sm:font-normal">
              Next direct debit
            </p>
            <p className="text-white font-medium text-[12px] leading-[140%] uppercase sm:text-base sm:leading-[21.36px] sm:font-normal">
              {nextDirect
                ? nextDirect
                : moment().add('2', 'weeks').format('DD/MM/YYYY')}
            </p>
          </div>
        )}
        <div className="sm:hidden flex items-center mt-[56px] justify-between">
          <div onClick={() => setStep(step - 1)}>
            <ArrowButton text="back" isRight={false} />
          </div>
          <div
            onClick={() => {
              setStep(step + 1);
            }}
          >
            <ArrowButton text="next" isRight={true} />
          </div>
        </div>
      </div>
    );
  };
  const [terms, setTerms] = useState<boolean>(false);
  const ConfirmYourDetails = () => {
    return (
      <div>
        <p className="sm:hidden uppercase font-bold leading-[90%] text-[30px] text-white h-[46px]">
          Confirm your details
        </p>
        <div className="">
          <p className="sm:hidden text-white font-medium text-sm leading-[140%] tracking-[1.4px] uppercase border-b-[#d3d3d3] border-b-2 pb-[20px]">
            contact details
          </p>
          <div className="sm:hidden grid grid-cols-5 gap-y-[14px] w-full pt-[14px] items-center">
            <div className="grid grid-cols-2 col-span-4">
              <InputAnimated
                readOnly
                label="First name"
                type="text"
                value={updatedInfo.givenName}
                name="surname"
              />
              <InputAnimated
                readOnly
                label="Last name"
                type="text"
                value={updatedInfo.surname}
                name="lastName"
              />
            </div>
            <div className="relative ">
              <div
                className={`flex items-center mt-[15px] pr-[12px] pb-[7px] bg-transparent border-b border-[rgba(255,255,255,0.4)] cursor-pointer w-full focus:ring-1`}
                // onClick={() => setShowGender(!isShowGender)}
              >
                <div className="bg-transparent h-[20px] outline-none w-full  truncate text-white">
                  <span
                    className={`form__label text-[#848484] gotham absolute  transition-all duration-300
                            ${
                              selectedGender
                                ? 'top-1 left-0 text-[11px]'
                                : 'text-sm top-[15px] left-0'
                            }
                            `}
                  >
                    Gender
                  </span>
                  <span className="text-sm gotham font-medium uppercase tracking-widest">
                    {selectedGender}
                  </span>
                </div>
                <div
                  className={`w-[10px] h-[10px] border-l-[2px] border-b-[2px] transition-all duration-150 -rotate-45 ${
                    isShowGender
                      ? 'border-brand-clime rotate-[135deg]'
                      : 'border-gray'
                  }`}
                ></div>
              </div>
              <div
                className={`w-full bg-[#13181b] border-gray text-white border-2 absolute z-40 ${
                  isShowGender ? '' : 'hidden'
                }`}
              >
                {['MALE', 'FEMALE']?.map((item: any, index: number) => (
                  <div
                    key={index}
                    className="cursor-pointer uppercase gotham font-medium tracking-widest text-xs px-4 py-2 hover:text-black hover:bg-primary"
                    // onClick={() => {
                    //   setShowGender(false);
                    //   setSelectedGender(item);
                    //   // setDropdownSelected(true);
                    // }}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </div>
            <div className="grid w-full col-span-5 grid-cols-2">
              <InputAnimated
                readOnly
                label="Email"
                type="text"
                value={updatedInfo.email}
                name="givenName"
              />
              <InputAnimated
                readOnly
                label="Phone number"
                type="text"
                value={updatedInfo.mobileNumber}
                name="givenName"
              />
            </div>
          </div>
          <div className="flex justify-between pr-[13px] items-center mt-[16px]"></div>
        </div>
        <div className="sm:hidden pb-[7px] border-b-2 border-b-white mt-[44px]">
          <h2 className="heading2 !text-[14px] text-white uppercase text-left font-medium">
            health check
          </h2>
        </div>
        <div className="mt-5 flex items-center justify-between sm:flex-col sm:items-start sm:gap-y-5 sm:mt-4">
          <h6 className="gotham text-sm text-gray sm:text-white sm:text-base sm:leading-[23px]">
            Do you have any injuries, disabilities or current health conditions?
            *
          </h6>
          <div className="flex items-center gap-4 sm:gap-5 sm:w-full">
            <div
              onClick={() => setTerms(true)}
              className={`${
                terms ? 'bg-primary border-none' : 'bg-transparent border-white'
              } sm:w-1/2 cursor-pointer flex items-center justify-center text-sm border rounded w-[70px] h-[37px]  text-white uppercase`}
            >
              yes
            </div>
            <div
              onClick={() => setTerms(false)}
              className={`${
                !terms
                  ? 'bg-primary border-none'
                  : 'bg-transparent border-white'
              } sm:w-1/2 cursor-pointer flex items-center justify-center border text-sm rounded bg-primary w-[70px] h-[37px] text-white uppercase`}
            >
              no
            </div>
          </div>
        </div>
        {/* MOBILE */}
        {!terms && (
          <div className="sm:block hidden">
            <div className="pb-[6px] border-b border-b-white border-opacity-40 mt-8">
              <h2 className="text-base text-white font-bold leading-[160%] uppercase">
                terms and conditions*
              </h2>
            </div>
            <div className="flex flex-col gap-y-5 mt-5 mb-7">
              <div className="flex items-center justify-between gap-x-4">
                <CheckboxActive />
                <p className="text-base text-white leading-[133.5%]">
                  I confirm I have read and accept the terms and conditions.
                </p>
              </div>
              <div className="flex items-center justify-between gap-x-4">
                <CheckboxActive />
                <p className="text-base text-white leading-[133.5%]">
                  I confirm I meet the requirements from the pre-exercise
                  checklist.
                </p>
              </div>
            </div>
          </div>
        )}

        {terms && (
          <div className="mt-5 border-b border-b-textSecondary sm:border-b-0">
            {/* DESKTOP */}
            <h2 className="sm:hidden gotham text-sm uppercase text-textSecondary text-left">
              Please select the relevant box below*
            </h2>
            <p className="sm:hidden text-textSecondary gotham text-[12px] mt-1 text-left">
              Note you must select one option, in order to proceed with this gym
              membership.
            </p>
            {/* MOBILE */}
            <div className="sm:block hidden pb-[6px] border-b border-b-white border-opacity-40 mt-8">
              <h2 className="text-base text-white font-bold leading-[160%] uppercase">
                pre-exercise checklist*
              </h2>
            </div>
            <p className="sm:block hidden text-textSecondary text-base leading-[21.36px] mt-3 sm:font-normal">
              Please complete the following questions, so we are aware of your
              current health situation:
            </p>
            {/* ------------ */}
            <div className="flex flex-col mt-4">
              <div className="flex items-start md:flex-row-reverse flex-row md:justify-end justify-between gap-4 py-3 sm:px-0 px-3">
                <p className="text-gray gotham text-left">
                  I will seek guidance from an appropriate health or medical
                  practitioner prior to undertaking exercise.
                </p>
                {width && width < breakpoints.sm ? (
                  <Switch isYesNo />
                ) : (
                  <Switch isYesNo />
                )}
              </div>
              <div className="flex items-start md:flex-row-reverse flex-row md:justify-end justify-between gap-4 py-3 sm:px-0 px-3">
                <p className="text-gray gotham text-left">
                  Have you ever been told by a doctor that you have a heart
                  condition or have suffered a stroke?
                </p>
                {width && width < breakpoints.sm ? (
                  <Switch isYesNo />
                ) : (
                  <Switch isYesNo />
                )}
              </div>{' '}
              <div className="flex items-start md:flex-row-reverse flex-row md:justify-end justify-between gap-4 py-3 sm:px-0 px-3">
                <p className="text-gray gotham text-left">
                  Do you ever have unexplained pains in your chest at rest or
                  during physical exercise?
                </p>
                {width && width < breakpoints.sm ? (
                  <Switch isYesNo />
                ) : (
                  <Switch isYesNo />
                )}
              </div>{' '}
              <div className="flex items-start md:flex-row-reverse flex-row md:justify-end justify-between gap-4 py-3 sm:px-0 px-3">
                <p className="text-gray gotham text-left">
                  Do you consistently feel faint or suffer from spells of
                  dizziness?
                </p>
                {width && width < breakpoints.sm ? (
                  <Switch isYesNo />
                ) : (
                  <Switch isYesNo />
                )}
              </div>{' '}
              <div className="flex items-start md:flex-row-reverse flex-row md:justify-end justify-between gap-4 py-3 sm:px-0 px-3">
                <p className="text-gray gotham text-left">
                  Do you suffer from type I or II diabetes and have trouble
                  controlling your blood glucose levels?
                </p>
                {width && width < breakpoints.sm ? (
                  <Switch isYesNo />
                ) : (
                  <Switch isYesNo />
                )}
              </div>
              <div className="flex items-start md:flex-row-reverse flex-row md:justify-end justify-between gap-4 py-3 sm:px-0 px-3">
                <p className="text-gray gotham text-left">
                  I am under the supervision of a medical practitioner.
                </p>
                {width && width < breakpoints.sm ? (
                  <Switch isYesNo />
                ) : (
                  <Switch isYesNo />
                )}
              </div>
              <div className="flex items-start md:flex-row-reverse flex-row md:justify-end justify-between gap-4 py-3 sm:px-0 px-3">
                <p className="text-gray gotham text-left">
                  High or low blood pressure?
                </p>
                {width && width < breakpoints.sm ? (
                  <Switch isYesNo />
                ) : (
                  <Switch isYesNo />
                )}
              </div>
              <div className="flex items-start md:flex-row-reverse flex-row md:justify-end justify-between gap-4 py-3 sm:px-0 px-3">
                <p className="text-gray gotham text-left">Pregnant?</p>
                {width && width < breakpoints.sm ? (
                  <Switch isYesNo />
                ) : (
                  <Switch isYesNo />
                )}
              </div>
              <div className="flex items-start md:flex-row-reverse flex-row md:justify-end justify-between gap-4 py-3 sm:px-0 px-3">
                <p className="text-gray gotham text-left">
                  Tightness in chest?
                </p>
                {width && width < breakpoints.sm ? (
                  <Switch isYesNo />
                ) : (
                  <Switch isYesNo />
                )}
              </div>
              <div className="flex items-start md:flex-row-reverse flex-row md:justify-end justify-between gap-4 py-3 sm:px-0 px-3">
                <p className="text-gray gotham text-left">
                  Undergoing cancer treatment?
                </p>
                {width && width < breakpoints.sm ? (
                  <Switch isYesNo />
                ) : (
                  <Switch isYesNo />
                )}
              </div>
              {/* ANOTHER SECTION */}
              <div className="mb-[15px] hidden sm:flex border-none flex-col gap-[3px] mt-[40px] sm:mt-7 sm:mb-0">
                <h2 className="gotham text-base uppercase text-white leading-[160%] font-bold text-left">
                  Please select the relevant box below*
                </h2>
                <p className="text-textSecondary border-none gotham text-[16px] leading-[133%] mt-1 text-start sm:mb-5">
                  Note you must select one option, in order to proceed with this
                  gym membership.
                </p>
              </div>
              <div className="items-center hidden sm:flex md:flex-row-reverse flex-row md:justify-end justify-between gap-4 sm:px-0 sm:items-start">
                <p className="text-gray  leading-[135%] sm:text-white gotham text-left">
                  I will seek guidance from an appropriate health or medical
                  practitioner prior to undertaking exercise.
                </p>
                <div className="flex gap-2 sm:mt-1">
                  <CheckboxActive />
                </div>
              </div>
              <div className="items-center hidden sm:flex md:flex-row-reverse flex-row md:justify-end justify-between gap-4 pb-[27px] sm:px-0 sm:pb-0 sm:items-start">
                <p className="text-gray leading-[135%] sm:text-white  gotham text-left">
                  I am under the supervision of a medical practitioner.
                </p>
                <div className="flex gap-2 sm:mt-1">
                  <CheckboxActive />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="sm:hidden pb-[7px] border-b-2 border-b-white mt-[40px]">
          <h2 className=" heading2 !text-[14px] text-white uppercase text-left font-medium">
            Emergency Contact
          </h2>
        </div>
        <h2 className="sm:block sm:my-7 hidden relative font-bicyclette font-bold text-primary text-xl leading-[18px] uppercase after:content-[' '] after:absolute after:left-0 after:right-0 after:-bottom-2 after:h-[1px] after:bg-primary">
          Emergency Contact
        </h2>
        <div className="mt-[25px] grid grid-cols-3 sm:grid-cols-1 sm:gap-y-[23px]">
          <InputBase
            nameInput="emergencyContactName"
            holder="Emergency contact name*"
            style="lg:w-full"
            isRequired={true}
            value={updatedInfo.emergencyContactName}
            onchange={handleChange}
            revertColor={width && width <= breakpoints.sm ? true : false}
          />
          <InputBase
            nameInput="emergencyContactRelation"
            holder="Relation to you*"
            style="lg:w-full"
            isRequired={true}
            value={updatedInfo.emergencyContactRelation}
            onchange={handleChange}
            revertColor={width && width <= breakpoints.sm ? true : false}
          />
          <InputNumber
            prefix={prefixEmergencyNumber}
            onChangePrefix={(value) => setPrefixEmergencyNumber(value)}
            nameInput="emergencyContactNumber"
            holder="Emergency phone*"
            style="lg:w-full"
            isRequired={true}
            value={updatedInfo.emergencyContactNumber}
            onchange={handleChange}
            revertColor={width && width <= breakpoints.sm ? true : false}
          />

          {/* <div className="mt-[50px]"><h2 className="text-brand-clime text-[30px] bebas font-normal uppercase border-b border-b-brand-clime pb-2">TERMS AND CONDITIONS</h2></div> */}
        </div>
        {/* DESKTOP T&C*/}
        <div className="sm:hidden flex items-center gap-[16px] mt-6 px-1">
          <CheckboxActive onChecked={(e) => setAccept(e)} />
          <p className="text-gray gotham select-none max-w-[780px] text-xs">
            I agree to the{' '}
            <span
              className="underline cursor-pointer text-xs"
              onClick={() => setTAndC(true)}
            >
              terms & conditions
            </span>{' '}
            and the{' '}
            <span
              className="underline cursor-pointer text-xs"
              onClick={() => setPrivacyPolicy(true)}
            >
              privacy policy
            </span>{' '}
            attached to my membership.*
          </p>
        </div>
        {/* MOBILE T&C */}
        {terms && (
          <div>
            <div className="sm:block hidden pb-[6px] border-b border-b-white border-opacity-40 mt-8">
              <h2 className="text-base text-white font-bold leading-[160%] uppercase">
                Terms and conditions*
              </h2>
            </div>
            <div className="sm:flex hidden items-start gap-x-4 mt-6 px-1">
              <CheckboxActive onChecked={(e) => setAccept(e)} />
              <p className="text-base text-white leading-[133.5%]">
                I confirm I have read and accept the terms and conditions.
              </p>
            </div>
          </div>
        )}

        <div className="flex items-center mt-6 justify-between sm:mt-10">
          <div className="sm:hidden" onClick={() => setStep(step - 1)}>
            <ArrowButton text="back" isRight={false} />
          </div>
          <div className="sm:block hidden" onClick={() => setStep(step - 1)}>
            <ArrowButton text="" isRight={false} />
          </div>
          <div
            onClick={() => {
              if (accept) setStep(step + 1);
              else toastError('please accept the term and condition checkbox');
            }}
          >
            <ArrowButton text="next" isRight={true} />
          </div>
        </div>
      </div>
    );
  };
  // const [addMembershipToMember] = useMutation(ADD_MEMBERSHIP_TO_MEMBER);
  const [isSameInfo, setIsSameInfo] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<string>('useCardOnFile');
  const renderHeadingTitleMobile = React.useMemo(() => {
    switch (step) {
      case 1:
        return 'CHANGE MEMBERSHIP';
      case 2:
        return 'CHANGE MEMBERSHIP';
      case 3:
        return 'CONFIRM YOU DETAILS';
      case 4:
        return 'CONFIRM YOUR PAYMENT DETAILS';
      case 5:
      case 6:
        return 'YOUR MEMBERSHIP IS NOW UPDATED';
      default:
        return 'NOT FOUND';
    }
  }, [step]);

  const ConfirmPaymentDetail = () => {
    return (
      <div>
        <p className="sm:hidden uppercase font-bold leading-[90%] text-[30px] text-white h-[46px]">
          Confirm your PAYMENT details
        </p>
        <div className="">
          <div className="sm:flex hidden items-center justify-between gap-x-2 mt-8">
            <p className="text-base text-white font-bold leading-[160%] uppercase">
              Direct debit (fortnightly)
            </p>
            <p className="text-lg text-primary font-bold leading-[160%]">
              $33.80
            </p>
          </div>

          {paymentMethod === 'creditCard' && (
            <div className="hidden sm:flex items-center gap-x-4 mb-7 mt-9">
              {/* <CheckboxActive onChecked={() => setIsSameInfo(!isSameInfo)} /> */}
              <p className="text-white text-base leading-[21.36px] font-normal">
                Same card as above
              </p>
            </div>
          )}
          <div>
            {paymentMethod === 'bankAccount' && !isSameInfo && (
              <div className="mt-[33px] grid grid-cols-6 gap-y-[13px] sm:gap-y-0 sm:grid-cols-1 sm:mt-5">
                <div className="col-span-2 flex flex-col sm:gap-y-5 sm:mb-5 gap-y-3">
                  <InputBase
                    holder="Account name"
                    style="lg:w-full"
                    isRequired={true}
                    onchange={(e) => console.log(e)}
                  />
                  <InputBase
                    holder="BSB*"
                    style="lg:w-full"
                    isRequired={true}
                    onchange={(e) => console.log(e)}
                  />
                </div>
                <div className="col-span-4 flex flex-col sm:gap-y-5 gap-y-3">
                  <InputBase
                    holder="Bank name"
                    style="lg:w-full"
                    isRequired={true}
                    onchange={(e) => console.log(e)}
                  />
                  <InputBase
                    holder="Account number"
                    style="lg:w-full"
                    isRequired={true}
                    onchange={(e) => console.log(e)}
                  />
                </div>
              </div>
            )}
            {!isSameInfo && paymentMethod === 'creditCard' && (
              <div className="mt-[33px] grid grid-cols-6 gap-y-[13px] sm:gap-y-0 sm:grid-cols-1 sm:mt-5">
                <div className="col-span-2 flex flex-col sm:gap-y-5 sm:mb-5 gap-y-3">
                  <InputBase
                    holder="Name on the card*"
                    style="lg:w-full"
                    isRequired={true}
                  />
                  <InputBase
                    holder="Card number*"
                    style="lg:w-full"
                    isRequired={true}
                  />
                </div>
                <div className="col-span-4 flex flex-col sm:gap-y-5 gap-y-3">
                  <InputBase
                    onchange={(e) => setDataAccount(e)}
                    nameInput="cardExpiryDate"
                    holder="Expiry date*"
                    style="lg:w-full"
                    isRequired={true}
                  />
                  <InputBase
                    onchange={(e) => setDataAccount(e)}
                    nameInput="cardCvv"
                    holder="CCV/CVV*"
                    style="lg:w-full"
                    isRequired={true}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center mt-6 justify-between">
          <div className="block sm:hidden" onClick={() => setStep(step - 1)}>
            <ArrowButton text="back" isRight={false} />
          </div>
          <div className="sm:block hidden" onClick={() => setStep(step - 1)}>
            <ArrowButton text="" isRight={false} />
          </div>
          <div
            onClick={() => {
              handleCallApiChangeMembership();
            }}
          >
            <ArrowButton text="confirm" isRight={true} />
          </div>
        </div>
      </div>
    );
  };
  const congrats = () => {
    return (
      <div
        id="hide-scroll"
        className="sm:grid-cols-1 sm:!h-full md:h-[70vh] h-full w-full md:overflow-y-scroll overflow-y-hidden grid grid-cols-3 gap-6 sm:gap-0 sm:w-full"
      >
        <div className="col-span-2 sm:mt-9">
          <h2 className="sm:!hidden text-primary border-b border-b-primary pb-2 text-[25px] text-left md:flex hidden uppercase heading2">
            success, you’ve just <br /> change membership
          </h2>
          <div className="mt-[47px] flex flex-col  md:items-start items-start w-full justify-between sm:mt-0">
            <div className="flex flex-col justify-start items-start mb-[20px] sm:mb-6">
              <h2
                style={{ fontSize: '40px' }}
                className="font-bold uppercase text-start text-white  md:mb-3 mb-0 heading font-bicyclette sm:text-[40px] sm:text-left sm:!mb-0"
              >
                Congrats, {userInfo.givenName} {userInfo.surname}!
              </h2>
              <div
                className="sm:hidden block mt-4"
                onClick={() => window.location.replace('/dashboard')}
              >
                <ArrowButton text="dashboard " isRight={true} />
              </div>
            </div>
            <div className="sm:hidden flex gap-x-12 items-start justify-between">
              <div>
                {/* <h2 className='gotham text-sm text-white text-left'>Your membership number</h2>
                      <p className='gotham text-textSecondary text-left tracking-[1px] text-[12px] mt-1'>123 456 789</p> */}
                <h2 className="gotham text-sm text-white text-left">
                  Username
                </h2>
                <p className="gotham text-textSecondary text-left tracking-[1px] text-[12px] mt-1">
                  {userInfo.email}
                </p>
              </div>
              <div>
                <h2 className="gotham text-sm text-white text-left">
                  Start date
                </h2>
                <p className="gotham text-textSecondary text-left tracking-[1px] text-[12px] mt-1">
                  Today, {moment().format('DD/MM/YYYY')}
                </p>
              </div>
            </div>
            <div className="sm:block hidden">
              <h2 className="text-white text-base leading-[21.36px] mb-1">
                New membership starts
              </h2>
              <p className="text-textSecondary text-base leading-[21.36px]">
                {moment().format('DD/MM/YYYY')}
              </p>
            </div>
          </div>
          <div className="mt-[57px] sm:mt-7">
            <h2 className="uppercase text-left pb-[10px] border-b border-b-primary text-primary heading2 f font-bicyclette sm:text-xl sm:font-bold">
              next steps
            </h2>
          </div>
          <div className="mt-[28px]">
            <div className="flex md:flex-col flex-row md:items-start pb-3 border-b border-b-borderLine items-center justify-between">
              <div className="flex items-center gap-4 sm:gap-x-5 sm:items-start">
                <h1 className="font-bold md:text-white text-primary heading font-bicyclette sm:text-[30px]">
                  01
                </h1>
                <div className="sm:flex sm:flex-col sm:gap-y-3">
                  <p className="uppercase font-bold md:text-primary text-white heading2 font-bicyclette text-lg sm:text-base">
                    Check your email
                  </p>
                  <p className="sm:block hidden text-white">
                    We’ve sent your membership number, contract and receipt to
                    your email.
                  </p>
                </div>
              </div>
              <p className="sm:hidden text-white md:text-left text-right md:mt-2 mt-0">
                We’ve sent your membership number, and payment receipt to your
                nominated <br /> email address to your email address.
              </p>
            </div>
            <div className="flex pt-4 pb-3 md:flex-col flex-row md:items-start border-b border-b-borderLine items-center justify-between">
              <div className="flex items-center gap-4 sm:gap-x-5 sm:items-start">
                <h1 className="font-bold md:text-white text-primary heading font-bicyclette sm:text-[30px]">
                  02
                </h1>
                <div className="sm:flex sm:flex-col sm:gap-y-3">
                  <p className="sm:hidden uppercase font-bold md:text-primary text-white heading2 font-bicyclette text-lg sm:text-base">
                    Check the app
                  </p>
                  <p className="sm:block hidden uppercase font-bold md:text-primary text-white heading2 font-bicyclette text-lg sm:text-base">
                    Download the app
                  </p>
                  <p className="sm:block hidden text-white">
                    Download the Club Lime Member ID App and get it set up on
                    your phone. Unlock your access pass.
                  </p>
                  <div className="sm:flex hidden mt-2 justify-between items-center gap-x-1">
                    <img
                      className="h-[34px] w-1/2"
                      src={appstore}
                      alt="appstore"
                    />
                    <img
                      className="h-[53px] w-1/2"
                      src={googlePlay}
                      alt="ggplay"
                    />
                  </div>
                </div>
              </div>
              <div className="sm:hidden">
                <p className=" text-white md:text-left text-right md:mt-2 mt-0">
                  Download the Plus Fitness app, get it setup on your phone
                  <br />
                </p>
                <div className="mt-2 flex md:justify-start justify-end items-center gap-3">
                  <img
                    className="h-[34px] w-auto"
                    src={appstore}
                    alt="appstore"
                  />
                  <img
                    className="h-[53px] w-auto"
                    src={googlePlay}
                    alt="ggplay"
                  />
                </div>
              </div>
            </div>
            <div className="flex pt-6 pb-3 md:flex-col flex-row md:items-start items-center justify-between">
              <div className="flex items-center gap-4 sm:gap-x-5 sm:items-start">
                <h1 className="font-bold md:text-white text-primary heading font-bicyclette sm:text-[30px]">
                  03
                </h1>
                <div className="sm:flex sm:flex-col sm:gap-y-3">
                  <p className="uppercase text-left font-bold md:text-primary text-white heading2 font-bicyclette text-lg sm:text-base">
                    Come to your club and <br />
                    start training
                  </p>

                  <p className="sm:block hidden text-white">
                    Congratulations on taking the first step of your fitness
                    journey at Club Lime. Simply use the Club Lime Member ID App
                    to access your gym and get your sweat on!
                  </p>
                </div>
              </div>
              <p className="sm:hidden text-white md:text-left text-right md:mt-2 mt-0">
                Congratulations on taking the first step of your fitness journey
                at Plus Fitness. <br /> Simply use the mobile member app to
                access your gym and get your sweat on!
              </p>
            </div>
          </div>
        </div>
        <div className="hidden sm:block mt-10">
          <PrimaryOutlinedButton
            onClick={() => navigate('/dashboard')}
            className="h-[34px] w-full rounded flex items-center justify-between !px-0 mb-9"
          >
            {/* <span className="blank w-[45px]"></span> */}
            <p className="text-center w-full uppercase text-base font-bold">
              need a personal trainer?
            </p>
            <span className="border-l border-l-white px-3 flex h-[40px] items-center justify-center min-w-[45px]">
              <img className="w-auto h-auto" src={arrowRight} alt="icon" />
            </span>
          </PrimaryOutlinedButton>
          <PrimaryOutlinedButton
            onClick={() => navigate('/dashboard')}
            className="h-[52px] w-full uppercase rounded text-base text-center font-bold"
          >
            Take me to dashboard
          </PrimaryOutlinedButton>
        </div>
        <div className="sm:hidden">
          <img className="h-full w-full" src={congratsImg} />
        </div>
      </div>
    );
  };
  return (
    <>
      {TAndC && (
        <ModalBase noBaseHeader onClickOutSide={() => setTAndC(false)}>
          <div className="h-[800px] overflow-y-auto" id="scroll-hidden">
            <section className="bg-dark pt-6 pb-20">
              <div className="containerPage">
                <h1 className="heading heading-underline text-white">
                  {termAndCondition?.title}
                </h1>
                <div className="contentWrapper">
                  <MyReactQuill content={termAndCondition?.content} />
                </div>
              </div>
            </section>
          </div>
        </ModalBase>
      )}

      {privacyPolicy && (
        <ModalBase noBaseHeader onClickOutSide={() => setPrivacyPolicy(false)}>
          <div className="h-[800px] overflow-y-auto" id="scroll-hidden">
            <section className="bg-dark pt-6 pb-20">
              <div className="containerPage">
                <h1 className="heading heading-underline text-white">
                  {privacyPolicyData?.title}
                </h1>
                <div className="contentWrapper">
                  <MyReactQuill content={privacyPolicyData?.content} />
                </div>
              </div>
            </section>
          </div>
        </ModalBase>
      )}
      {modal && (
        <Modal
          open={modal}
          onClose={() => !loadingModal && setModal(false)}
          styles={{
            overlay: {
              background: 'rgba(45, 45, 35, 0.4)',
            },
            modal: {
              background: 'black',
              height: 'auto',
              width: 'auto',
              padding: '0',
            },
            modalContainer: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          }}
        >
          {loadingModal && (
            <div className="bg-[rgba(0,0,0,0.4)] z-50 absolute h-full flex w-full">
              <div className="row examples m-auto ">
                <div className="  ">
                  <div className=" relative " data-title="dot-pulse">
                    <div className="stage w-[200px]">
                      <div className="dot-pulse"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="flex items-center justify-between pt-[33px] md:pt-6 pr-[33px] pl-[33px] sm:px-6 ">
            <div className="flex items-center gap-[17px] ">
              <img
                src={userInfo.imageUrl}
                className="inline-block rounded-full h-[38.7px] w-[38.7px] "
              />
              <span className="uppercase gotham text-[20px] text-white font-bicyclette tracking-[0.01em]">
                hello {userInfo.surname}
              </span>
            </div>
            <div
              onClick={() => setModal(false)}
              className="flex items-center justify-center w-8 h-8 rounded-full border border-white cursor-pointer"
            >
              <div className="w-4 h-4 block">
                <img
                  src="/icons/close.png"
                  alt="close"
                  className="object-fit w-full"
                />
              </div>
            </div>
          </div>
          <div className="cancelModal w-[688px] px-[33px]  pt-8  pb-10">
            <div className="cancelModalContent w-full h-full">
              <div className=" flex items-center mt-7">
                {/* Left content */}
                <div
                  className="w-1/2 sm:hidden h-full relative opacity-80"
                  style={{
                    minWidth: '310px',
                    minHeight: '370px',
                    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.24), rgba(0, 0, 0, 0.24)), url('/images/MyBookingsPage/cancel-booking-modal.jpg')`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                  }}
                ></div>
                {/* Right content */}
                <div className="w-1/2 sm:w-full sm:pl-[33px] h-full relative sm:static -left-7  sm:px-2">
                  <p className="text-sm text-[#7681FF] uppercase leading-4 font-medium mb-4">
                    Are you sure you want to change current membership to this?
                  </p>
                  <p className="uppercase text-white text-2xl leading-7 font-bold mb-7">
                    {membershipDetail.membershipName} <br />{' '}
                    <span>
                      {[
                        ...membershipDetail.description.split(','),
                        `Joining fee: $${membershipDetail.joiningFee}`,
                        `Activation fee: $${membershipDetail.joiningFee2}`,
                        `Card/fob fee: $${membershipDetail.joiningFee3}`,
                      ].map((i) => {
                        return (
                          <div className="flex items-end gap-1 mt-2">
                            <span className="self-start w-[19px] h-[19px] flex-none rounded-[50%] flex items-center justify-center border border-white">
                              <img
                                className="w-auto h-auto"
                                src={tick}
                                alt="icon"
                              />
                            </span>
                            <span
                              dangerouslySetInnerHTML={createMarkup(
                                i.replace('\n', '<br/>')
                              )}
                            ></span>
                          </div>
                        );
                      })}
                    </span>
                    <br /> ${membershipDetail.costPrice}
                  </p>

                  <div className="btnGroup flex items-center gap-x-8 sm:gap-x-4">
                    <PrimaryOutlinedButton
                      className="rounded-md w-[108px] sm:w-1/2"
                      // onClick={() => handleChangeMembership()}
                      onClick={handleOpenMakePaymentModal}
                    >
                      Yes
                    </PrimaryOutlinedButton>
                    <PrimaryOutlinedButton
                      className="rounded-md w-[108px] sm:w-1/2 hover:!bg-[#ff0000] hover:!border-[#ff0000]"
                      onClick={() => setModal(false)}
                    >
                      No
                    </PrimaryOutlinedButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {openModalPayment && modal && (
        <ModalMakePayment
          changeMembershipData={changeMembershipData}
          newLocationId={newLocationId}
          setModalMakePayment={setModalPayment}
          amount={amountChangeMembership}
          paymentType="changeMembership"
          setStep={setStep}
          openModalOutside={setModal}
          isPIF={isPIF}
        />
      )}
      {addMembershipPaymentModal && (
        <ModalMakePayment
          payloadData={{
            addMembershipArguments: {
              membershipStartDate: new Date().toISOString().split('T')[0],
              newLocationId: newMembershipLocationId,
              newMembershipId: membershipDetail.membershipNew.NewMembershipId,
            },
          }}
          newLocationId={newLocationId}
          setModalMakePayment={setAddMembershipPaymentModal}
          amount={
            membershipDetail.costPrice +
            membershipDetail.joiningFee +
            membershipDetail.joiningFee2 +
            membershipDetail.joiningFee3
          }
          paymentType="AMS"
          setStep={setStep}
          openModalOutside={setModal}
          isPIF={isPIF}
        />
      )}
      {/* DESKTOP DESIGN */}
      <div className="relative sm:hidden">
        <div
          className="absolute -top-7 pl-4 gotham text-sm tracking-[1.4px] font-medium text-white uppercase select-none"
          onClick={() => {
            step < 7 && setStep(step + 1);
          }}
        >
          Step {step}
        </div>
        <div
          className={`w-full h-[1px] mt-12 mb-6 bg-white grid grid-cols-5 relative`}
        >
          {new Array(step).fill('').map((i, idx) => (
            <div className="bg-primary h-[4px] -mt-[2px]"></div>
          ))}
        </div>

        {step == 1 && (
          <>
            {/* <Link to="/dashboard/account/biling">
            <div className="gotham uppercase text-sm leading-[19px] text-white tracking-widest font-medium">
              <div className="w-[41px] h-[48px] p-[7px] pl-0">
                <div className="border border-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
                  <img className="h-[15px] w-[15px]" src={arrowLeftShort} />
                </div>
              </div>
              <span className="gotham uppercase text-sm leading-[19px] text-white tracking-widest">
                billing
              </span>
            </div>
          </Link> */}
            <div className="">
              {/* <h6 className="uppercase text-white text-sm leading-[140%] tracking-widest gotham">
              step 1
            </h6> */}
              <p className="mt-[10px] font-bicyclette font-bold text-[30px] leading-[90%] uppercase text-white">
                Lets change your membership type
              </p>
              <h6 className="mt-[36px] text-white gotham text-sm leading-[140%] uppercase font-medium pb-[7px] w-full border-b border-b-white">
                Before you Start
              </h6>
              <div className="mt-[21px] flex flex-col  justify-start">
                <p className=" text-white gotham text-sm leading-[160%] font-normal">
                  Hello legend, we just wanted to let you know changing your
                  membership type will change the cost of your fortnightly
                  membership, please confirm you are happy to proceed
                </p>
                <div className="flex items-center justify-start mt-6 gap-[16px]">
                  <PrimaryOutlinedButton
                    onClick={() => {
                      setStep(2);
                      setIsStart(true);
                    }}
                    className="uppercase text-white font-medium border-white border rounded h-[37px] w-[99px] gotham !text-[11.2px]"
                  >
                    yes
                  </PrimaryOutlinedButton>
                  <PrimaryOutlinedButton
                    onClick={() => moveTab(0)}
                    className="uppercase text-white font-medium border-white border w-[107px] !px-0 rounded h-[37px] hover:!bg-[#DE0D0D] hover:!border-[#DE0D0D] hover:text-white  gotham !text-[11.2px]"
                  >
                    no thanks
                  </PrimaryOutlinedButton>
                </div>
              </div>
            </div>
          </>
        )}
        {/* Change home club */}
        {step == 2 && (
          <>
            <p className="mt-[10px] font-bicyclette font-bold text-[30px] leading-[90%] uppercase text-white">
              Select a new plan
            </p>
            <MembershipsList
              onPrev={() => setStep(1)}
              onNext={() => {
                setStep(step + 1);
              }}
              info={info}
              setInfo={(v) => handleSelect(v)}
              setStep={setStep}
              changeMembership={true}
              setMembershipArrDashboard={setMembershipArr}
              setMembershipType={setMembershipType}
              isPIF={isPIF}
            />
          </>
        )}
        {/* Change Membership plan */}
        {step == 3 && <ConfirmMembershipInfo />}
        {/* Add & Confirm Upgrade */}
        {step == 4 && ConfirmYourDetails()}
        {/* Confirm Detail */}
        {step == 5 && ConfirmPaymentDetail(setStep)}
        {/* Your Payment */}
        {/* {step == 6 && <YourPayment setStep={setStep} />} */}
        {/* Congrats */}
        {step == 6 && congrats()}
      </div>

      {/* MOBILE DESIGN */}
      <div className="hidden sm:block px-6 pt-1 pb-3">
        <div className="flex items-start justify-between gap-x-5">
          <p className="font-bicyclette font-bold text-white text-[25px] uppercase">
            {renderHeadingTitleMobile}
          </p>
          <div
            onClick={() => navigate('/dashboard/memberships')}
            className="cursor-pointer h-[14px] mt-[2px]"
          >
            <img
              className="block w-full object-contain"
              src="/icons/icon_back.png"
              alt="icon-back"
            />
          </div>
        </div>
      </div>
      <div className="relative hidden sm:block px-6 pb-20">
        {/* EXCEPT THE CONGRATS STEP */}
        {step <= 4 && (
          <div className="mt-2 mb-5">
            <div className="h-[6px] w-full bg-transparent">
              <div
                style={{
                  width: `${step * 25}%`,
                }}
                className={`h-full bg-primary`}
              ></div>
            </div>

            <div className="flex items-center justify-between mt-4">
              {[1, 2, 3, 4].map((mobileStep) => {
                return (
                  <div
                    key={mobileStep}
                    className={`${
                      step >= mobileStep ? 'bg-primary ' : 'bg-richBlack '
                    } relative w-9 h-9 rounded-full border border-primary flex items-center justify-center font-bicyclette font-bold leading-[18px] text-white text-xl text-center after:content-[' '] ${
                      mobileStep === 4
                        ? ' '
                        : ' after:absolute after:left-[calc(100%+1px)] after:top-1/2 after:h-[2px] after:w-[calc(25vw)] after:bg-textSecondary'
                    }  `}
                  >
                    {mobileStep}
                  </div>
                );
              })}
            </div>
          </div>
        )}

        {step === 1 && (
          <div className="">
            <h2 className="relative font-bicyclette font-bold text-primary text-xl leading-[18px] uppercase after:content-[' '] after:absolute after:left-0 after:right-0 after:-bottom-2 after:h-[1px] after:bg-primary">
              BEFORE YOU START
            </h2>
            <p className="text-white text-base text-justify leading-[21.36px] mt-6 mb-8">
              Hi, we just wanted to let you know changing your membership will
              change the cost of your fortnightly membership, please confirm you
              are happy to proceed.
            </p>

            <div className="flex items-center justify-center gap-x-5 mt-1">
              <PrimaryOutlinedButton
                onClick={() => setStep(2)}
                className="w-1/2 uppercase text-white border-white border rounded h-[34px] gotham text-base font-bold leading-[160%]"
              >
                yes
              </PrimaryOutlinedButton>
              <PrimaryOutlinedButton
                onClick={() => moveTab(0)}
                className="w-1/2 uppercase text-white border-white border rounded h-[34px] hover:!bg-[#DE0D0D] hover:!border-[#DE0D0D] hover:text-white  gotham text-base font-bold leading-[160%]"
              >
                no thanks
              </PrimaryOutlinedButton>
            </div>
          </div>
        )}

        {/* Change home club */}
        {step === 2 && (
          <div>
            <h2 className="relative font-bicyclette font-bold text-primary text-xl leading-[18px] uppercase after:content-[' '] after:absolute after:left-0 after:right-0 after:-bottom-2 after:h-[1px] after:bg-primary">
              SELECT MEMBERSHIP PLAN
            </h2>
            <MembershipsList
              onPrev={() => setStep(1)}
              onNext={() => {
                setStep(step + 1);
              }}
              info={info}
              setInfo={(v) => handleSelect(v)}
              setStep={setStep}
              changeMembership={true}
              isDashboardMobile
            />
          </div>
        )}

        {/* Change Membership plan - combine step 3 and 4 */}
        {step === 3 && (
          <div>
            <h2 className="relative font-bicyclette font-bold text-primary text-xl leading-[18px] uppercase after:content-[' '] after:absolute after:left-0 after:right-0 after:-bottom-2 after:h-[1px] after:bg-primary">
              Membership plan
            </h2>
            <ConfirmMembershipInfo />

            <h2 className="mt-9 relative font-bicyclette font-bold text-primary text-xl leading-[18px] uppercase after:content-[' '] after:absolute after:left-0 after:right-0 after:-bottom-2 after:h-[1px] after:bg-primary">
              health check
            </h2>
            {ConfirmYourDetails()}
          </div>
        )}

        {step === 4 && (
          <div>
            <h2 className=" sm:block hidden relative font-bicyclette font-bold text-primary text-xl leading-[18px] uppercase after:content-[' '] after:absolute after:left-0 after:right-0 after:-bottom-2 after:h-[1px] after:bg-primary">
              Today's payment
            </h2>
            <div className="sm:flex hidden flex-col mt-8 gap-y-3 pb-7 border-b border-white border-opacity-40">
              <div className="flex items-center justify-between gap-x-2 ">
                <p className="text-base text-white font-bold uppercase">
                  Total due today
                </p>
                <p className="text-lg text-primary font-bold">$132.80</p>
              </div>
              <div className="flex items-center justify-between gap-x-2 ">
                <p className="text-base text-white font-normal">
                  FLEXI membership
                </p>
                <p className="text-base text-white font-normal">$33.80</p>
              </div>
              <div className="flex items-center justify-between gap-x-2 ">
                <p className="text-base text-white font-normal">Activate fee</p>
                <p className="text-base text-white font-normal">$99.00</p>
              </div>
            </div>

            <h2 className="relative mt-7 font-bicyclette font-bold text-primary text-xl leading-[18px] uppercase after:content-[' '] after:absolute after:left-0 after:right-0 after:-bottom-2 after:h-[1px] after:bg-primary">
              Ongoing payment details
            </h2>
            {ConfirmPaymentDetail()}
          </div>
        )}

        {step === 6 && <div>{congrats()}</div>}
      </div>
    </>
  );
}

export default ChangeClub;
