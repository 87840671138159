import React, { useContext, useEffect, useState } from 'react';
import LayoutDashboard from '@/components/LayoutDashboard';
import { Link } from 'gatsby';

import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import { Auth, Storage } from 'aws-amplify';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import {
  cancelMemberShip,
  getListLocation,
  getMemberContractByMemberId,
  revokeMembershipCancellation,
  revokeMembershipSuspension,
  changeSuspensionDate,
  cancelSuspension,
  suspendMemberShip,
  changeSuspensionsDate,
  suspendMemberShipMedical,
  UPFRONT_SUSPENSION,
  UPFRONT_SUSPENSION_MEDICAL,
} from '@/api/mutation';
import moment from 'moment';
import { toastError, toastSuccess } from '@/common/toasts/toast';
import ModalBase from '@/common/modals/ModalBase';
import ButtonNavigate from '@/common/buttons/ArrowButton';
import InputTextArea from '@/common/inputs/textArea';
import PrimaryOutlinedButton from '@/common/buttons/PrimaryOutlinedButton';
import ChangeClub from './Components/ChangeClub';
import { v4 as uuidv4 } from 'uuid';
import useWindowSize from '@/hooks/useWindowSize';
import { breakpoints } from '@/constants';
import LoadingScreen from '@/common/loading/LoadingScreen';
import ThreeDot from '@/common/loading/ThreeDot';
import { uppercaseFirstLetter } from '@/helpers/string.helper';
import { reverse } from 'lodash';
import { AuthContext } from '@/api/auth';
import ModalMakePayment from '@/common/payment/modalMakePayment';
// import { changeSuspensionDate } from '../../../api/mutation';
import { utcToZonedTime } from 'date-fns-tz';

function Memberships() {
  const { width } = useWindowSize();
  const [tabs, setDefaultTab] = useState([
    'membership plan',
    'change',
    'suspend',
    'cancel',
  ]);
  const { userInfo } = useContext(AuthContext);
  const [activeTab, setTab] = useState<number>(0);
  const dateSuspend = false;
  const [endDate, setEndDate] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [reason, setReason] = useState([
    // { name: 'MEDICAL', select: false },
    // { name: 'Standard', select: false },
    { name: 'Medical', select: false, type: 'attach', file: [] },
    { name: 'Standard', select: false, type: 'attach', file: [] },
  ]);
  const [getMembership] = useLazyQuery(getMemberContractByMemberId);
  const [getLocation] = useLazyQuery(getListLocation);
  const [userInfoMemberShip, setUserInfoMemberShip] = useState();
  const [userInfoMemberShipV2, setUserInfoMemberShipV2] = useState();
  const [locationList, setListLocations] = useState([]);
  const client = useApolloClient();
  const [suspendedMembership, setSuspendedMembership] = useState();
  useEffect(() => {
    // const userData = JSON.parse(localStorage.getItem("currentUserDetail"));
    handleGetData();
  }, []);
  client.refetchQueries({
    include: ['getMemberContractByMemberId'], // Consider using "active" instead!
  });
  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    if (refetch) {
      membershipStatus.status = '';
    }
  }, [refetch]);
  const handleGetData = () => {
    Auth.currentAuthenticatedUser().then((user) => {
      getMembership({
        variables: {
          memberId: user.attributes['custom:memberId'],
        },
      })
        .then((membership) => {
          const suspendMembership = [];
          const memberShipDataFilter =
            membership.data.getMemberContractByMemberId.items
              .filter((i) => {
                if (i.expiryDateTime != null) {
                  if (
                    new Date(i.expiryDateTime).valueOf() < new Date().valueOf()
                  ) {
                    return false;
                  }
                }

                if (i.endDateTime != null) {
                  if (
                    new Date(i.endDateTime).valueOf() < new Date().valueOf()
                  ) {
                    return false;
                  }
                }

                return true;
              })
              .map((i) => {
                // if the susspension is currently active have to be in status approved
                const suspensionItems = i.suspensions.items.filter(
                  (i) =>
                    i.cancelledDateTime === null &&
                    i.suspensionStatus === 'APPROVED'
                );
                if (
                  suspensionItems.length > 0 &&
                  suspensionItems.every(
                    (item) =>
                      new Date().valueOf() >
                        new Date(item.suspensionStartDateTime).valueOf() &&
                      new Date().valueOf() <
                        new Date(item.suspensionEndDateTime).valueOf()
                  )
                ) {
                  return { ...i, isActive: true };
                }
                suspendMembership.push(i);
                return { ...i, isActive: true };
              })
              .filter((i) => i);

          setSuspendedMembership(suspendMembership);
          setUserInfoMemberShip(memberShipDataFilter);
          setUserInfoMemberShipV2(
            membership.data.getMemberContractByMemberId.items.filter((i) => {
              // if (i.expiryDateTime != null) {
              //   if (
              //     new Date(i.expiryDateTime).valueOf() < new Date().valueOf()
              //   ) {
              //     return false;
              //   }
              // }

              // if (i.endDateTime != null) {
              //   if (new Date(i.endDateTime).valueOf() < new Date().valueOf()) {
              //     return false;
              //   }
              // }

              return true;
            })
          );
          if (memberShipDataFilter.length == 0) {
            setTab(1);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
    getLocation()
      .then((res) => {
        setListLocations(res.data.listLocations.items);
      })
      .catch((e) => console.log(e));
  };

  useEffect(() => {
    if (tabs == 0 && userInfoMemberShip && userInfoMemberShip.length == 0) {
      setTab(1);
    }
  }, [tabs]);

  const [openModal, setModal] = useState(false);
  const [userReason, setUserReason] = useState<string>('');
  // const [itemSuspend, setSuspendItem] = useState();
  const itemSuspend = React.useRef<any>();

  const [suspendMembershipAPI] = useMutation(suspendMemberShip);
  const [suspendMembershipMedicalApi] = useMutation(suspendMemberShipMedical);

  const membershipStatus = React.useMemo(() => {
    const result: Record<string, any> = {
      status: null,
      remainingDay: null,
      requestDate: null,
      cancellationDate: null,
      suspendEndDate: null,
      suspendStartDate: null,
      color: '',
      title: '',
      suspensionStatus: '',
    };

    const membershipItem = userInfoMemberShipV2?.[0];

    const activeSuspensionItem = membershipItem?.suspensions?.items?.filter(
      (e) => e.cancelledDateTime === null
    )?.[0];

    const currentDate = moment().format('YYYY-MM-DD');
    const expiryDateTime = utcToZonedTime(
      membershipItem?.expiryDateTime,
      'Australia/Sydney'
    );
    if (
      (membershipItem?.expiryDateTime !== null &&
        moment(currentDate).isSame(membershipItem?.expiryDateTime)) ||
      moment(currentDate).isAfter(membershipItem?.expiryDateTime)
    ) {
      result.status = 'canceled';
      result.color = 'bg-red-500';
      result.title = 'no Active membership';
    } else if (
      membershipItem?.expiryDateTime !== null &&
      moment(currentDate).isBefore(membershipItem?.expiryDateTime)
    ) {
      result.status = 'pendingCancellation';
      result.color = 'bg-red-500';
      result.title = 'membership cancellation pending';
      result.cancellationDate = utcToZonedTime(
        membershipItem?.expiryDateTime,
        'Australia/Sydney'
      );
      result.remainingDay = moment(membershipItem?.expiryDateTime).diff(
        currentDate,
        'days'
      );
    } else if (
      activeSuspensionItem &&
      ((activeSuspensionItem.suspensionStatus.toLowerCase() !== 'rejected' &&
        activeSuspensionItem &&
        moment(currentDate).isBefore(
          activeSuspensionItem.suspensionStartDateTime
        ) &&
        moment(currentDate).isBefore(
          activeSuspensionItem.suspensionEndDateTime
        ) &&
        currentDate.split('T')[0] !==
          activeSuspensionItem.suspensionStartDateTime.split('T')[0]) ||
        (currentDate.split('T')[0] ===
          activeSuspensionItem.suspensionStartDateTime.split('T')[0] &&
          activeSuspensionItem.suspensionStatus.toLowerCase() === 'pending'))
    ) {
      result.status = 'pendingSuspension';
      result.color = 'bg-[#FF6900]';
      result.title = 'membership suspension pending';
      result.suspendEndDate = activeSuspensionItem.suspensionEndDateTime;
      result.suspendStartDate = activeSuspensionItem.suspensionStartDateTime;
    } else if (
      activeSuspensionItem &&
      activeSuspensionItem.suspensionStatus.toLowerCase() === 'approved' &&
      (moment(currentDate).isBetween(
        activeSuspensionItem.suspensionStartDateTime,
        activeSuspensionItem.suspensionEndDateTime,
        undefined,
        '[]'
      ) ||
        currentDate.split('T')[0] ===
          activeSuspensionItem.suspensionStartDateTime.split('T')[0] ||
        currentDate.split('T')[0] ===
          activeSuspensionItem.suspensionEndDateTime.split('T')[0])
    ) {
      result.suspendEndDate = activeSuspensionItem.suspensionEndDateTime;
      result.suspendStartDate = activeSuspensionItem.suspensionStartDateTime;
      result.status = 'suspended';
      result.color = 'bg-[#FF6900]';
      result.title = 'membership suspended';
    } else if (
      membershipItem.isPending ||
      (currentDate.split('T')[0] !==
        membershipItem.startDateTime.split('T')[0] &&
        moment(currentDate).isBefore(membershipItem.startDateTime))
    ) {
      result.title = userInfoMemberShip?.length + ' future  membership';
      result.color = 'bg-[#FF6900]';
      result.status = 'future';
    } else {
      result.status = 'active';
      result.color = 'bg-primary';
      result.title = userInfoMemberShipV2?.length + ' active membership';
    }
    result.suspensionStatus = activeSuspensionItem?.suspensionStatus;
    return result;
  }, [userInfoMemberShipV2]);
  useEffect(() => {
    if (userInfoMemberShipV2 && !userInfoMemberShipV2[0].recurring) {
      setDefaultTab(['membership plan', 'suspend']);
    }
  }, [userInfoMemberShipV2]);
  const handleSuspend = async () => {
    setLoading(true);
    try {
      const dataFilter = reason.filter((i) => i.select)[0];
      const uuid = uuidv4() + '.' + dataFilter.file[0]?.name?.split('.')?.[1];
      const name = dataFilter.name.toLowerCase() + `suspension/${uuid}`;
      const reader = new FileReader();
      let file = dataFilter.file[0];
      reader.onload = () => {
        file = reader.result;
      };
      const dataImg = await Storage.put(name, file, {
        contentType: file?.type,
      });
      // medicalsuspension/<uuid>.<fileExtn>>
      const user = await Auth.currentAuthenticatedUser();
      const dateEnd = endDate.split('/').reverse().join('-');
      const dateStart = startDate.split('/').reverse().join('-');
      const selectedReason =
        reason.filter((i) => i.select)[0].name.toLowerCase() === 'standard'
          ? 'OTHER'
          : 'MEDICAL';
      const payload = {
        variables: {
          operation: 'CREATE_SUSPENSION',
          memberContractId: itemSuspend.current,
          suspendFrom: dateStart,
          suspendTo: dateEnd,
          suspensionAmount:
            Math.ceil(
              Math.abs(
                new Date(dateEnd) - new Date(dateStart) + 24 * 60 * 60 * 1000
              ) /
                (24 * 60 * 60 * 1000) /
                7
            ) * 2.5,
          suspensionReason: userReason == '' ? 'medical' : userReason, // no need
          imageUrl: name, // no need
          suspensionType: selectedReason,
        },
      };
      if (selectedReason.toLowerCase() === 'medical') {
        await suspendMembershipMedicalApi(payload);
      } else await suspendMembershipAPI(payload);
      setIsSuspended(true);
      toastSuccess('Successfully suspended');
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
      // setModal(false);
    }
  };
  const [isSuspend, setIsSuspended] = useState();
  const [confirm, setConfirm] = useState(false);
  const setUserReasonInput = (e) => {
    setUserReason(e.target.value);
  };
  const [modalLoading, setLoading] = useState(false);
  const [revokeSuspendModal, setRevokeSuspendModal] = useState(false);
  const handleDateISO = (str: string, reverse: boolean = false) => {
    if (reverse) return str.split('/').reverse().join('-');
    return str.split('T')[0].split('-').reverse().join('/');
  };
  const filterSuspension = (i) => {
    const data = [...i.suspensions.items]
      .filter((i) => i.cancelledDateTime == null)
      .map((i) => {
        return {
          ...i,
          suspensionEndDateTime: handleDateISO(i.suspensionEndDateTime),
          suspensionStartDateTime: handleDateISO(i.suspensionStartDateTime),
        };
      })[0];

    return data
      ? data.suspensionStartDateTime + ' to ' + data.suspensionEndDateTime
      : data
      ? data.suspensionStartDateTime + ' to ' + data.suspensionEndDateTime
      : 'no';
  };
  const handleMedSusStart = () => {
    if (
      new Date().setMonth(new Date().getMonth() - 3).valueOf() <
      new Date(userInfoMemberShip[0].startDateTime).valueOf()
    ) {
      return new Date(userInfoMemberShip[0].startDateTime);
    } else {
      return new Date(new Date().setMonth(new Date().getMonth() - 3));
    }
  };
  console.log({ userInfoMemberShipV2 });
  const [paymentAmount, setpaymentAmount] = useState(0);
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentType, setPaymentType] = useState();
  const [paymentPayloadData, setPaymentPayloadData] = useState();
  const handleSuspendInputs = async () => {
    // HANDLE MOBILE
    if (width && width <= breakpoints.sm) {
      if (itemSuspend.current) await handleSuspend();
      else toastError('Please fill all the inputs then you good to go ');
    }
    // HANDLE DESKTOP
    else {
      if (userReason && itemSuspend.current) await handleSuspend();
      else toastError('Please fill all the inputs then you good to go ');
    }
  };
  const suspendMembership = () => {
    const calculateNumberOfWeeks = (startDate, endDate) => {
      const formatStartDate = new Date(handleDateISO(startDate, true));
      const formatEndDate = new Date(handleDateISO(endDate, true));
      const msInDay = 24 * 60 * 60 * 1000;
      const amount = 0;
      // Math.ceil(
      //   Math.abs(formatEndDate - formatStartDate + msInDay) / msInDay / 7
      // ) * 2.5;
      if (
        reason.filter((e) => e.name.toLowerCase() == 'medical')?.[0]?.select
      ) {
        // return `The suspension from ${startDate} to ${endDate} will cost you $0`;
        return ` Suspension from ${startDate} to ${endDate} `;
      } else {
        // return `The suspension from ${startDate} to ${endDate} will cost you $${amount}`;
        return `Suspension from ${startDate} to ${endDate}`;
      }
      // "The suspension from startDate will cost you $" +
      // Math.ceil(Math.abs(endDate - startDate) / msInDay / 7) * 2.5 +
      // " for " +
      // Math.abs(endDate - startDate) / msInDay +' days'
    };

    const handleCheck = () => {
      // HANDLE FOR MOBILE
      if (width && width <= breakpoints.sm) {
        if (startDate && endDate && reason.filter((i) => i.select).length) {
          const reasonSelected = reason.filter((e) => e.select)?.[0];

          if (
            reasonSelected.name.toLocaleLowerCase() === 'medical' &&
            reasonSelected.file?.length === 0
          ) {
            toastError('Please attach your file then you good to go');
            return;
          } else if (
            reasonSelected.name.toLocaleLowerCase() === 'standard' &&
            userReason.trim() === ''
          ) {
            toastError('Please provide reason details then you good to go');
            return;
          }
          handleSuspendInputs();
        } else toastError('Please fill all the inputs then you good to go ');
      }
      // HANDLE FOR DESKTOP
      else {
        if (startDate && endDate && reason.filter((i) => i.select).length) {
          const reasonSelected = reason.filter((e) => e.select)?.[0];
          if (
            reasonSelected.file?.length === 0 &&
            reasonSelected.name.toLocaleLowerCase() === 'medical'
          ) {
            toastError('Please attach your file then you good to go');
            return;
          }
          if (userInfoMemberShip.length < 2) {
            itemSuspend.current = userInfoMemberShip[0].id;
          }
          const formatStartDate = new Date(handleDateISO(startDate, true));
          const formatEndDate = new Date(handleDateISO(endDate, true));
          const msInDay = 24 * 60 * 60 * 1000;
          const amount = 0;
          // Math.ceil(
          //   Math.abs(formatEndDate - formatStartDate + msInDay) / msInDay / 7
          // ) * 2.5;
          setpaymentAmount(amount);
          setPaymentPayloadData({
            suspensionArguments: {
              suspendFrom: startDate.split('/').reverse().join('-'),
              suspendTo: endDate.split('/').reverse().join('-'),
              suspensionReason: userReason,
              suspensionType: 'OTHER',
              operation: 'CREATE_SUSPENSION',
              suspensionAmount:
                Math.ceil(
                  Math.abs(
                    new Date(endDate.split('/').reverse().join('-')) -
                      new Date(startDate.split('/').reverse().join('-')) +
                      24 * 60 * 60 * 1000
                  ) /
                    (24 * 60 * 60 * 1000) /
                    7
                ) * 2.5,
            },
          });
          setPaymentType('SSC');
          setModal(true);
          // setModal;
        } else toastError('Please fill all the inputs then you good to go ');
      }
    };
    // const checkStart
    const handleMaxSusEnd = () => {
      const sixMonthFromStartDate = new Date(
        new Date(startDate.split('/').reverse().join('-')).setMonth(
          new Date(startDate.split('/').reverse().join('-')).getMonth() + 6
        )
      );
      let suspensionEndDate;
      const contractEndDate = new Date(userInfoMemberShipV2[0].endDateTime);
      console.log({ contractEndDate }, userInfoMemberShipV2[0].endDateTime, {
        sixMonthFromStartDate,
        contractEndDate,
      });
      if (
        userInfoMemberShipV2[0].endDateTime &&
        contractEndDate.valueOf() < sixMonthFromStartDate.valueOf()
      ) {
        suspensionEndDate = contractEndDate;
      } else {
        suspensionEndDate = sixMonthFromStartDate;
      }
      return suspensionEndDate;
    };
    return (
      <>
        {openModal && (
          <ModalBase
            noBaseHeader
            onClickOutSide={() => {
              // setModal(false)
            }}
            loading={modalLoading}
          >
            <div className="flex items-center justify-between pt-6 md:pt-6 pr-6 pl-[33px] w-[688px] ">
              <div className="flex items-center gap-[15px] ">
                <span className="uppercase gotham tracking-[0.01em]">
                  SUSPEND MEMBERSHIP
                </span>
              </div>
              <div
                onClick={() => setModal(false)}
                className="flex items-center justify-center w-8 h-8 rounded-full border border-white cursor-pointer"
              >
                <div className="w-4 h-4 block">
                  <img
                    src="/icons/close.png"
                    alt="close"
                    className="object-fit w-full"
                  />
                </div>
              </div>
            </div>
            {isSuspend == null && (
              <div className="pr-6 pl-[33px] flex gap-[24px] my-[50px] flex-col justify-center items-center max-w-[688px] ">
                <p className="text-[40px] text-center gravity uppercase font-bicyclette font-bold">
                  are you sure you want to suspend?
                </p>

                {userInfoMemberShip.length > 1 && (
                  <div className="self-start">
                    <p>Please select a membership to suspend:</p>
                    {userInfoMemberShip.map((i, index) => (
                      <div
                        key={index}
                        onClick={() => {
                          itemSuspend.current = i.id;
                        }}
                        className={`gotham text-sm flex items-center gap-6 cursor-pointer my-2 w-max px-1 $`}
                      >
                        <div
                          className={`text-white w-[30px] rounded-[5px] h-[30px] border  flex justify-center items-center ${
                            itemSuspend.current == i.id
                              ? 'bg-white'
                              : 'border-white'
                          }`}
                        >
                          {itemSuspend.current == i.id && (
                            <svg
                              width="14"
                              height="10"
                              viewBox="0 0 14 10"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 4.69231L5.2 9L13 1"
                                stroke={'black'}
                                strokeWidth="1.02"
                                fill="white"
                              />
                            </svg>
                          )}
                        </div>
                        {index + 1 + '. '}
                        {i.description}
                      </div>
                    ))}
                  </div>
                )}

                <div className="self-start w-full">
                  <p className="gotham text-sm text-white cursor-pointer my-2">
                    Please tell us your reason:
                  </p>
                  <InputTextArea
                    // isShowRequired={checking}
                    onchange={setUserReasonInput}
                    nameInput="reason"
                    placeholder="Reason*"
                    style="lg:w-full"
                    isRequired={true}
                    type="text"
                  />
                </div>

                <div className="flex gap-6 sm:flex-col">
                  <ButtonNavigate
                    noArr={true}
                    text="Yes. I want to suspend"
                    // ;
                    onclick={
                      () => handleSuspendInputs()
                      // reason.filter((i) => i.select)[0].name.toLowerCase() ===
                      // 'standard'
                      //   ? setPaymentModal(true)
                      //   : handleSuspendInputs()
                    }
                  />
                  <ButtonNavigate
                    noArr={true}
                    text="No. I want to continue"
                    onclick={() => setModal(false)}
                  />
                </div>
              </div>
            )}
            {isSuspend == true && (
              <div className="pr-6 pl-[33px] flex gap-[24px] my-[50px] flex-col justify-center items-center mt-[58px] mb-[100px]">
                <p className="text-[40px] font-bold font-bicyclette gravity text-center uppercase">
                  your membership
                  <br /> will be suspended
                </p>
                <p className=" text-lg text-center gotham text-primary">
                  from {startDate} to {endDate}{' '}
                </p>
                <p className="max-w-[392px] text-xs text-center gotham leading-[22px] mb-[8px]">
                  Your membership will resume on{' '}
                  {new Date(
                    new Date(endDate.split('/').reverse().join('-')).setDate(
                      new Date(
                        endDate.split('/').reverse().join('-')
                      ).getDate() + 1
                    )
                  )
                    .toISOString()
                    .split('T')[0]
                    .split('-')
                    .reverse()
                    .join('/')}
                  .<br /> Enjoy your time off, we will see you then. We will
                  send
                  <br /> you a reminder email close to that date.
                </p>
                <ButtonNavigate
                  text="TAKE ME TO DASHBOARD"
                  onclick={() => {
                    itemSuspend.current = null;
                    setIsSuspended(null);
                    setModal(false);
                    // navigate('/dashboard');
                    window.location.replace('/dashboard');
                  }}
                ></ButtonNavigate>
              </div>
            )}
          </ModalBase>
        )}

        {/* MOBILE */}
        {isSuspend && width && width <= breakpoints.sm ? (
          <div className="px-6 pb-20 mt-4">
            <h2 className="font-bicyclette font-bold text-xl text-primary uppercase">
              your membership will be suspended
            </h2>
            <p className="text-sm font-bold text-white mt-2">
              from 1 August to 18 August 2023
            </p>
            <p className="text-sm leading-[160%] text-white mt-4">
              Your membership will resume on 19 August 2023. Enjoy your time
              off, we will see you then. We will send you a reminder email close
              to that date.
            </p>

            <div className="flex flex-col gap-y-5 mt-9">
              <PrimaryOutlinedButton
                onClick={() =>
                  window.location.replace('/dashboard/memberships')
                }
                className="h-[52px] w-full rounded text-base font-bold leading-[160%] uppercase"
              >
                my membership
              </PrimaryOutlinedButton>
              <PrimaryOutlinedButton
                onClick={() => window.location.replace('/dashboard')}
                className="h-[52px] w-full rounded text-base font-bold leading-[160%] uppercase"
              >
                go to dashboard
              </PrimaryOutlinedButton>
            </div>
          </div>
        ) : (
          <div className="sm:block hidden px-6 pb-20 relative">
            {modalLoading && (
              <div className="fixed top-0 left-0 h-screen w-screen">
                <ThreeDot />
              </div>
            )}
            <div className="flex gap-x-[10px] items-center text-textSecondary font-normal text-sm mb-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                className="flex-none"
              >
                <path
                  d="M4.77144 25.2283C-0.915588 19.5413 -0.942374 10.3527 4.70529 4.70505C10.353 -0.942613 19.5415 -0.915825 25.2286 4.77121C30.9156 10.4582 30.9424 19.6468 25.2947 25.2945C19.647 30.9421 10.4585 30.9153 4.77144 25.2283Z"
                  stroke="#848484"
                  stroke-width="0.6742"
                />
                <path
                  d="M14.4837 17.2073L14.2876 14.9224L14.3529 14.8519C16.0523 14.8096 16.9542 14.048 16.9542 12.8632V12.835C16.9542 11.8195 16.2222 11.0014 15.0458 11.0014C14.0784 11.0014 13.3333 11.4951 12.6667 12.3272L12 11.6361C12.7451 10.6629 13.6732 10 15.0719 10C16.8366 10 18 11.1848 18 12.7927V12.8209C18 14.5839 16.7712 15.4584 15.281 15.6841L15.1503 17.2073H14.4837ZM14.2092 20V18.519H15.4118V20H14.2092Z"
                  fill="#848484"
                />
              </svg>
              We have a maximum suspension period of 90 days and a minimum of 7
              days.
              <br />
              {/* suspension cost: $2.50 per week (rounded to next full week i.e. if
              you suspend for 8 days the suspendsion fee will be $5.00)  */}
              your next suspension cannot start before your next Direct Debit on{' '}
              {minDD ? handleDateISO(minDD?.toISOString()) : 'Today'}
            </div>

            <h2 className="text-primary text-xl font-bold font-bicyclette uppercase mb-4">
              suspension period
            </h2>

            <div className="flex flex-col gap-y-[25px] mt-2">
              <label
                onClick={() => {
                  const selectedReason = reason.filter((i) => i.select);
                  if (selectedReason.length === 0) {
                    toastError('please select the reason first');
                  }
                }}
              >
                <div
                  className={`border-b flex h-[30px] flex-col pb-[2px]  relative ${
                    startDate !== '' ? 'border-b-white' : 'border-b-white'
                  } `}
                >
                  <div className="absolute  bottom-[6px] w-full">
                    {reason.filter((i) => i.select).length > 0 && (
                      <Flatpickr
                        onChange={(dateObj, dateStr) => setStartDate(dateStr)}
                        // value={
                        //   startDate
                        //     ? startDate.split('/').reverse().join('-')
                        //     : null
                        // }
                        options={{
                          dateFormat: 'd/m/Y',
                          disableMobile: 'true',
                          enableTime: false,
                          time_24hr: false,
                          allowInput: false,
                          minDate:
                            reason.filter((i) => i.select)[0].name ===
                            'Standard'
                              ? new Date(minDD)
                              : handleMedSusStart(),
                        }}
                      />
                    )}
                  </div>
                  <label
                    className={`gotham transition-all duration-300 absolute left-0 ${
                      startDate !== ''
                        ? 'text-textSecondary top-[2px] sm:top-[-13px] text-xs'
                        : 'text-white bottom-[6px] text-base'
                    }`}
                  >
                    Start date*
                  </label>
                  <div className="absolute  z-10 right-3 bottom-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_1_1209)">
                        <path
                          d="M4.09005 5.14832H4.09376C4.70159 5.14832 5.19434 4.65957 5.19434 4.05669L5.19434 1.08795C5.19434 0.485065 4.70159 -0.00366497 4.09376 -0.00366497H4.09005C3.48222 -0.00366497 2.98947 0.485065 2.98947 1.08795L2.98947 4.05669C2.98947 4.65957 3.48222 5.14832 4.09005 5.14832Z"
                          fill="white"
                        />
                        <path
                          d="M16.0813 5.14832H16.085C16.6928 5.14832 17.1855 4.65957 17.1855 4.05669V1.08795C17.1855 0.485065 16.6928 -0.00366497 16.085 -0.00366497H16.0813C15.4734 -0.00366497 14.9807 0.485065 14.9807 1.08795V4.05669C14.9807 4.65957 15.4734 5.14832 16.0813 5.14832Z"
                          fill="white"
                        />
                        <path
                          d="M1.52086 18.9999H18.4906C18.8923 18.9989 19.2772 18.84 19.5608 18.5579C19.8445 18.2758 20.0039 17.8937 20.0039 17.4953V3.57717C20.0039 3.17875 19.8445 2.79659 19.5608 2.51452C19.2772 2.23245 18.8923 2.07349 18.4906 2.07251H1.52086C1.11917 2.07349 0.734266 2.23245 0.450575 2.51452C0.166884 2.79659 0.00756715 3.17875 0.00756836 3.57717V17.4953C0.00756715 17.8937 0.166884 18.2758 0.450575 18.5579C0.734266 18.84 1.11917 18.9989 1.52086 18.9999V18.9999ZM1.72908 6.82989H18.2823V16.979H1.72164L1.72908 6.82989Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1_1209">
                          <rect width="20" height="19" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </label>
              <label
                onClick={() => {
                  !startDate && toastError('please select start date first');
                }}
              >
                <div
                  className={`border-b flex h-[30px] flex-col pb-[2px]  relative ${
                    endDate !== '' ? 'border-b-white' : 'border-b-white'
                  } `}
                >
                  <div className="absolute  bottom-[6px] w-full">
                    {startDate && (
                      <Flatpickr
                        onChange={(dateObj, dateStr) => setEndDate(dateStr)}
                        value={
                          endDate
                            ? new Date(endDate.split('/').reverse().join('-'))
                            : null
                        }
                        options={{
                          dateFormat: 'd/m/Y',
                          disableMobile: 'true',
                          enableTime: false,
                          time_24hr: false,
                          allowInput: false,
                          maxDate: new Date(
                            new Date(
                              startDate.split('/').reverse().join('-')
                            ).setMonth(
                              new Date(
                                startDate.split('/').reverse().join('-')
                              ).getMonth() + 6
                            )
                          ),
                          minDate: startDate && new Date(startDate).fp_incr(6),
                        }}
                      />
                    )}
                  </div>
                  <label
                    className={`gotham transition-all duration-300 absolute left-0 ${
                      endDate !== ''
                        ? 'text-textSecondary top-[2px] sm:top-[-13px] text-xs'
                        : 'text-white bottom-[6px] text-base '
                    }`}
                  >
                    End date*
                  </label>
                  <div className="absolute  z-10 right-3 bottom-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_1_1209)">
                        <path
                          d="M4.09005 5.14832H4.09376C4.70159 5.14832 5.19434 4.65957 5.19434 4.05669L5.19434 1.08795C5.19434 0.485065 4.70159 -0.00366497 4.09376 -0.00366497H4.09005C3.48222 -0.00366497 2.98947 0.485065 2.98947 1.08795L2.98947 4.05669C2.98947 4.65957 3.48222 5.14832 4.09005 5.14832Z"
                          fill="white"
                        />
                        <path
                          d="M16.0813 5.14832H16.085C16.6928 5.14832 17.1855 4.65957 17.1855 4.05669V1.08795C17.1855 0.485065 16.6928 -0.00366497 16.085 -0.00366497H16.0813C15.4734 -0.00366497 14.9807 0.485065 14.9807 1.08795V4.05669C14.9807 4.65957 15.4734 5.14832 16.0813 5.14832Z"
                          fill="white"
                        />
                        <path
                          d="M1.52086 18.9999H18.4906C18.8923 18.9989 19.2772 18.84 19.5608 18.5579C19.8445 18.2758 20.0039 17.8937 20.0039 17.4953V3.57717C20.0039 3.17875 19.8445 2.79659 19.5608 2.51452C19.2772 2.23245 18.8923 2.07349 18.4906 2.07251H1.52086C1.11917 2.07349 0.734266 2.23245 0.450575 2.51452C0.166884 2.79659 0.00756715 3.17875 0.00756836 3.57717V17.4953C0.00756715 17.8937 0.166884 18.2758 0.450575 18.5579C0.734266 18.84 1.11917 18.9989 1.52086 18.9999V18.9999ZM1.72908 6.82989H18.2823V16.979H1.72164L1.72908 6.82989Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1_1209">
                          <rect width="20" height="19" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </label>
            </div>

            <h2 className="text-primary text-xl font-bold font-bicyclette uppercase mt-5 leading-[37px] pt-[1px]">
              reason for suspension
            </h2>
            {reason.map((item, index) => {
              return (
                <div
                  className={` ${
                    index + 1 !== reason?.length
                      ? ' border-b-[0.5px] border-textSecondary '
                      : ' '
                  }${index == 0 ? 'pt-[10px] pb-5 ' : 'py-5'}`}
                  key={index}
                >
                  <div className={`flex items-center gap-x-3`}>
                    <div
                      className={`text-white justify-self-end w-[30px] rounded-[5px] h-[30px] border  flex justify-center items-center ${
                        item.select ? 'bg-white' : 'border-white'
                      }`}
                      onClick={() => {
                        const newData = [...reason].map(
                          (childItem, childIndex) =>
                            childIndex == index
                              ? { ...childItem, select: !childItem.select }
                              : { ...childItem, select: false }
                        );
                        setReason(newData);
                      }}
                    >
                      {item.select && (
                        <svg
                          width="14"
                          height="10"
                          viewBox="0 0 14 10"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 4.69231L5.2 9L13 1"
                            stroke={'black'}
                            strokeWidth="1.02"
                            fill="white"
                          />
                        </svg>
                      )}
                    </div>
                    <p className="text-white font-normal">{item.name}</p>
                  </div>

                  {item.select &&
                    item.name.toLocaleLowerCase() === 'medical' && (
                      <>
                        <div className="image-upload p-[14px] bg-textSecondary bg-opacity-20 rounded border border-dashed border-textSecondary mt-5">
                          <label
                            htmlFor="medical-file-input"
                            className="flex items-center gap-x-4"
                          >
                            <div className="w-[35px]">
                              <img
                                className="object-contain w-full block"
                                src="/icons/cloud-upload.svg"
                              />
                            </div>
                            <div className="">
                              <p className="text-white mb-1 font-normal">
                                Upload medical certificate
                              </p>
                              <p className="text-textSecondary text-xs font-normal">
                                Image files or PDF only
                              </p>
                            </div>
                          </label>

                          <input
                            hidden
                            id="medical-file-input"
                            type="file"
                            onChange={(e) => {
                              const newData = [...reason].map(
                                (childItem, childIndex) =>
                                  childIndex == index
                                    ? {
                                        ...childItem,
                                        file: [e.target.files[0]],
                                      }
                                    : { ...childItem }
                              );
                              setReason(newData);
                            }}
                            accept="application/pdf,application/vnd.ms-excel,application/vnd.ms-word,pdf, image/jpg, image/jpeg, image/png"
                            multiple
                          />
                        </div>
                        {item.file?.length > 0 && (
                          <div className="flex flex-col mt-5 gap-y-3">
                            {item.file?.map((file, childIndex) => (
                              <div className="flex items-center gap-x-3">
                                <button
                                  className={`text-textSecondary font-medium border-b rounded-md relative h-[29px] flex items-center justify-center w-[29px] border-t border-l border-r border-textSecondary border-solid`}
                                  onClick={() => {
                                    const newData = [...reason].map(
                                      (item, index) =>
                                        index == childIndex
                                          ? { ...item, file: [] }
                                          : { ...item }
                                    );
                                    setReason(newData);
                                  }}
                                >
                                  X
                                </button>
                                <p className="text-textSecondary text-sm leading-[121%] font-normal">
                                  {file.name}
                                </p>
                              </div>
                            ))}
                          </div>
                        )}
                      </>
                    )}

                  {item.select &&
                    item.name.toLocaleLowerCase() === 'standard' && (
                      <div>
                        <textarea
                          placeholder="Please provide details*"
                          className="w-full bg-white rounded border border-textSecondary p-2 mt-5 text-textSecondary"
                          rows={5}
                          value={userReason}
                          onChange={setUserReasonInput}
                        ></textarea>
                      </div>
                    )}
                </div>
              );
            })}

            <PrimaryOutlinedButton
              onClick={() => {
                // handleSuspend();
                if (userInfoMemberShip.length < 2) {
                  itemSuspend.current = userInfoMemberShip[0].id;
                }
                handleCheck();
              }}
              className={`text-white text-base font-bold uppercase rounded h-[52px] w-full mt-5 ${
                !startDate || !endDate || !reason.filter((i) => i.select).length
                  ? 'pointer-events-none opacity-40'
                  : ' '
              }`}
            >
              SUSPEND
            </PrimaryOutlinedButton>
          </div>
        )}

        {/* DESKTOP */}
        <div className="block sm:hidden">
          {userInfoMemberShip[0]?.isActive && (
            <div className="mt-[30px] lg:w-full pb-[32px] mb-6 border-[1.5px] border-[rgb(255,255,255,0.4)] rounded hover:bg-[#1F2528]">
              <>
                <p className="border-b border-white font-bicyclette font-medium text-white px-6 tracking-[1.4px] py-[19.5px] uppercase h-[57px] px-[27px] border-b border-b-white text-white font-bold  flex justify-between items-center uppercase">
                  Reason for suspension
                </p>
                {reason.map((i, ind) =>
                  i.type != 'attach' ? (
                    <div
                      className="flex mx-[30px] text-white items-center px-[7px] justify-between border-b border-white py-[12px] "
                      key={ind}
                    >
                      <p className="gotham text-white tracking-[0.01em] text-sm font-medium ">
                        {i.name}
                      </p>
                      <div
                        className={`text-white w-[30px] rounded-[5px] h-[30px] border  flex justify-center items-center ${
                          i.select ? 'bg-white' : 'border-white'
                        }`}
                        onClick={() => {
                          const newData = [...reason].map((item, index) =>
                            index == ind
                              ? { ...item, select: !item.select }
                              : { ...item, select: false }
                          );
                          setReason(newData);
                        }}
                      >
                        {i.select && (
                          <svg
                            width="14"
                            height="10"
                            viewBox="0 0 14 10"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 4.69231L5.2 9L13 1"
                              stroke={'black'}
                              strokeWidth="1.02"
                              fill="white"
                            />
                          </svg>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div
                        className={`grid grid-cols-2 mx-[30px] text-white items-center px-[7px] justify-between ${
                          i.name.toLowerCase() == 'medical' &&
                          'border-b border-white pb-[39px]'
                        }   pt-[12px]  gap-y-[25px]`}
                      >
                        <p className="gotham text-white tracking-[0.01em] text-sm font-normal ">
                          {i.name}
                        </p>
                        <div
                          className={`text-white justify-self-end w-[30px] rounded-[5px] h-[30px] border  flex justify-center items-center ${
                            i.select ? 'bg-white' : 'border-white'
                          }`}
                          onClick={() => {
                            const newData = [...reason].map((item, index) =>
                              index == ind
                                ? { ...item, select: !item.select }
                                : { ...item, select: false }
                            );
                            setReason(newData);
                          }}
                        >
                          {i.select && (
                            <svg
                              width="14"
                              height="10"
                              viewBox="0 0 14 10"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1 4.69231L5.2 9L13 1"
                                stroke={'black'}
                                strokeWidth="1.02"
                                fill="white"
                              />
                            </svg>
                          )}
                        </div>
                        <div className="h-full">
                          <div>
                            <div className="flex gap-6">
                              {i.file.length == 0 &&
                                i.name.toLowerCase() == 'medical' && (
                                  <div>
                                    <div className="flex items-center">
                                      <button
                                        className={`text-white  font-bold border-b rounded-md  relative h-[30px] flex items-center justify-center w-[35px] border-t border-l border-r border-white border-solid`}
                                      >
                                        <label>
                                          <input
                                            onChange={(e) => {
                                              const newData = [...reason].map(
                                                (item, index) =>
                                                  index == ind
                                                    ? {
                                                        ...item,
                                                        file: [
                                                          e.target.files[0],
                                                        ],
                                                      }
                                                    : { ...item }
                                              );
                                              setReason(newData);
                                            }}
                                            type="file"
                                            accept="application/pdf,application/vnd.ms-excel,application/vnd.ms-word,pdf, image/jpg, image/jpeg, image/png"
                                            className="w-[56px] opacity-0 py-2 absolute z-10 left-0 top-0"
                                            multiple
                                          />
                                        </label>
                                        <div className="absolute left-1/2 -translate-x-1/2">
                                          +
                                        </div>
                                      </button>
                                      <p className="ml-[14px] graphik-regular text-white text-sm font-normal">
                                        Attach medical certificate
                                      </p>
                                    </div>
                                  </div>
                                )}
                              <div className="flex flex-col">
                                {i.file?.map((file, i) => (
                                  <div className="flex items-center">
                                    <button
                                      className={`text-white  font-bold border-b rounded-md  relative h-[30px] flex items-center justify-center w-[35px]  border-t border-l  border-r border-white border-solid`}
                                      onClick={() => {
                                        const newData = [...reason].map(
                                          (item, index) =>
                                            index == i
                                              ? { ...item, file: [] }
                                              : { ...item }
                                        );
                                        setReason(newData);
                                      }}
                                    >
                                      <div className="absolute rotate-45 left-1/2 -translate-x-1/2">
                                        +
                                      </div>
                                    </button>
                                    <p className="ml-[14px] graphik-regular text-white text-sm font-normal">
                                      {file.name}
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                            <input className="w-0 h-0" />
                          </div>
                        </div>
                        {i.name.toLowerCase() == 'medical' && (
                          <div className="justify-self-center gotham text-sm font-normal">
                            File type must be .jpg, .png, or
                            <br /> PDF. Total file size must be 5mb or
                            <br /> lower.{' '}
                          </div>
                        )}
                      </div>
                    </>
                  )
                )}{' '}
              </>
            </div>
          )}

          <div className="mt-[30px] rounded-md border-[1.5px] border-[rgb(255,255,255,0.4)] hover:bg-[#1F2528]">
            <div className="border-b border-white font-bicyclette font-medium text-white px-6 tracking-[1.4px] py-[19.5px] uppercase">
              {userInfoMemberShip[0]?.isActive
                ? 'suspend period'
                : 'cancel Suspension'}
            </div>
            {!userInfoMemberShip[0]?.isActive && (
              <div className="pt-[18px] flex justify-between pb-[14px] mx-6  gap-x-[14px] items-center text-[#898c8d]">
                Are you sure you want to cancel the suspension?
                <div className="flex flex-row lg:flex-col justify-end items-end gap-6">
                  <div
                    className="w-[180px] flex items-center justify-center cursor-pointer gotham text-sm font-bold uppercase rounded border  transition-all border-gray text-white  h-[36px] hover:bg-white hover:text-black"
                    onClick={() => {
                      setRevokeSuspendModal(true);
                    }}
                  >
                    Revoke suspension
                  </div>
                  {/* <div
                                    onClick={() => setCancelStep(1)}
                                    className="w-[180px] flex items-center justify-center cursor-pointer gotham text-sm font-bold uppercase rounded border  transition-all border-gray text-white  h-[36px] hover:bg-white hover:text-black"
                                >
                                    yes, i'm sure
                                </div> */}
                </div>
              </div>
            )}

            {userInfoMemberShip[0]?.isActive && (
              <>
                <div className="pt-[24px] pb-[30px] mx-6 flex gap-x-[14px] text-sm items-center text-[#898c8d]">
                  {/* <div className=' w-[40%] flex flex-col gap-[3px]'>
                    <div className='text-[#898c8d]'>Home club</div>
                    <div>Plus Fitness Tuggeranong</div>
                </div>
                <div className='w-[60%] flex flex-col gap-[3px]'>
                    <div className='text-[#898c8d]'>Per fortnight</div>
                    <div>$32.80</div>
                </div> */}
                  <svg
                    width="31"
                    height="31"
                    viewBox="0 0 31 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="flex-none"
                  >
                    <circle
                      cx="15.5"
                      cy="15.5"
                      r="14.5"
                      stroke="currentColor"
                      stroke-width="1.07487"
                    />
                    <path
                      d="M14.7151 9.24414H15.8018L15.6144 18.5935H14.9024L14.7151 9.24414ZM15.2584 22.4344C15.0461 22.4344 14.865 22.3595 14.7151 22.2096C14.5652 22.0597 14.4902 21.8786 14.4902 21.6662C14.4902 21.4539 14.5652 21.2728 14.7151 21.1229C14.865 20.973 15.0461 20.898 15.2584 20.898C15.4833 20.898 15.6706 20.973 15.8205 21.1229C15.9704 21.2728 16.0453 21.4539 16.0453 21.6662C16.0453 21.8786 15.9704 22.0597 15.8205 22.2096C15.6706 22.3595 15.4833 22.4344 15.2584 22.4344Z"
                      fill="currentColor"
                    />
                  </svg>
                  {reason.filter((i) => i.select).length > 0 &&
                  reason.filter((i) => i.select)[0].name.toLowerCase() ===
                    'medical' ? (
                    <>
                      ONCE DETAILS HAVE BEEN SUBMITTED A TEAM MEMBER WILL REVIEW
                      YOUR DOCUMENT AND IF APPROVED,
                      <br />
                      YOUR REQUEST WILL BE PROCESSED.
                    </>
                  ) : (
                    <>
                      We have a maximum suspension period of 90 days and a
                      minimum of 7 days.
                      <br />
                      {/* suspension cost: $2.50 per week (rounded to next full week
                      i.e. if you suspend for 8 days the suspendsion fee will be
                      $5.00)*/}
                      your next suspension cannot start before your next Direct
                      Debit on{' '}
                      {minDD ? handleDateISO(minDD?.toISOString()) : ''}
                    </>
                  )}
                </div>
                <div className="grid grid-cols-2 gap-[18px] mx-6 mb-[42px]">
                  <label
                    onClick={() => {
                      const selectedReason = reason.filter((i) => i.select);
                      if (selectedReason.length === 0) {
                        toastError('please select the reason first');
                      }
                    }}
                  >
                    <div
                      className={`border-b flex h-[47px] flex-col pb-[2px]  relative ${
                        startDate !== '' ? 'border-b-gray' : 'border-b-gray'
                      } `}
                    >
                      <div className="absolute  bottom-[6px] w-full">
                        {reason.filter((i) => i.select).length > 0 && (
                          <Flatpickr
                            onChange={(dateObj, dateStr) =>
                              setStartDate(dateStr)
                            }
                            // value={
                            //   startDate
                            //     ?
                            //         startDate
                            //     : null
                            // }
                            options={{
                              dateFormat: 'd/m/Y',
                              disableMobile: 'true',
                              enableTime: false,
                              time_24hr: false,
                              allowInput: false,
                              minDate:
                                reason.filter((i) => i.select)[0].name ===
                                'Standard'
                                  ? new Date(minDD)
                                  : handleMedSusStart(),
                            }}
                          />
                        )}
                      </div>
                      <label
                        className={`gotham absolute left-0 ${
                          startDate !== ''
                            ? 'text-textSecondary text-[11px] top-[2px] sm:top-[-8px]'
                            : 'text-white bottom-[14px] text-sm'
                        }`}
                      >
                        Start date*
                      </label>
                      <div className="absolute  z-10 right-3 bottom-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="19"
                          viewBox="0 0 20 19"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1_1209)">
                            <path
                              d="M4.09005 5.14832H4.09376C4.70159 5.14832 5.19434 4.65957 5.19434 4.05669L5.19434 1.08795C5.19434 0.485065 4.70159 -0.00366497 4.09376 -0.00366497H4.09005C3.48222 -0.00366497 2.98947 0.485065 2.98947 1.08795L2.98947 4.05669C2.98947 4.65957 3.48222 5.14832 4.09005 5.14832Z"
                              fill="white"
                            />
                            <path
                              d="M16.0813 5.14832H16.085C16.6928 5.14832 17.1855 4.65957 17.1855 4.05669V1.08795C17.1855 0.485065 16.6928 -0.00366497 16.085 -0.00366497H16.0813C15.4734 -0.00366497 14.9807 0.485065 14.9807 1.08795V4.05669C14.9807 4.65957 15.4734 5.14832 16.0813 5.14832Z"
                              fill="white"
                            />
                            <path
                              d="M1.52086 18.9999H18.4906C18.8923 18.9989 19.2772 18.84 19.5608 18.5579C19.8445 18.2758 20.0039 17.8937 20.0039 17.4953V3.57717C20.0039 3.17875 19.8445 2.79659 19.5608 2.51452C19.2772 2.23245 18.8923 2.07349 18.4906 2.07251H1.52086C1.11917 2.07349 0.734266 2.23245 0.450575 2.51452C0.166884 2.79659 0.00756715 3.17875 0.00756836 3.57717V17.4953C0.00756715 17.8937 0.166884 18.2758 0.450575 18.5579C0.734266 18.84 1.11917 18.9989 1.52086 18.9999V18.9999ZM1.72908 6.82989H18.2823V16.979H1.72164L1.72908 6.82989Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1_1209">
                              <rect width="20" height="19" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </label>
                  <label
                    onClick={() => {
                      !startDate &&
                        toastError('please select start date first');
                    }}
                  >
                    <div
                      className={`border-b flex h-[47px] flex-col pb-[2px]  relative ${
                        endDate !== '' ? 'border-b-gray' : 'border-b-gray'
                      } `}
                    >
                      <div className="absolute  bottom-[6px] w-full">
                        {startDate && (
                          <Flatpickr
                            onChange={(dateObj, dateStr) => setEndDate(dateStr)}
                            value={
                              endDate
                                ? new Date(
                                    endDate.split('/').reverse().join('-')
                                  )
                                : null
                            }
                            options={{
                              dateFormat: 'd/m/Y',
                              disableMobile: 'true',
                              enableTime: false,
                              time_24hr: false,
                              allowInput: false,
                              maxDate: handleMaxSusEnd(),
                              minDate:
                                startDate &&
                                reason.filter((i) => i.select)[0].name ===
                                  'Standard'
                                  ? new Date(
                                      startDate.split('/').reverse().join('-')
                                    ).fp_incr(6)
                                  : new Date(
                                      startDate.split('/').reverse().join('-')
                                    ),
                            }}
                          />
                        )}
                      </div>
                      <label
                        className={`gotham  absolute left-0 ${
                          endDate !== ''
                            ? ' top-[2px] sm:top-[-8px] text-[11px]  text-textSecondary'
                            : 'text-white bottom-[14px] text-[14px]'
                        }`}
                      >
                        End date*
                      </label>
                      <div className="absolute  z-10 right-3 bottom-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="19"
                          viewBox="0 0 20 19"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1_1209)">
                            <path
                              d="M4.09005 5.14832H4.09376C4.70159 5.14832 5.19434 4.65957 5.19434 4.05669L5.19434 1.08795C5.19434 0.485065 4.70159 -0.00366497 4.09376 -0.00366497H4.09005C3.48222 -0.00366497 2.98947 0.485065 2.98947 1.08795L2.98947 4.05669C2.98947 4.65957 3.48222 5.14832 4.09005 5.14832Z"
                              fill="white"
                            />
                            <path
                              d="M16.0813 5.14832H16.085C16.6928 5.14832 17.1855 4.65957 17.1855 4.05669V1.08795C17.1855 0.485065 16.6928 -0.00366497 16.085 -0.00366497H16.0813C15.4734 -0.00366497 14.9807 0.485065 14.9807 1.08795V4.05669C14.9807 4.65957 15.4734 5.14832 16.0813 5.14832Z"
                              fill="white"
                            />
                            <path
                              d="M1.52086 18.9999H18.4906C18.8923 18.9989 19.2772 18.84 19.5608 18.5579C19.8445 18.2758 20.0039 17.8937 20.0039 17.4953V3.57717C20.0039 3.17875 19.8445 2.79659 19.5608 2.51452C19.2772 2.23245 18.8923 2.07349 18.4906 2.07251H1.52086C1.11917 2.07349 0.734266 2.23245 0.450575 2.51452C0.166884 2.79659 0.00756715 3.17875 0.00756836 3.57717V17.4953C0.00756715 17.8937 0.166884 18.2758 0.450575 18.5579C0.734266 18.84 1.11917 18.9989 1.52086 18.9999V18.9999ZM1.72908 6.82989H18.2823V16.979H1.72164L1.72908 6.82989Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1_1209">
                              <rect width="20" height="19" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </label>
                </div>
                <div className=" mx-7 flex justify-between mb-6 ">
                  {/* <button
                        className="w-[180px] gotham text-[11.2px] uppercase rounded border  transition-all border-gray text-white  h-[36px] hover:bg-white hover:text-black"
                        onClick={() => {
                            // handleSuspend();
                            handleCheck();
                        }}
                    >
                        Suspend
                    </button>{" "} */}
                  <div
                    onClick={() => {
                      // handleSuspend();

                      handleCheck();
                    }}
                    className="flex cursor-pointer text-white hover:bg-primary hover:border-primary hover:text-white justify-center items-center border border-white rounded-md w-[135px] h-[37px] text-xs font-bold"
                  >
                    SUSPEND
                  </div>

                  <p className="inline-block text-sm text-white">
                    {startDate &&
                      endDate &&
                      calculateNumberOfWeeks(startDate, endDate)}
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  };

  const nearestDate = (dateArray, targetDate) => {
    // Convert targetDate to Date object if it's not already
    if (!targetDate) {
      targetDate = new Date();
    } else if (!(targetDate instanceof Date)) {
      targetDate = new Date(targetDate);
    }
    const futureDates = dateArray.filter(
      (date) => new Date(date.debitDate) > targetDate
    );

    // If there are no future dates, return null
    if (futureDates.length === 0) {
      return null;
    }

    // Calculate the absolute difference between targetDate and each future date
    const diffs = futureDates.map((date) =>
      Math.abs(new Date(date.debitDate) - targetDate)
    );

    // Find the index of the minimum absolute difference
    const minDiffIndex = diffs.indexOf(Math.min(...diffs));

    // Return the future date at that index
    return futureDates[minDiffIndex];
  };
  const [revokeSuspend] = useMutation(revokeMembershipSuspension);
  const [changeSuspenDate] = useMutation(changeSuspensionsDate);
  const [cancelSuspend] = useMutation(cancelSuspension);
  const handleRevokeSuspension = async (i, dateTime) => {
    setLoading(true);
    try {
      const suspensionData = i.suspensions.items.filter(
        (i) => i.cancelledDateTime === null
      )[0];
      const changeDate = changeSuspensionDate.isShow
        ? changeSuspensionDate.dateTime.split('/').reverse().join('-')
        : suspensionData.suspensionStartDateTime;
      const revokeReason = changeSuspensionDate.isShow
        ? 'change date'
        : 'cancel';
      if (changeSuspensionDate.isShow) {
        if (suspensionData.suspensionType.toLowerCase() !== 'medical')
          await changeSuspenDate({
            variables: {
              suspensionId: suspensionData.id,
              suspensionEndDate: changeDate,
              reason: revokeReason,
            },
          });
        else
          await suspendMembershipMedicalApi({
            variables: {
              suspensionId: suspensionData.id,
              suspensionEndDate: changeDate,
              operation: 'EDIT_SUSPENSION',
              suspensionReason: 'user edit',
            },
          });
      } else {
        if (suspensionData.suspensionType.toLowerCase() !== 'medical')
          await cancelSuspend({
            variables: {
              suspensionId: suspensionData.id,
            },
          });
        else
          await suspendMembershipMedicalApi({
            variables: {
              suspensionId: suspensionData.id,
              operation: 'CANCEL_SUSPENSION',
            },
          });
      }

      setConfirm(!confirm);
      setChangeSuspensionDate({ ...changeSuspensionDate, isShow: false });
      toastSuccess(
        changeSuspensionDate.isShow
          ? 'Successfully changed '
          : 'Successfully cancel suspension '
      );
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };
  const [minDD, setMinDD] = useState();
  const [changeSuspensionDate, setChangeSuspensionDate] = useState({
    isShow: false,
    dateTime: '',
  });
  const [upfront] = useMutation(UPFRONT_SUSPENSION);
  const [upfrontAmount, setUpfrontAmount] = useState(0);
  const [upfrontMedical] = useMutation(UPFRONT_SUSPENSION_MEDICAL);
  // calc upfront edit
  useEffect(() => {
    if (changeSuspensionDate.isShow && changeSuspensionDate.dateTime !== '') {
      const dateTime = handleDateISO(changeSuspensionDate.dateTime, true);
      const suspensionCurrent =
        userInfoMemberShipV2[0]?.suspensions.items.filter(
          (i) => i.cancelledDateTime === null
        )[0];
      const isStandard = suspensionCurrent.suspensionType === 'OTHER';

      if (isStandard) {
        upfront({
          variables: {
            suspensionId: suspensionCurrent.id,
            operation: 'UPFRONT_EDIT_SUSPENSION',
            suspensionEndDate: dateTime,
          },
        }).then((res) => {
          const suspensionData =
            userInfoMemberShipV2[0].suspensions.items.filter(
              (i) => i.cancelledDateTime === null
            )[0];
          setPaymentType('SSU');
          setPaymentPayloadData({
            suspensionArguments: {
              suspensionEndDate: changeSuspensionDate.isShow
                ? changeSuspensionDate.dateTime.split('/').reverse().join('-')
                : suspensionData.suspensionStartDateTime,
              suspensionId: suspensionData.id,
              operation: 'EDIT_SUSPENSION',
              suspensionReason: 'user edit',
            },
          });
          // setpaymentAmount(res.data.suspendMembership.data.amount);
          // setUpfrontAmount(res.data.suspendMembership.data.amount);

          setpaymentAmount(0);
          setUpfrontAmount(0);
        });
      } else {
        upfrontMedical({
          variables: {
            suspensionId: suspensionCurrent.id,
            operation: 'UPFRONT_EDIT_SUSPENSION',
            suspensionEndDate: dateTime,
          },
        }).then((res) => {
          const suspensionData =
            userInfoMemberShipV2[0].suspensions.items.filter(
              (i) => i.cancelledDateTime === null
            )[0];
          setPaymentType('MCU');
          setPaymentPayloadData({
            suspensionArguments: {
              suspensionEndDate: changeSuspensionDate.isShow
                ? changeSuspensionDate.dateTime.split('/').reverse().join('-')
                : suspensionData.suspensionStartDateTime,
              suspensionId: suspensionData.id,
              operation: 'EDIT_SUSPENSION',
              suspensionReason: 'user edit',
            },
          });
          // setpaymentAmount(res.data.suspendMedicalMembership.data.amount);
          // setUpfrontAmount(res.data.suspendMedicalMembership.data.amount);

          setpaymentAmount(0);
          setUpfrontAmount(0);
        });
      }
    } else {
      setUpfrontAmount(0);
    }
  }, [changeSuspensionDate]);

  // calc upfront cancel suspension
  useEffect(() => {
    if (changeSuspensionDate.isShow === false && revokeSuspendModal === true) {
      const dateTime = handleDateISO(changeSuspensionDate.dateTime, true);
      const suspensionCurrent =
        userInfoMemberShipV2[0]?.suspensions.items.filter(
          (i) => i.cancelledDateTime === null
        )[0];
      const isStandard = suspensionCurrent.suspensionType === 'OTHER';

      if (isStandard) {
        upfront({
          variables: {
            suspensionId: suspensionCurrent.id,
            operation: 'UPFRONT_CANCEL_SUSPENSION',
            suspensionEndDate:
              suspensionCurrent.suspensionEndDateTime.split('T')[0],
          },
        }).then((res) => {
          // setpaymentAmount(res.data.suspendMembership.data.amount);
          // setUpfrontAmount(res.data.suspendMembership.data.amount);

          setpaymentAmount(0);
          setUpfrontAmount(0);
          setPaymentPayloadData({
            suspensionArguments: {
              suspensionId: suspensionCurrent.id,
              operation: 'CANCEL_SUSPENSION',
            },
          });
          setPaymentType('SSU');
        });
      } else {
        upfrontMedical({
          variables: {
            suspensionId: suspensionCurrent.id,
            operation: 'UPFRONT_CANCEL_SUSPENSION',
            suspensionEndDate:
              suspensionCurrent.suspensionEndDateTime.split('T')[0],
          },
        }).then((res) => {
          const suspensionData =
            userInfoMemberShipV2[0].suspensions.items.filter(
              (i) => i.cancelledDateTime === null
            )[0];
          setPaymentType('MCU');
          setPaymentPayloadData({
            suspensionArguments: {
              suspensionEndDate:
                suspensionCurrent.suspensionEndDateTime.split('T')[0],
              suspensionId: suspensionData.id,
              operation: 'CANCEL_SUSPENSION',
              suspensionReason: 'user edit',
            },
          });
          // setpaymentAmount(res.data.suspendMedicalMembership.data.amount);
          // setUpfrontAmount(res.data.suspendMedicalMembership.data.amount);

          setpaymentAmount(0);
          setUpfrontAmount(0);
        });
      }
    } else {
      setUpfrontAmount(0);
    }
    // setChangeSuspensionDate({
    //   ...changeSuspensionDate,
    //   isShow: false,
    // });
    // setRevokeSuspendModal(true);
  }, [revokeSuspendModal]);
  const membershipPlan = () => {
    return (
      <React.Fragment>
        {/* // DESKTOP UI */}
        <div className="block sm:hidden">
          <div className="border-b font-bicyclette border-white font-medium text-white px-6 tracking-[1.4px] py-[19.5px] uppercase">
            membership plan
          </div>
          <div className="pt-[18px] pb-[14px] mx-6 text-white border-b border-gray flex">
            <div className=" w-[40%] flex flex-col gap-[3px]">
              <div className="text-[#898c8d] text-[11px] font-normal leading-[17px]">
                Home club
              </div>
              <div>
                {userInfoMemberShip?.map((item, index) => (
                  <div
                    className="text-white gotham text-sm leading-[20px]"
                    key={index}
                  >
                    {uppercaseFirstLetter(
                      locationList?.filter((i) => i.id === item?.locationId)[0]
                        ?.name ?? 'Plus Fitness Tuggeranong'
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="w-[60%] flex flex-col gap-[3px]">
              <div className="text-[#898c8d] text-[11px] font-normal leading-[17px]">
                Membership amount
              </div>
              <div>
                {userInfoMemberShip?.map((item, index) => (
                  <p
                    key={index}
                    className="text-white gotham text-sm leading-[20px]"
                  >
                    ${item?.costPrice.toFixed(2)}
                  </p>
                ))}
              </div>
            </div>
          </div>

          <div className="pt-[18px] pb-[14px] mx-6 text-white border-b border-gray flex justify-between items-center mb-[34px]">
            <div className="flex flex-col gap-[3px]">
              <div className="text-[#898c8d] text-[11px] leading-[17px]">
                Membership Level
              </div>
              <div>
                {userInfoMemberShip?.map((item, index) => (
                  <h2 className=" text-white gotham text-sm" key={index}>
                    {item.membershipName}
                  </h2>
                ))}
              </div>
            </div>
            <div className="">
              {membershipStatus.status === 'active' &&
                !userInfo?.isBlocked &&
                userInfoMemberShipV2[0].recurring && (
                  <PrimaryOutlinedButton
                    className="rounded-md w-[180px] h-[37px] !text-[11px]"
                    onClick={() => setTab(1)}
                  >
                    CHANGE MEMBERSHIP
                  </PrimaryOutlinedButton>
                )}
            </div>
          </div>
          <div className="pt-[18px] pb-[13px] mx-6 text-white border-b border-gray flex">
            <div className=" w-[40%] flex flex-col gap-[3px] justify-center">
              <div className="text-white text-sm">Next direct debit</div>
            </div>
            <div className="w-[60%] flex flex-col gap-[3px]">
              <div className="text-white text-sm ">
                {userInfoMemberShip?.map((item: any, index: number) => {
                  const dataFilter = nearestDate(
                    item?.billings?.items?.filter((i) => !i.isProcessed)
                  );

                  if (!minDD) {
                    const currentDate = new Date();
                    const nextDate = new Date(currentDate);
                    nextDate.setDate(currentDate.getDate() + 1);
                    if (item.recurring)
                      setMinDD(new Date(dataFilter?.debitDate || nextDate));
                    else setMinDD(nextDate);
                  }
                  return item?.paymentFrequency?.type.toLowerCase() ===
                    'weeks' ? (
                    <p className=" text-white gotham text-sm" key={index}>
                      {moment(new Date(dataFilter?.debitDate)).format(
                        'DD/MM/yyyy'
                      )}
                    </p>
                  ) : (
                    <p
                      className="mt-[3px] text-white gotham text-sm"
                      key={index}
                    >
                      {' '}
                      No direct debit
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
          {/* <div className='pt-[18px] pb-[14px] mx-6 text-white border-b border-gray flex'>
                <div className=' w-[40%] flex flex-col gap-[3px]'>
                    <div className='text-white'>contract auto-renews</div>
                </div>
                <div className='w-[60%] flex flex-col gap-[3px]'>
                    <div className='text-white'>N/A</div>
                </div>
            </div> */}
          <div
            className={`py-[35px] flex gap-[18px] mx-6 ${
              membershipStatus.status !== 'pendingCancellation' &&
              membershipStatus.status !== 'pendingSuspension' &&
              membershipStatus.status !== 'future' &&
              membershipStatus.status !== 'suspended'
                ? 'justify-end'
                : 'justify-between'
            }`}
          >
            {membershipStatus.status === 'future' && (
              <>
                <div className="">
                  <p className="uppercase text-[#FF6900] text-sm font-bold leading-[160%]">
                    you have one future membership which{' '}
                    {userInfoMemberShipV2[0].isPending ? (
                      <>
                        is
                        <br /> awaiting approval from your parents and staff
                      </>
                    ) : (
                      <>
                        will
                        <br /> start on{' '}
                        {moment(userInfoMemberShipV2[0].startDateTime).format(
                          'Do MMMM YYYY'
                        )}
                      </>
                    )}
                  </p>
                </div>
              </>
            )}
            {membershipStatus.status === 'pendingCancellation' && (
              <div className="">
                <p className="uppercase text-textError text-sm font-bold leading-[160%]">
                  request to cancel your membership is pending
                </p>
                <div className="flex items-center">
                  {/* <p className="text-textError text-sm leading-[140%] mr-6">
                  <b>Request date:</b> 6th July 2023
                </p> */}
                  <p className="text-textError text-sm leading-[140%] mr-[10px]">
                    <b>Cancellation date:</b>{' '}
                    {moment(membershipStatus?.cancellationDate).format(
                      'Do MMMM YYYY'
                    )}
                  </p>
                  <span className="text-textSecondary text-xs leading-[140%]">
                    ({membershipStatus.remainingDay} days remaining)
                  </span>
                </div>
              </div>
            )}

            {membershipStatus.status == 'pendingSuspension' && (
              <div className="">
                <p className="uppercase text-[#FF6900] text-sm font-bold leading-[160%]">
                  Your Membership Suspension is{' '}
                  {membershipStatus.suspensionStatus === 'PENDING'
                    ? 'AWAITING APPROVE'
                    : membershipStatus.suspensionStatus === 'APPROVED'
                    ? 'PENDING'
                    : ''}
                </p>
                <div className="flex items-center">
                  <p className="text-[#FF6900] text-sm leading-[140%] mr-6">
                    Suspension period will start on{' '}
                    {moment(membershipStatus.suspendStartDate).format(
                      'Do MMMM YYYY'
                    )}{' '}
                    ends on{' '}
                    {moment(membershipStatus.suspendEndDate).format(
                      'Do MMMM YYYY'
                    )}
                  </p>
                </div>
              </div>
            )}

            {membershipStatus.status === 'suspended' && (
              <div className="">
                <p className="uppercase text-[#FF6900] text-sm font-bold leading-[160%]">
                  your membership has been suspended
                </p>
                <div className="flex items-center">
                  <p className="text-[#FF6900] text-sm leading-[140%] mr-6">
                    Suspension period ends on{' '}
                    {moment(membershipStatus.suspendEndDate).format(
                      'Do MMMM YYYY'
                    )}
                  </p>
                </div>
              </div>
            )}

            <div className="flex items-center gap-[18px]">
              {/* SUSPENSION --- PENDING CASE */}

              {(membershipStatus.status === 'suspended' ||
                membershipStatus.status === 'pendingSuspension') && (
                <>
                  <PrimaryOutlinedButton
                    className="rounded-md"
                    onClick={() => {
                      setChangeSuspensionDate({
                        ...changeSuspensionDate,
                        isShow: true,
                      });
                      setRevokeSuspendModal(true);
                    }}
                  >
                    CHANGE SUSPENSION DATE
                  </PrimaryOutlinedButton>
                  <PrimaryOutlinedButton
                    className="rounded-md"
                    onClick={() => {
                      setChangeSuspensionDate({
                        ...changeSuspensionDate,
                        isShow: false,
                      });
                      setRevokeSuspendModal(true);
                    }}
                  >
                    CANCEL SUSPENSION
                  </PrimaryOutlinedButton>
                </>
              )}

              {/* CANCEL --- PENDING CASE */}
              {membershipStatus.status === 'pendingCancellation' && (
                <PrimaryOutlinedButton
                  onClick={() => {
                    setRevokeModal(true);
                  }}
                  className="rounded-md"
                >
                  REQUEST CANCELLATION
                </PrimaryOutlinedButton>
              )}

              {membershipStatus.status !== 'pendingSuspension' &&
                membershipStatus.status !== 'pendingCancellation' &&
                membershipStatus.status !== 'suspended' && (
                  <>
                    {!userInfo?.isBlocked && (
                      <PrimaryOutlinedButton
                        className="rounded-md w-[185px] h-[37px] !text-[11px] !px-0"
                        onClick={() => setTab(2)}
                      >
                        SUSPEND MEMBERSHIP
                      </PrimaryOutlinedButton>
                    )}
                    {userInfoMemberShipV2 &&
                      userInfoMemberShipV2[0].recurring && (
                        <PrimaryOutlinedButton
                          className="rounded-md w-[181px] h-[37px] !text-[11px]"
                          onClick={() => setTab(3)}
                        >
                          {userInfoMemberShipV2?.[0]?.expiryDateTime
                            ? 'REQUEST CANCELATION'
                            : 'CANCEL MEMBERSHIP'}
                        </PrimaryOutlinedButton>
                      )}
                  </>
                )}
            </div>
          </div>
        </div>
        {/* // MOBILE UI */}
        <div className="hidden sm:block px-6 pb-20">
          <div className="pb-[14px] ">
            <p className="text-textSecondary text-base leading-[21.36px] mt-[2px] mb-2 font-normal">
              Home Club
            </p>

            <h2 className="text-white text-[25px] font-bold font-bicyclette leading-[22.5px] mb-2">
              {uppercaseFirstLetter(
                locationList?.filter(
                  (i) => i.id === userInfoMemberShip?.[0]?.locationId
                )[0]?.name ?? 'Plus Fitness Tuggeranong'
              )}
            </h2>

            <div className="flex flex-col mb-[14px]">
              <p className="text-white font-base leading-[22.5px] font-normal">
                {locationList?.filter(
                  (i) => i.id === userInfoMemberShip?.[0]?.locationId
                )[0]?.address.address ?? 'Plus Fitness Tuggeranong'}{' '}
              </p>
              {/*
                Shop 10 South.Poiunt

              <p className="text-white font-base leading-[21.36px]">
                Athlon Drive, Greenway ACT 2900
              </p> */}
              <a
                href={
                  'mailto: ' +
                    locationList?.filter(
                      (i) => i.id === userInfoMemberShip?.[0]?.locationId
                    )[0]?.email ?? 'Plus Fitness Tuggeranong'
                }
                className="text-white text-[15px] font-base leading-[22.5px] font-normal"
              >
                E:{' '}
                <span className="lowercase font-normal">
                  {locationList?.filter(
                    (i) => i.id === userInfoMemberShip?.[0]?.locationId
                  )[0]?.email ?? 'Plus Fitness Tuggeranong'}
                </span>
              </a>
              <a
                href={`tel:${
                  locationList?.filter(
                    (i) => i.id === userInfoMemberShip?.[0]?.locationId
                  )[0]?.phoneNumber
                }`}
                className="text-white text-[15px] font-base leading-[22.5px] font-normal"
              >
                T:{' '}
                {locationList?.filter(
                  (i) => i.id === userInfoMemberShip?.[0]?.locationId
                )[0]?.phoneNumber ?? 'Plus Fitness Tuggeranong'}
              </a>
            </div>

            <div className="border-t-[0.5px] border-textSecondary pt-3 pb-[14px]">
              <p className="text-textSecondary mb-[5px] font-normal leading-[23px]  ">
                Membership Level
              </p>
              <p
                className={` font-bold leading-[23px] ${
                  membershipStatus.status !== 'active'
                    ? 'text-textSecondary'
                    : 'text-white'
                }`}
              >
                {
                  userInfoMemberShip?.[0]?.membershipName?.split(
                    ' Membership'
                  )[0]
                }{' '}
                - $
                {parseInt(userInfoMemberShip?.[0]?.costPrice) *
                  (userInfoMemberShip?.[0]?.paymentFrequency.type.toLowerCase() ==
                  'weeks'
                    ? parseInt(userInfoMemberShip?.[0]?.paymentFrequency.amount)
                    : 0)}
                {/* {userInfoMemberShip?.[0]?.costPrice}/
                {userInfoMemberShip?.[0]?.paymentFrequency.type.charAt(
                  userInfoMemberShip?.[0]?.paymentFrequency.type.length - 1
                ) == 's'
                  ? userInfoMemberShip?.[0]?.paymentFrequency.type.slice(0, -1)
                  : userInfoMemberShip?.[0]?.paymentFrequency.type.slice(
                      0,
                      -1
                    )}{' '} */}
                {userInfoMemberShip?.[0]?.paymentFrequency.type === 'WEEKS' &&
                  ' per fortnight'}
              </p>

              {/* SUSPENSION --- PENDING CASE */}
              {membershipStatus.status == 'pendingSuspension' && (
                <div className="mt-2">
                  <p className="text-[#FF6900] text-base font-bold leading-[20px] uppercase">
                    Your Membership Suspension is Pending
                  </p>
                  <div className="flex items-center mt-1">
                    <p className="text-[#FF6900] text-base leading-[133.5%]">
                      Suspension period start on{' '}
                      {moment(membershipStatus.suspendStartDate).format(
                        'Do MMMM YYYY'
                      )}
                    </p>
                  </div>
                </div>
              )}
              {/* CANCEL --- PENDING CASE */}
              {membershipStatus.status === 'pendingCancellation' && (
                <div className="mt-2">
                  <p className="uppercase text-textError text-base font-bold leading-[160%]">
                    request to cancel your membership is pending
                  </p>
                  <div className="flex items-center mt-1 sm:flex-col sm:items-start">
                    <p className="text-textError text-base leading-[133.5%]">
                      <b>Cancellation date:</b>{' '}
                      {moment(membershipStatus?.cancellationDate).format(
                        'Do MMMM YYYY'
                      )}
                    </p>
                    <span className="text-textSecondary text-xs leading-[133.5%] mt-1">
                      ({membershipStatus.remainingDay} days remaining)
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className="border-t-[0.5px] border-textSecondary pb-[14px] pt-3">
              <p className="text-textSecondary mb-[5px] font-normal leading-[23px]">
                Next Direct Debit
              </p>
              <p className="text-white font-bold leading-[23px]">
                {userInfoMemberShip?.map((item: any, index: number) => {
                  const dataFilter = nearestDate(
                    item?.billings?.items.filter((i) => !i.isProcessed)
                  );
                  const currentDate = new Date();
                  const nextDate = new Date(currentDate);
                  nextDate.setDate(currentDate.getDate() + 1);
                  if (!minDD)
                    setMinDD(new Date(dataFilter?.debitDate || nextDate));
                  return item?.paymentFrequency?.type.toLowerCase() === 'weeks'
                    ? moment(new Date(dataFilter?.debitDate)).format(
                        'DD/MM/yyyy'
                      )
                    : 'No direct debit';
                })}
              </p>
            </div>

            {/* <div className="border-t-[0.5px] border-textSecondary py-3">
              <p className="text-textSecondary mb-3">Contract Auto-Renewal</p>
              <p className="text-white font-bold">N/A</p>
            </div> */}

            <div className="buttonGroup flex flex-col gap-y-5 mt-4">
              {membershipStatus.status !== 'pendingSuspension' &&
                membershipStatus.status !== 'pendingCancellation' && (
                  <>
                    <PrimaryOutlinedButton
                      onClick={() => setTab(1)}
                      className="h-[52px] w-full rounded-md font-bold font-base leading-[25.6px] uppercase"
                    >
                      CHANGE MEMBERSHIP
                    </PrimaryOutlinedButton>
                    <PrimaryOutlinedButton
                      onClick={() => setTab(2)}
                      className="h-[52px] w-full rounded-md font-bold font-base leading-[25.6px] uppercase"
                    >
                      SUSPEND MEMBERSHIP
                    </PrimaryOutlinedButton>
                    <PrimaryOutlinedButton
                      onClick={() => setTab(3)}
                      className="h-[52px] w-full rounded-md font-bold font-base leading-[25.6px] uppercase"
                    >
                      CANCEL MEMBERSHIP
                    </PrimaryOutlinedButton>
                  </>
                )}

              {/* CANCEL --- PENDING CASE */}
              {membershipStatus.status === 'pendingCancellation' && (
                <PrimaryOutlinedButton
                  onClick={() => {
                    setRevokeModal(true);
                  }}
                  className="rounded-md sm:text-base sm:font-bold sm:uppercase sm:leading-[160%] sm:h-[52px]"
                >
                  request CANCELLATION
                </PrimaryOutlinedButton>
              )}

              {/* SUSPENSION --- PENDING CASE */}
              {membershipStatus.status === 'pendingSuspension' && (
                <>
                  <PrimaryOutlinedButton
                    onClick={() => {
                      setChangeSuspensionDate({
                        ...changeSuspensionDate,
                        isShow: false,
                      });
                      setRevokeSuspendModal(true);
                    }}
                    className="h-[52px] w-full rounded-md font-bold font-base leading-[25.6px] uppercase"
                  >
                    cancel suspension
                  </PrimaryOutlinedButton>
                  <PrimaryOutlinedButton
                    onClick={() => {
                      setChangeSuspensionDate({
                        ...changeSuspensionDate,
                        isShow: true,
                      });
                      setRevokeSuspendModal(true);
                    }}
                    className="h-[52px] w-full rounded-md font-bold font-base leading-[25.6px] uppercase"
                  >
                    change suspension
                  </PrimaryOutlinedButton>
                </>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };
  const [reasonCancel, setReasonCancel] = useState([
    { name: 'It is too expensive for my budget', select: false },
    { name: 'I’m not happy with the facilities', select: false },
    {
      name: 'I have lost motivation and confidence to train',
      select: false,
    },
    { name: 'Just a change of mind', select: false },
    {
      name: 'The class timetable does not suit my schedule',
      select: false,
    },
    { name: 'Standard', select: false, type: 'attatch', file: [] },
  ]);
  const [cancelStep, setCancelStep] = useState(0);
  const [accept, setAccept] = useState(false);
  const [itemCancel, setCancelItem] = useState();
  const [endDateCancel, setDateEnd] = useState(
    new Date().fp_incr(29).toISOString()?.split('T')[0]
  );
  const [cancelMemberShipAPI] = useMutation(cancelMemberShip);

  const handleCancel = async (item) => {
    setLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      const data = await cancelMemberShipAPI({
        variables: {
          memberId: user.attributes['custom:memberId'],
          memberContractId: item,
          endDate: endDateCancel.split('/').reverse().join('-'),
          updatedBy: '', //no need
        },
      });
      setConfirm(!confirm);
      toastSuccess('Successfully cancelled');
      setConfirm(true);
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };
  const [revokeMembership] = useMutation(revokeMembershipCancellation);
  const handleRevoke = async (item) => {
    setLoading(true);
    try {
      const user = await Auth.currentAuthenticatedUser();
      const data = await revokeMembership({
        variables: {
          memberId: user.attributes['custom:memberId'],
          memberContractId: item,
        },
      });
      setConfirm(!confirm);
      toastSuccess('Successfully revoked');
    } catch (e) {
      toastError(e.message);
    } finally {
      setLoading(false);
    }
  };
  const [revokeModal, setRevokeModal] = useState(false);

  const renderHeadingTitleMobile = React.useMemo(() => {
    switch (activeTab) {
      case 0:
        return 'MEMBERSHIP PLAN';
      case 1:
        return 'CHANGE MEMBERSHIP';
      case 2:
        return 'SUSPEND MEMBERSHIP';
      case 3:
        return 'CANCEL MEMBERSHIP';
      default:
        return 'NOT FOUND';
    }
  }, [activeTab]);
  const handleCancelUi = () => (
    <>
      {cancelStep == 0 && (
        <div className="sm:mt-[8px] mt-[30px] lg:w-full pb-[32px]  border-[1.5px] border-[rgb(255,255,255,0.4)] sm:border-none  rounded sm:bg-transparent bg-[#13181b] hover:bg-[#1F2528]">
          <div className="sm:h-auto sm:px-6 h-[57px] sm:border-none border-b border-b-white text-white tracking-widest gotham text-sm flex justify-between items-center uppercase">
            {/* <p className="sm:hidden sm:border-none font-bicyclette border-white   tracking-[1.4px] py-[19.5px]  h-[57px] px-[27px]  text-white font-bold  flex justify-between items-center uppercase">
              {userInfoMemberShip[0]?.expiryDateTime
                ? 'Revoke cancelation'
                : 'reason for cancellation'}
            </p> */}
            <p className="sm:block text-primary hidden font-bold text-[20px] leading-[90%] font-bicyclette">
              BEFORE YOU GO{' '}
            </p>
          </div>
          <>
            <div className="grid grid-cols-2 sm:grid-cols-1 sm:gap-[28px] gap-[150px] px-6 pt-6 sm:pt-3">
              <p className="text-white gotham text-sm leading-[21.36px]">
                Are you sure you want to{' '}
                {userInfoMemberShip[0]?.expiryDateTime
                  ? 'cancel the cancelation'
                  : 'cancel the membership'}
                ? We offer membership suspend for up to 90-days free-of-charge.
              </p>
              {userInfoMemberShip[0]?.expiryDateTime ? (
                <div className="flex flex-row lg:flex-col justify-end items-end gap-6">
                  <div
                    className="w-[180px] flex items-center justify-center cursor-pointer gotham text-sm font-bold uppercase rounded border  transition-all border-gray text-white  h-[36px] hover:bg-white hover:text-black text-base"
                    onClick={() => {
                      setRevokeModal(true);
                    }}
                  >
                    request cancelation
                  </div>
                  {/* <div
                      onClick={() => setCancelStep(1)}
                      className="w-[180px] flex items-center justify-center cursor-pointer gotham text-sm font-bold uppercase rounded border  transition-all border-gray text-white  h-[36px] hover:bg-white hover:text-black"
                  >
                      yes, i'm sure
                  </div> */}
                </div>
              ) : (
                <div className="flex flex-row lg:flex-col justify-end sm:items-end items-center sm:gap-5 gap-6">
                  {!userInfo?.isBlocked && (
                    <div
                      className="sm:w-full text-[11px] w-[155px] font-medium sm:font-bold flex items-center justify-center cursor-pointer gotham uppercase rounded border  transition-all border-gray text-white sm:h-[52px] h-[36px] hover:bg-primary hover:border-primary hover:text-white sm:text-base"
                      onClick={() => setTab(2)}
                    >
                      Suspend instead
                    </div>
                  )}
                  <div
                    onClick={() => setCancelStep(1)}
                    className="sm:w-full w-[135px] font-medium sm:font-bold flex items-center justify-center cursor-pointer gotham text-[11px] uppercase rounded border  transition-all border-gray text-white sm:h-[52px] h-[36px] hover:bg-primary hover:border-primary hover:text-white sm:text-base"
                  >
                    yes, i'm sure
                  </div>
                </div>
              )}
            </div>
          </>
        </div>
      )}
      {cancelStep == 1 && (
        <div className="sm:mt-[8px] mt-[30px] lg:w-full pb-[32px] sm:pb-11 border-[1.5px] border-[rgb(255,255,255,0.4)] sm:border-none rounded sm:bg-transparent bg-[#13181b]">
          <div className="sm:h-auto sm:px-6 sm:text-primary h-[57px] px-[27px] sm:border-none border-b border-b-white text-white tracking-widest gotham text-sm flex justify-between items-center uppercase sm:mb-2">
            <p className="sm:font-bold sm:block flex justify-between w-full items-center sm:text-[20px] sm:leading-[90%] font-bicyclette">
              reason for cancellation
              <br />
              <span className="sm:pt-[10px] text-primary sm:text-textSecondary text-sm normal-case leading-6 inline-block sm:font-normal">
                Tick all that apply*
              </span>
            </p>
          </div>
          {reasonCancel.map((i, ind) => (
            <div
              className={`${
                ind == reasonCancel.length - 1 ? 'flex-wrap' : ''
              } flex sm:mx-6 sm:gap-[10px] sm:flex-row-reverse mx-[30px] text-white items-center sm:px-0 px-[7px] sm:justify-end justify-between ${
                ind + 1 !== reasonCancel?.length
                  ? ' border-b border-white sm:border-textSecondary '
                  : ' '
              } ${
                i.name === 'Standard' &&
                reasonCancel.filter((i) => i.name === 'Standard')[0].select &&
                width &&
                width <= breakpoints.sm
                  ? '!border-b-transparent'
                  : ''
              } ${ind == 0 && 'pt-[14px]'} border-white py-[12px] `}
              key={ind}
            >
              <p className="gotham  text-white tracking-[0.01em] text-sm font-normal sm:font-normal ">
                {i.name}
              </p>
              <div
                className={`text-white w-[30px] rounded-[5px] h-[30px] border  flex justify-center items-center ${
                  i.select ? 'bg-white' : ' border-white'
                }`}
                onClick={() => {
                  const newData = [...reasonCancel].map((item, index) =>
                    index == ind ? { ...item, select: !item.select } : item
                  );
                  setReasonCancel(newData);
                }}
              >
                {i.select && (
                  <svg
                    width="14"
                    height="10"
                    viewBox="0 0 14 10"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 4.69231L5.2 9L13 1"
                      stroke={'black'}
                      strokeWidth="1.02"
                      fill="white"
                    />
                  </svg>
                )}
              </div>
              {i.name === 'Standard' ? (
                width > breakpoints.sm ? (
                  <>
                    <div className="w-full gotham text-[11px] text-gray">
                      Please provide details below*
                    </div>
                    <textarea className="w-full h-[58px] sm:h-auto bg-transparent text-white border-b border-gray outline-none text-sm text-gray" />
                  </>
                ) : (
                  reasonCancel.filter((i) => i.name === 'Standard')[0]
                    .select && (
                    <div className="w-full  bg-white h-[130px] rounded-[5px] py-[8px] px-[9px]">
                      {/* <div className="w-full gotham text-[11px] text-gray">
                        Please provide details below*
                      </div> */}
                      <textarea
                        placeholder="Please provide details below*"
                        className="text-black placeholder:text-[11px] w-full placeholder:text-textSecondary h-full border-b-0 bg-transparent outline-none text-sm"
                      />
                    </div>
                  )
                )
              ) : (
                ''
              )}
            </div>
          ))}
        </div>
      )}
      {(reasonCancel.some((i) => i.select) ||
        (width && width <= breakpoints.sm)) && (
        <div className="sm:mt-0 mt-[30px] lg:w-full pb-[22px] sm:pb-[32px] sm:border-none border-[1.4px] border-[rgb(255,255,255,0.4)] rounded sm:bg-transparent bg-[#13181b] mb-6">
          <div className="sm:hidden sm:h-auto sm:border-transparent h-[57px] px-[27px] border-b border-b-white text-white tracking-widest gotham text-sm flex justify-between items-center uppercase">
            <p>BEFORE YOU GO</p>
          </div>
          <div className="mx-6 sm:mt-0 mt-6 sm:gap-6 grid grid-cols-3 sm:grid-cols-1">
            {cancelStep == 1 && (
              <>
                <div
                  className={`sm:col-span-1 col-span-2 flex select-none sm:flex-wrap sm:items-center ${
                    !reasonCancel.some((i) => i.select)
                      ? ' sm:pointer-events-none sm:opacity-40'
                      : ' sm:opacity-100 sm:pointer-events-auto'
                  }`}
                >
                  <div
                    className={`text-white shrink-0 w-[30px] rounded-[5px] h-[30px] border flex justify-center items-center ${
                      accept ? 'bg-white' : 'border-white'
                    }`}
                    onClick={() => {
                      setAccept(!accept);
                    }}
                  >
                    {accept && (
                      <svg
                        width="14"
                        height="10"
                        viewBox="0 0 14 10"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 4.69231L5.2 9L13 1"
                          stroke={'black'}
                          strokeWidth="1.02"
                          fill="white"
                        />
                      </svg>
                    )}
                  </div>
                  <p className="hidden sm:block text-white text-base ml-3 sm:font-normal">
                    Terms and Conditions*
                  </p>
                  <p className="ml-[16px] gotham text-[14px] inline-block text-white sm:w-full sm:ml-0 sm:mt-3 sm:font-normal sm:leading-[120%]">
                    I accept that it will take 28 days for my direct debits to
                    end and that I may be contacted with an offer that could aid
                    with my reason for cancellation. I understand that by
                    cancelling I will forgo my current membership fees and will
                    have to pay a reactivation fee to sign up again.
                  </p>
                </div>
                <div
                  onClick={() => accept && setModal(!openModal)}
                  className={`ml-auto sm:m-0 my-auto sm:w-full w-[209px] text-[11px] font-medium sm:h-[52px] flex items-center justify-center sm:font-bold gotham sm:text-base uppercase rounded border transition-all h-[37px] ${
                    accept
                      ? 'border-gray text-white hover:bg-white hover:text-black cursor-pointer'
                      : 'border-gray text-gray cursor-not-allowed opacity-40'
                  }`}
                  disabled={!accept}
                >
                  cancel my membership
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
  const cancelMembership = () => {
    return (
      <>
        {width && width < breakpoints.sm
          ? !openModal && handleCancelUi()
          : handleCancelUi()}
        {width && width < breakpoints.sm && openModal ? (
          !confirm ? (
            <div className="mx-6 flex flex-col gap-5 my-3">
              {modalLoading && (
                <div className="fixed top-0 left-0 h-screen w-screen">
                  <ThreeDot />
                </div>
              )}
              <div className="text-[20px] uppercase font-bold text-primary font-bicyclette">
                are you sure you want to cancel your membership?
              </div>
              <label className="self-center mt-2">
                <div
                  className={`border-b sm:w-[calc(100vw-48px)] w-75 self-start h-[30px]  pb-[2px]  relative ${
                    endDateCancel !== '' ? 'border-b-gray' : 'border-b-gray'
                  } `}
                >
                  <div className="absolute  bottom-[6px] w-full">
                    <Flatpickr
                      onChange={(dateObj, dateStr) => setDateEnd(dateStr)}
                      options={{
                        dateFormat: 'd/m/Y',
                        disableMobile: 'true',
                        enableTime: false,
                        time_24hr: false,
                        allowInput: false,
                        minDate: new Date().fp_incr(27),
                      }}
                      value={
                        endDateCancel
                          ? new Date(
                              endDateCancel.split('/').reverse().join('-')
                            )
                          : new Date().fp_incr(27)
                      }
                    />
                  </div>
                  <label
                    className={`gotham text-[12px] absolute left-0 ${
                      endDateCancel !== ''
                        ? 'text-white top-[2px] sm:top-[-8px]'
                        : 'text-gray bottom-[14px]'
                    }`}
                  >
                    End date*
                  </label>
                  <div className="absolute  z-10 right-3 bottom-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_1_1209)">
                        <path
                          d="M4.09005 5.14832H4.09376C4.70159 5.14832 5.19434 4.65957 5.19434 4.05669L5.19434 1.08795C5.19434 0.485065 4.70159 -0.00366497 4.09376 -0.00366497H4.09005C3.48222 -0.00366497 2.98947 0.485065 2.98947 1.08795L2.98947 4.05669C2.98947 4.65957 3.48222 5.14832 4.09005 5.14832Z"
                          fill="white"
                        />
                        <path
                          d="M16.0813 5.14832H16.085C16.6928 5.14832 17.1855 4.65957 17.1855 4.05669V1.08795C17.1855 0.485065 16.6928 -0.00366497 16.085 -0.00366497H16.0813C15.4734 -0.00366497 14.9807 0.485065 14.9807 1.08795V4.05669C14.9807 4.65957 15.4734 5.14832 16.0813 5.14832Z"
                          fill="white"
                        />
                        <path
                          d="M1.52086 18.9999H18.4906C18.8923 18.9989 19.2772 18.84 19.5608 18.5579C19.8445 18.2758 20.0039 17.8937 20.0039 17.4953V3.57717C20.0039 3.17875 19.8445 2.79659 19.5608 2.51452C19.2772 2.23245 18.8923 2.07349 18.4906 2.07251H1.52086C1.11917 2.07349 0.734266 2.23245 0.450575 2.51452C0.166884 2.79659 0.00756715 3.17875 0.00756836 3.57717V17.4953C0.00756715 17.8937 0.166884 18.2758 0.450575 18.5579C0.734266 18.84 1.11917 18.9989 1.52086 18.9999V18.9999ZM1.72908 6.82989H18.2823V16.979H1.72164L1.72908 6.82989Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1_1209">
                          <rect width="20" height="19" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </label>
              <div className="flex gap-5 justify-center">
                <PrimaryOutlinedButton
                  className="uppercase w-[153px] h-[37px] gotham text-[11px] border border-white rounded-md sm:font-bold"
                  onClick={async () => {
                    try {
                      if (userInfoMemberShip.length < 2) {
                        setCancelItem(userInfoMemberShip[0].id);
                        await handleCancel(userInfoMemberShip[0].id);
                      } else await handleCancel(userInfoMemberShip[0].id);
                    } catch (e) {}
                  }}
                >
                  yes
                </PrimaryOutlinedButton>
                <PrimaryOutlinedButton
                  className="uppercase w-[153px] h-[37px] gotham text-[11px] border border-white rounded-md sm:font-bold"
                  onClick={() => setModal(false)}
                >
                  no
                </PrimaryOutlinedButton>
              </div>
              <p className="text-white text-base sm:text-sm sm:mt-2 sm:leading-[160%]">
                Qui blanditiis praesentium voluptatum deleniti atque corrupti
                quos dolores et quas molestias excepturi.
              </p>
            </div>
          ) : (
            confirm && (
              <div className="mx-6 flex flex-col gap-4 my-4">
                <div className="text-[20px] uppercase font-bold text-primary font-bicyclette">
                  this isn’t goodbye,
                  <br />
                  it’s see you soon
                </div>
                <p className="text-white text-base sm:text-sm sm:font-normal sm:mb-4">
                  We have sent a membership summary to your email address.
                  Remember we always welcome our customers back with open arms.
                  See you soon legend.
                </p>
                <div className="flex flex-col gap-5 justify-center">
                  <PrimaryOutlinedButton
                    className="uppercase w-full h-[52px] gotham text-[11px] border border-white rounded-md sm:font-bold sm:text-base"
                    onClick={() => {
                      window.location.replace('/dashboard/memberships');
                    }}
                  >
                    my membership
                  </PrimaryOutlinedButton>
                  <PrimaryOutlinedButton
                    className="uppercase w-full h-[52px] gotham text-[11px] border border-white rounded-md sm:font-bold sm:text-base"
                    onClick={() => {
                      window.location.replace('/dashboard');
                    }}
                  >
                    go to dashboard
                  </PrimaryOutlinedButton>
                </div>
              </div>
            )
          )
        ) : (
          ''
        )}
        {openModal && width && width > breakpoints.sm && (
          <ModalBase noBaseHeader loading={modalLoading}>
            <div className=" flex items-center justify-between pt-6 md:pt-6 pr-6 pl-[33px] sm:px-6">
              <div className="flex items-center gap-[15px] ">
                <span className="uppercase gotham tracking-[0.01em]">
                  CANCEL MEMBERSHIP
                </span>
              </div>
              {!confirm && (
                <div
                  onClick={() => setModal(false)}
                  className="flex items-center justify-center w-8 h-8 rounded-full border border-white cursor-pointer"
                >
                  <div className="w-4 h-4 block">
                    <img
                      src="/icons/close.png"
                      alt="close"
                      className="object-fit w-full"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="pr-6 pl-[33px] flex gap-[36px] w-[688px] sm:w-unset my-[100px] flex-col justify-center items-center sm:px-6">
              <p className="text-[40px] font-bold font-bicyclette gravity uppercase text-center">
                {!confirm && (
                  <>
                    are you sure you
                    <br /> want to cancel?
                  </>
                )}
                {confirm && 'This isn’t goodbye, it’s see you soon'}
              </p>
              {!confirm && (
                <>
                  {userInfoMemberShip.length > 1 && (
                    <div className="self-start">
                      <p>Please select a membership to cancel:</p>
                      {userInfoMemberShip.map((i, index) => (
                        <div
                          key={index}
                          onClick={() => {
                            setCancelItem(i.id);
                          }}
                          className={`gotham text-sm flex items-center gap-6 cursor-pointer my-2 w-max px-1 $`}
                        >
                          <div
                            className={`text-white w-[30px] rounded-[5px] h-[30px] border  flex justify-center items-center ${
                              itemCancel == i.id ? 'bg-white' : 'border-white'
                            }`}
                          >
                            {itemCancel == i.id && (
                              <svg
                                width="14"
                                height="10"
                                viewBox="0 0 14 10"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 4.69231L5.2 9L13 1"
                                  stroke={'black'}
                                  strokeWidth="1.02"
                                  fill="white"
                                />
                              </svg>
                            )}
                          </div>
                          {index + 1 + '. '}
                          {i.description}
                        </div>
                      ))}
                    </div>
                  )}
                  <label className="self-center">
                    <div
                      className={`border-b w-72 self-start h-[59px]  pb-[2px]  relative ${
                        endDateCancel !== '' ? 'border-b-gray' : 'border-b-gray'
                      } `}
                    >
                      <div className="absolute  bottom-[6px] w-full">
                        <Flatpickr
                          onChange={(dateObj, dateStr) => setDateEnd(dateStr)}
                          options={{
                            dateFormat: 'd/m/Y',
                            disableMobile: 'true',
                            enableTime: false,
                            time_24hr: false,
                            allowInput: false,
                            minDate: new Date().fp_incr(28),
                          }}
                          value={
                            endDateCancel
                              ? new Date(
                                  endDateCancel.split('/').reverse().join('-')
                                )
                              : new Date().fp_incr(28)
                          }
                        />
                      </div>
                      <label
                        className={`gotham text-[12px] absolute left-0 ${
                          endDateCancel !== ''
                            ? 'text-white top-[2px] sm:top-[-8px]'
                            : 'text-gray bottom-[14px]'
                        }`}
                      >
                        End date*
                      </label>
                      <div className="absolute  z-10 right-3 bottom-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="19"
                          viewBox="0 0 20 19"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_1_1209)">
                            <path
                              d="M4.09005 5.14832H4.09376C4.70159 5.14832 5.19434 4.65957 5.19434 4.05669L5.19434 1.08795C5.19434 0.485065 4.70159 -0.00366497 4.09376 -0.00366497H4.09005C3.48222 -0.00366497 2.98947 0.485065 2.98947 1.08795L2.98947 4.05669C2.98947 4.65957 3.48222 5.14832 4.09005 5.14832Z"
                              fill="white"
                            />
                            <path
                              d="M16.0813 5.14832H16.085C16.6928 5.14832 17.1855 4.65957 17.1855 4.05669V1.08795C17.1855 0.485065 16.6928 -0.00366497 16.085 -0.00366497H16.0813C15.4734 -0.00366497 14.9807 0.485065 14.9807 1.08795V4.05669C14.9807 4.65957 15.4734 5.14832 16.0813 5.14832Z"
                              fill="white"
                            />
                            <path
                              d="M1.52086 18.9999H18.4906C18.8923 18.9989 19.2772 18.84 19.5608 18.5579C19.8445 18.2758 20.0039 17.8937 20.0039 17.4953V3.57717C20.0039 3.17875 19.8445 2.79659 19.5608 2.51452C19.2772 2.23245 18.8923 2.07349 18.4906 2.07251H1.52086C1.11917 2.07349 0.734266 2.23245 0.450575 2.51452C0.166884 2.79659 0.00756715 3.17875 0.00756836 3.57717V17.4953C0.00756715 17.8937 0.166884 18.2758 0.450575 18.5579C0.734266 18.84 1.11917 18.9989 1.52086 18.9999V18.9999ZM1.72908 6.82989H18.2823V16.979H1.72164L1.72908 6.82989Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_1_1209">
                              <rect width="20" height="19" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </label>
                </>
              )}
              {!confirm && (
                <div className="flex gap-5 justify-center">
                  <PrimaryOutlinedButton
                    className="uppercase w-[111px] h-[37px] gotham text-[11px] border border-white rounded-md "
                    onClick={async () => {
                      try {
                        if (userInfoMemberShip.length < 2) {
                          setCancelItem(userInfoMemberShip[0].id);
                          await handleCancel(userInfoMemberShip[0].id);
                        } else await handleCancel(userInfoMemberShip[0].id);
                      } catch (e) {}
                    }}
                  >
                    yes
                  </PrimaryOutlinedButton>
                  <PrimaryOutlinedButton
                    className="uppercase w-[111px] h-[37px] gotham text-[11px] border border-white rounded-md "
                    onClick={() => setModal(false)}
                  >
                    no
                  </PrimaryOutlinedButton>
                </div>
              )}
              {!confirm && (
                <p className="gotham text-sm leading-[22px] text-white text-center"></p>
              )}
              {confirm && (
                <p className="gotham text-sm leading-[22px] text-white text-center">
                  We have sent a membership summary to your email
                  <br /> address. Remember we always welcome our customers
                  <br /> back with open arms. See you soon legend.
                </p>
              )}
              {confirm && (
                <ButtonNavigate
                  text="BACK TO DASHBOARD"
                  onclick={() => {
                    setModal(false);
                    window.location.replace('/dashboard');
                  }}
                ></ButtonNavigate>
              )}
            </div>
          </ModalBase>
        )}
      </>
    );
  };
  // const [suspensionRVEndDate, setSuspensionRVEndDate] = useState('')
  return (
    <LayoutDashboard>
      {paymentModal && (
        <ModalMakePayment
          changeMembershipData={{
            locationId: userInfoMemberShipV2[0].locationId,
          }}
          setModalMakePayment={setPaymentModal}
          amount={paymentAmount.toString()}
          paymentType={paymentType}
          payloadData={paymentPayloadData}
          openModalOutside={setPaymentModal}
          setStep={() => {
            if (paymentType === 'SSU' || paymentType === 'MCU') {
              setConfirm(!confirm);
              setChangeSuspensionDate({
                ...changeSuspensionDate,
                isShow: false,
              });
              toastSuccess('Successfully change');
            }
            if (paymentType === 'SSC') {
              setIsSuspended(true);
              toastSuccess('Successfully suspended');
            }
          }}
        />
      )}
      {revokeModal && (
        <ModalBase
          noBaseHeader
          closeModal={() => setRevokeModal(false)}
          onClickOutSide={() => {
            // setRevokeModal(false)
          }}
          loading={modalLoading}
        >
          <div className=" flex items-center justify-between pt-6 md:pt-6 pr-6 pl-[33px] sm:px-6">
            <div className="flex items-center gap-[15px] ">
              {/* <span className="uppercase gotham tracking-[0.01em]">
                CANCEL MEMBERSHIP CANCELATION
              </span> */}
            </div>
            {!confirm && (
              <div
                onClick={() => setRevokeModal(false)}
                className="flex items-center justify-center w-8 h-8 rounded-full border border-white cursor-pointer"
              >
                <div className="w-4 h-4 block">
                  <img
                    src="/icons/close.png"
                    alt="close"
                    className="object-fit w-full"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="pr-6 pl-[33px] flex gap-[36px] my-[100px] flex-col justify-center items-center sm:px-6">
            <p className="text-[40px] gravity uppercase text-center font-bold font-bicyclette sm:text-3xl">
              {!confirm && 'are you sure you want to cancel this cancelation?'}
              {!confirm && (
                <span className="text-sm sm:inline-block">
                  <br />
                  <span className="text-[#7681FF]">
                    {userInfoMemberShip[0]?.membershipName}
                  </span>
                  <br /> cancelled
                  <span className="text-[#7681FF]">
                    {' '}
                    from{' '}
                    {moment(membershipStatus?.cancellationDate).format(
                      'DD/MM/YYYY'
                    )}
                  </span>
                </span>
              )}
              {confirm && 'WELCOME BACK. WE HAPPY TO SEE YOU AGAIN'}
            </p>

            {!confirm && (
              <div className="flex gap-5 justify-center">
                <PrimaryOutlinedButton
                  className="uppercase w-[111px] h-[37px] gotham text-[11px] border border-white rounded-md "
                  onClick={async () => {
                    handleRevoke(userInfoMemberShip[0].id);
                    // if (userInfoMemberShip.length < 2) {
                    //     setCancelItem(userInfoMemberShip[0].id);
                    //     await handleCancel(userInfoMemberShip[0].id);
                    // }
                    // else await handleCancel(userInfoMemberShip[0].id);
                    // setConfirm(true);
                  }}
                >
                  yes
                </PrimaryOutlinedButton>
                <PrimaryOutlinedButton
                  className="uppercase w-[111px] h-[37px] gotham text-[11px] border border-white rounded-md "
                  onClick={() => setRevokeModal(false)}
                >
                  no
                </PrimaryOutlinedButton>
              </div>
            )}
            {/* {!confirm && (
                            <p className="gotham text-sm leading-[22px] text-white text-center">
                                Qui blanditiis praesentium voluptatum deleniti atque
                                <br /> corrupti quos dolores et quas molestias excepturi.
                            </p>
                        )} */}
            {/* {confirm && (
                            <p className="gotham text-sm leading-[22px] text-white text-center">
                                We have sent a membership summary to your email
                                <br /> address. Remember we always welcome our customers
                                <br /> back with open arms. See you soon legend.
                            </p>
                        )} */}
            {confirm && (
              <ButtonNavigate
                text="BACK TO DASHBOARD"
                onclick={() => {
                  setModal(false);
                  // navigate('/dashboard');
                  window.location.replace('/dashboard');
                }}
              ></ButtonNavigate>
            )}
          </div>
        </ModalBase>
      )}
      {revokeSuspendModal && (
        <ModalBase
          noBaseHeader
          closeModal={() => {
            setRevokeSuspendModal(false);
          }}
          onClickOutSide={() => {
            // setRevokeSuspendModal(false)
          }}
          loading={modalLoading}
        >
          <div className=" flex items-center justify-between pt-6 md:pt-6 pr-6 pl-[33px] sm:px-6">
            <div className="flex items-center gap-[15px] ">
              {/* <span className="uppercase gotham tracking-[0.01em]">
                {changeSuspensionDate.isShow
                  ? 'change suspension date'
                  : 'cancel MEMBERSHIP SuspenSion'}
              </span> */}
            </div>
            {!confirm && (
              <div
                onClick={() => setRevokeSuspendModal(false)}
                className="flex items-center justify-center w-8 h-8 rounded-full border border-white cursor-pointer"
              >
                <div className="w-4 h-4 block">
                  <img
                    src="/icons/close.png"
                    alt="close"
                    className="object-fit w-full"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="pr-6 pl-[33px] flex gap-[36px] my-[100px] flex-col justify-center items-center sm:px-6">
            <p className="text-[40px] gravity uppercase text-center font-bold font-bicyclette sm:text-xl">
              {!confirm
                ? changeSuspensionDate.isShow
                  ? 'are you sure you want to change suspension date?'
                  : 'are you sure you want to cancel this suspension?'
                : ''}
              {!confirm ? (
                changeSuspensionDate.isShow ? (
                  ''
                ) : (
                  <span className="text-sm sm:text-xs  sm:inline-block ">
                    <br />
                    <span className="text-[#7681FF]">
                      {userInfoMemberShipV2[0]?.membershipName}
                    </span>
                    <br /> suspended{' '}
                    <span className="text-[#7681FF]">
                      from {filterSuspension(userInfoMemberShipV2[0])}
                    </span>
                    <br />
                    {upfrontAmount > 0 && (
                      <span>Upfront amount: ${upfrontAmount}</span>
                    )}
                  </span>
                )
              ) : (
                ''
              )}
              {confirm
                ? changeSuspensionDate.dateTime
                  ? 'thank you your Suspension date changed'
                  : 'WELCOME BACK. WE HAPPY TO SEE YOU AGAIN'
                : ''}
              <br />
              {/* {confirm && changeSuspensionDate.dateTime && <><span className="text-[#7681FF]">{suspendedMembership[0]?.membershipName}

              </span><br /><span className='text-white text-base'>End date </span><span className="text-[#7681FF]"> {changeSuspensionDate.dateTime}

                </span></>} */}
            </p>
            {!confirm && changeSuspensionDate.isShow && upfrontAmount > 0
              ? 'Upfront amount : $' + upfrontAmount
              : ''}
            {!confirm && changeSuspensionDate.isShow && (
              <label className="self-center">
                <div
                  className={`border-b w-72 self-start h-[59px] sm:h-[47px]  pb-[2px]  relative ${
                    changeSuspensionDate?.dateTime !== ''
                      ? 'border-b-white'
                      : 'border-b-white'
                  } `}
                >
                  <div className="absolute  bottom-[6px] w-full">
                    <Flatpickr
                      onChange={(dateObj, dateStr) =>
                        setChangeSuspensionDate({
                          ...changeSuspensionDate,
                          dateTime: dateStr,
                        })
                      }
                      options={{
                        dateFormat: 'd/m/Y',
                        disableMobile: 'true',
                        enableTime: false,
                        time_24hr: false,
                        allowInput: false,
                        minDate: new Date(
                          userInfoMemberShipV2?.[0].suspensions?.items?.filter(
                            (e) => e.cancelledDateTime === null
                          )?.[0].suspensionStartDateTime
                          // suspendedMembership[0].suspensions.items.filter(
                          //   (i) => i.cancelledDateTime === null
                          // )[0].suspensionStartDateTime
                        ),
                        maxDate: new Date(
                          userInfoMemberShipV2?.[0].suspensions?.items?.filter(
                            (e) => e.cancelledDateTime === null
                          )?.[0].suspensionEndDateTime
                          // suspendedMembership[0].suspensions.items.filter(
                          //   (i) => i.cancelledDateTime === null
                          // )[0].suspensionEndDateTime
                        ),
                      }}
                    />
                  </div>
                  <label
                    className={`gotham text-[12px] absolute left-0 ${
                      changeSuspensionDate.dateTime !== ''
                        ? 'text-textSecondary top-[2px] sm:top-[0px]'
                        : 'text-white bottom-[14px]'
                    }`}
                  >
                    End date*
                  </label>
                  <div className="absolute  z-10 right-3 bottom-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="19"
                      viewBox="0 0 20 19"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_1_1209)">
                        <path
                          d="M4.09005 5.14832H4.09376C4.70159 5.14832 5.19434 4.65957 5.19434 4.05669L5.19434 1.08795C5.19434 0.485065 4.70159 -0.00366497 4.09376 -0.00366497H4.09005C3.48222 -0.00366497 2.98947 0.485065 2.98947 1.08795L2.98947 4.05669C2.98947 4.65957 3.48222 5.14832 4.09005 5.14832Z"
                          fill="white"
                        />
                        <path
                          d="M16.0813 5.14832H16.085C16.6928 5.14832 17.1855 4.65957 17.1855 4.05669V1.08795C17.1855 0.485065 16.6928 -0.00366497 16.085 -0.00366497H16.0813C15.4734 -0.00366497 14.9807 0.485065 14.9807 1.08795V4.05669C14.9807 4.65957 15.4734 5.14832 16.0813 5.14832Z"
                          fill="white"
                        />
                        <path
                          d="M1.52086 18.9999H18.4906C18.8923 18.9989 19.2772 18.84 19.5608 18.5579C19.8445 18.2758 20.0039 17.8937 20.0039 17.4953V3.57717C20.0039 3.17875 19.8445 2.79659 19.5608 2.51452C19.2772 2.23245 18.8923 2.07349 18.4906 2.07251H1.52086C1.11917 2.07349 0.734266 2.23245 0.450575 2.51452C0.166884 2.79659 0.00756715 3.17875 0.00756836 3.57717V17.4953C0.00756715 17.8937 0.166884 18.2758 0.450575 18.5579C0.734266 18.84 1.11917 18.9989 1.52086 18.9999V18.9999ZM1.72908 6.82989H18.2823V16.979H1.72164L1.72908 6.82989Z"
                          fill="white"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1_1209">
                          <rect width="20" height="19" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </label>
            )}
            {!confirm && (
              <div className="flex gap-5 justify-center">
                <PrimaryOutlinedButton
                  className="uppercase w-[111px] h-[37px] gotham text-[11px] border border-white rounded-md "
                  onClick={async () => {
                    const suspensionCurrent =
                      userInfoMemberShipV2[0]?.suspensions.items.filter(
                        (i) => i.cancelledDateTime === null
                      )[0];
                    const isStandard =
                      suspensionCurrent.suspensionType === 'OTHER';
                    if (paymentAmount > 0) {
                      setPaymentModal(true);
                    } else
                      handleRevokeSuspension(
                        userInfoMemberShipV2[0],
                        changeSuspensionDate.dateTime
                      );

                    // if (userInfoMemberShip.length < 2) {
                    //     setCancelItem(userInfoMemberShip[0].id);
                    //     await handleCancel(userInfoMemberShip[0].id);
                    // }
                    // else await handleCancel(userInfoMemberShip[0].id);
                    // setConfirm(true);
                  }}
                >
                  yes
                </PrimaryOutlinedButton>
                <PrimaryOutlinedButton
                  className="uppercase w-[111px] h-[37px] gotham text-[11px] border border-white rounded-md "
                  onClick={() => {
                    setChangeSuspensionDate({
                      ...changeSuspensionDate,
                      dateTime: '',
                    });
                    setRevokeSuspendModal(false);
                  }}
                >
                  no
                </PrimaryOutlinedButton>
              </div>
            )}
            {/* {!confirm && (
                            <p className="gotham text-sm leading-[22px] text-white text-center">
                                Qui blanditiis praesentium voluptatum deleniti atque
                                <br /> corrupti quos dolores et quas molestias excepturi.
                            </p>
                        )} */}
            {/* {confirm && (
                            <p className="gotham text-sm leading-[22px] text-white text-center">
                                We have sent a membership summary to your email
                                <br /> address. Remember we always welcome our customers
                                <br /> back with open arms. See you soon legend.
                            </p>
                        )} */}
            {confirm && (
              <ButtonNavigate
                text="BACK TO DASHBOARD"
                onclick={() => {
                  setModal(false);
                  // navigate('/dashboard');
                  window.location.replace('/dashboard');
                }}
              ></ButtonNavigate>
            )}
          </div>
        </ModalBase>
      )}
      <div className="px-[61.5px] py-[50px] sm:p-0 sm:pb-20">
        {/* DESKTOP UI */}
        <Link to="/dashboard">
          <div className=" flex gap-[8px] items-center sm:hidden ">
            <div className="group w-[41px] h-[48px] p-[7px] pl-0">
              <div className="border overflow-hidden border-white rounded-full w-[34px] h-[34px] flex justify-center items-center">
                <img
                  className="duration-300 transition-all group-hover:-translate-x-1/2 h-[15px] w-[15px]"
                  src="/icons/arrow-left-short.svg"
                />
              </div>
            </div>
            <span className="gotham uppercase text-sm leading-[19px] text-white tracking-widest">
              dashboard
            </span>
          </div>
        </Link>
        <p className="sm:hidden text-white text-[40px] font-bold mt-[37px] mb-[50px] uppercase font-bicyclette">
          my membership
        </p>
        <div className="sm:hidden grid grid-cols-4 gap-x-[10px] border-b-2 border-primary">
          {tabs.map((i, index) => (
            <div
              key={index}
              className={`text-center tracking-[1.4px] rounded-t-md h-[32px] uppercase flex items-center justify-center text-sm gotham ${
                activeTab === index
                  ? 'bg-primary text-white font-bold'
                  : membershipStatus.status == 'active' &&
                    (!userInfo?.isBlocked || index === 0 || index === 3)
                  ? 'cursor-pointer bg-[#414141] hover:bg-[#a0a0a0] font-medium text-white'
                  : 'cursor-default bg-[#414141] text-[#848484]'
              }`}
              onClick={() => {
                membershipStatus.status == 'active' &&
                  (!userInfo?.isBlocked || index === 0 || index === 3) &&
                  setTab(index);
              }}
            >
              {membershipStatus.status == 'active'
                ? i
                : i === 'change' && membershipStatus.status === 'canceled'
                ? 'add membership'
                : i}
            </div>
          ))}
        </div>

        {/* MOBILE UI */}
        {activeTab !== 1 && (
          <div className="hidden sm:block px-6 pt-1 pb-3">
            <div className="flex items-center justify-between gap-x-2">
              <p className="font-bicyclette font-bold text-white text-[25px] mt-[3px] leading-[22.5px] uppercase">
                {renderHeadingTitleMobile}
              </p>
              {activeTab !== 0 && (
                <div
                  onClick={() => {
                    if (isSuspend) {
                      window.location.replace('/dashboard/memberships');
                    } else {
                      setTab(0);
                    }
                  }}
                  className="cursor-pointer h-[14px]"
                >
                  <img
                    className="block w-full object-contain"
                    src="/icons/icon_back.png"
                    alt="icon-back"
                  />
                </div>
              )}
            </div>
          </div>
        )}

        {activeTab === 0 && (
          <div className="mt-[30px] rounded-md border-[1.5px] border-[rgba(255,255,255,0.4)] hover:bg-[#1F2528] sm:mt-0 sm:rounded-none sm:border-none sm:hover:bg-transparent">
            {membershipPlan()}
          </div>
        )}
        {activeTab === 1 ? (
          userInfoMemberShipV2[0]?.recurring ? (
            <ChangeClub
              willRefetch={setRefetch}
              moveTab={setTab}
              membership={userInfoMemberShip}
            ></ChangeClub>
          ) : (
            activeTab === 1 && suspendMembership()
          )
        ) : (
          activeTab === 1 && (
            <ChangeClub
              willRefetch={setRefetch}
              moveTab={setTab}
              membership={userInfoMemberShip}
            ></ChangeClub>
          )
        )}
        {activeTab === 2 && suspendMembership()}
        {activeTab === 3 && cancelMembership()}
      </div>
    </LayoutDashboard>
  );
}

export default Memberships;
