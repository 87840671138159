import ArrowButton from '@/common/buttons/ArrowButton';
import React, { useState, useEffect } from 'react';
import tick from '../../assets/images/tick.svg';
import { useLazyQuery } from '@apollo/client';
import { listMembershipId, listMemberships } from '@/api/mutation';
import { toastError } from '@/common/toasts/toast';
import moment from 'moment';

interface SlideProps {
  onNext: () => void;
  onPrev: () => void;
  info: any;
  setInfo: (v) => void;
  changeMembership: boolean;

  isDashboardMobile?: boolean;
  isPIF: boolean;
  setMembershipType: (v) => void;
}
const Memberships: React.FC<SlideProps> = ({
  onNext,
  onPrev,
  info,
  setInfo,
  changeMembership = false,
  isDashboardMobile = false,
  isPIF,
  setMembershipType,
}) => {
  const [type, setType] = useState<string>('fort');
  const [membershipArr, setMembershipArr] = useState({});
  useEffect(() => {
    listMembershipIdApi().then((membership) => {
      membership = membership.data;
      if (membership) {
        sortData(membership.getMembershipByBrandId, age);
      }
    });
  }, []);

  const [age, setAge] = useState(18);
  useEffect(() => {
    listMembershipIdApi().then((membership) => {
      membership = membership.data;
      if (membership) {
        sortData(membership.getMembershipByBrandId, age);
      }
    });
  }, [age]);

  useEffect(() => {
    const ageInfo = moment(new Date()).diff(
      moment(info?.memberDetails?.dob),
      'years'
    );
    info?.memberDetails?.dob && info?.memberDetails?.dob != ''
      ? setAge(ageInfo)
      : setAge(18);
  }, [info]);

  // Check value upgrades exists
  // const handleCheck = (club: object) => {
  //     setUpgrades((prev) => {
  //         const isChecked = upgrades.some(
  //             (item) => item?.attributes?.title === club?.attributes?.title
  //         );
  //         return isChecked
  //             ? upgrades?.filter(
  //                 (item) => item?.attributes?.title !== club?.attributes?.title
  //             )
  //             : [...prev, club];
  //     });
  // };

  const [listMemberShipApi] = useLazyQuery(listMemberships);
  const [listMembershipIdApi] = useLazyQuery(listMembershipId);

  // const sortData = ({ items }) => {
  //     let type = { weekly: [], annual: [], student: [] };
  //     let upgradeData = [];

  //     const upgrade = ["CL ", "HR ", "GU ", "LO ", " + ", "RB "];
  //     if (items) {
  //         for (let i = 0; i < items.length; i++) {
  //             const result = upgrade.some((substr) =>
  //                 items[i].description.toLowerCase().includes(substr.toLowerCase())
  //             );
  //             if (result && items[i].isActive) {
  //                 upgradeData.push(items[i]);
  //             }
  //         }
  //         setUpgradeData(upgradeData);
  //     }
  // };
  const [price, setPrice] = useState();
  const [currentPlan, setCurrentPlan] = useState();
  const sortData = ({ items }, age) => {
    // console.log(info?.memberDetails?.homeLocationId, ': abcdefghlsdkfjklasdjf');
    let type = { weekly: [], annual: [], student: [], pif: [] };
    let upgradeData = { weekly: [], annual: [], student: [], pif: [] };

    const upgrade = ['CL ', 'HR ', 'GU ', 'LO ', ' + ', 'RB '];
    if (items) {
      for (let i = 0; i < items.length; i++) {
        const lowerClassName = items[i].membershipName.toLowerCase();
        const lowerDesc = items[i].description.toLowerCase();
        const result = upgrade.some((substr) =>
          lowerDesc.includes(substr.toLowerCase())
        );
        const costPriceFromLocation =
          items[i].membershipLocations?.items.filter(
            (i) => i.locationId === info?.memberDetails?.homeLocationId
          )[0]?.costPrice ?? -1;

        const currentItem = { ...items[i], costPrice: costPriceFromLocation };
        if (!result && currentItem.isActive) {
          if (
            (age === 16 && lowerClassName.includes('flexi')) ||
            (age > 16 &&
              (lowerClassName.includes('flexi') ||
                lowerClassName.includes('power') ||
                (!changeMembership && lowerClassName.includes('guest')) ||
                lowerDesc.includes('pif') ||
                lowerClassName.includes('paid in full') ||
                lowerClassName.includes('pif'))) ||
            (age <= 13 && lowerClassName.includes('junior')) ||
            (age > 13 && age < 16 && lowerClassName.includes('youth'))
          ) {
            if (
              !items[i].recurring ||
              lowerDesc.includes('pif') ||
              lowerClassName.includes('paid in full') ||
              lowerClassName.includes('pif')
            )
              type.pif.push(currentItem);
            else if (
              currentItem.paymentFrequency.type === 'WEEKS' &&
              !lowerDesc.includes('student')
            )
              type.weekly.push(currentItem);
            else if (
              (currentItem.paymentFrequency.type === 'YEARS' ||
                currentItem.paymentFrequency.type === 'MONTHS') &&
              !lowerDesc.includes('student')
            )
              type.annual.push(currentItem);
            else type.student.push(currentItem);
          }
        }
      }
      setPrice(type);
      setCurrentPlan('weekly');
      if (isPIF) {
        setType('pif');
      } else {
        setType('weekly');
      }

      setMembershipArr(type);
    }
  };
  function createMarkup(data) {
    return { __html: data };
  }

  if (isDashboardMobile) {
    return (
      <div className="mt-6">
        <p className="text-white leading-[21.36px] mb-3">
          Do you have a promo code?
        </p>
        <div className="flex items-center w-full border-b border-white border-opacity-40 bg-white rounded h-[42px] px-2 py-3">
          <div className="flex items-center w-full">
            <p className="text-base font-bold leading-[160%] text-primary uppercase">
              PF-
            </p>
            <input className="w-full pl-1 text-base font-bold leading-[160%] text-primary uppercase" />
          </div>
        </div>

        <div className="flex items-center w-full mt-6">
          <button
            onClick={() => setType('weekly')}
            className={`border border-solid leading-[160%] text-base font-bold uppercase h-[52px] w-[50%] rounded-l ${
              type == 'weekly'
                ? 'bg-primary text-white border-primary border-r-0'
                : ' bg-transparent text-white border-white border-r-0'
            } `}
          >
            fortnightly
          </button>
          <button
            onClick={() => setType('pif')}
            className={`border border-solid leading-[160%] text-base font-bold uppercase h-[52px] w-[50%] rounded-r ${
              type == 'pif'
                ? 'bg-primary text-white border-primary border-l-0 '
                : ' bg-transparent  text-white border-white border-l-0 '
            } `}
          >
            paid in full
          </button>
        </div>

        {/* Memberships */}
        <div className="grid mt-[30px] grid-cols-1 gap-y-5">
          {price?.[type]
            ?.filter(
              (i) => i.brandId === '6dec4e5f-7a07-4a7e-a809-2c0c1df01366'
            )
            ?.map((item: object, index: number) => {
              return (
                <div
                  key={index}
                  className="relative bg-black border border-textSecondary rounded-md overflow-hidden"
                >
                  <div className="flex flex-col items-center justify-center pt-7 pb-5 px-4">
                    <h2 className="uppercase heading2 text-primary text-center leading-[90%]">
                      {item.membershipName}{' '}
                      {age > 13 && age < 16 && (
                        <span className="heading2 text-[rgba(37,51,213,1)]">
                          YOUTH
                        </span>
                      )}
                    </h2>

                    <div
                      className={`mt-3 ${
                        type == 'pif' ? 'text-center' : 'text-start'
                      }`}
                    >
                      <span
                        className={`bebas text-[56px] text-white leading-[90%] ${
                          type == 'pif' && 'justify-self-center'
                        }`}
                      >
                        {' '}
                        $
                        {type !== 'pif'
                          ? (item.costPrice / 2).toFixed(2) + ''
                          : item.costPrice.toFixed(2)}
                      </span>
                      {type != 'pif' && (
                        <span className="lowercase text-white bebas text-[18px] font-normal leading-[160%]">
                          /{' '}
                          {item.paymentFrequency.type.charAt(
                            item.paymentFrequency.type.length - 1
                          ) == 's'
                            ? item.paymentFrequency.type.slice(0, -1)
                            : item.paymentFrequency.type.slice(0, -1)}
                        </span>
                      )}
                      {type != 'weekly' && (
                        <p className="text-center text-white text-base leading-[160%] font-bold mt-1">
                          SINGLE PAYMENT
                        </p>
                      )}
                      {type == 'weekly' && (
                        <p
                          className={`mt-[6px] leading-[110%] text-center text-base text-white text-opacity-60`}
                        >
                          {item.costPrice.toFixed(2) +
                            ' fortnightly Direct Debit'}
                        </p>
                      )}
                      ß
                    </div>
                  </div>
                  {!changeMembership ||
                  (changeMembership &&
                    item.id != info.memberDetails.membershipId) ? (
                    <div className="w-full mt-auto">
                      <div
                        onClick={() => {
                          changeMembership
                            ? setInfo &&
                              setInfo({
                                memberDetails: {
                                  ...info.memberDetails,
                                  membershipId: item.id,
                                  NewMembershipId: item.id,
                                  membershipLocationId:
                                    item?.membershipLocations?.items?.[0]?.id,
                                },
                                membershipDetails: { ...item },
                              })
                            : setInfo &&
                              setInfo({
                                ...info,
                                membershipId: item.id,
                                membershipLocationId:
                                  item?.membershipLocations?.items?.[0]?.id,
                              });
                          if (
                            price?.['pif']
                              ?.filter(
                                (i) =>
                                  i.brandId ===
                                  '6dec4e5f-7a07-4a7e-a809-2c0c1df01366'
                              )
                              .includes(item)
                          ) {
                            setMembershipType('pif');
                          } else {
                            setMembershipType('weekly');
                          }
                        }}
                      >
                        <div
                          className={`flex items-center justify-center w-full h-10 bg-white text-primary text-base text-center font-medium uppercase leading-[34.426px] ${
                            info?.membershipId === item?.id ||
                            (changeMembership &&
                              item.id == info.memberDetails.NewMembershipId)
                              ? ' !bg-primary !text-white'
                              : ' '
                          }`}
                        >
                          {info?.membershipId === item?.id ||
                          (changeMembership &&
                            item.id == info.memberDetails.NewMembershipId)
                            ? 'selected'
                            : 'choose plan'}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="w-full mt-auto">
                      <div>
                        <div className="flex items-center justify-center w-full h-10 bg-richBlack text-white text-base text-center font-medium uppercase leading-[34.426px]">
                          your current plan
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
        </div>

        {/* Buttons to actions */}
        <div className="flex items-center mt-[38px] justify-between">
          <div onClick={() => onPrev()}>
            <div className="flex">
              <ArrowButton text="" isRight={false} />
            </div>
          </div>
          <div
            onClick={() => {
              if (
                info?.membershipId ||
                (changeMembership && info.memberDetails.NewMembershipId)
              )
                onNext();
              else toastError('Please select a membership');
            }}
          >
            <div className="flex">
              <ArrowButton text="next" isRight={true} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      id="scroll-hidden"
      className="w-full h-full overflow-y-scroll pb-[70px] sm:pb-[150px]"
    >
      <div
        className={`flex md:items-start items-center md:flex-col flex-row   justify-between ${
          changeMembership ? 'pt-[30px]' : 'pt-[42px]'
        }`}
      >
        <div className="flex flex-col gap-2  md:pb-3 pb-0 border-b border-b-transparent md:border-b-primary w-full">
          <h6 className="gotham text-white text-left uppercase text-sm">
            Membership options for{' '}
          </h6>
          <h2
            className={`uppercase md:table-auto text-start heading2 ${
              changeMembership ? 'text-[#7681FF]' : 'text-primary'
            }`}
          >
            plus fitness tuggeranong
          </h2>
        </div>
        <p className="text-white leading-[21.36px] mb-3 sm:block hidden sm:mt-[28px]">
          Do you have a promo code?
        </p>
        <div className=" sm:flex hidden sm:mb-[27px] items-center w-full border-b border-white border-opacity-40 bg-white rounded h-[42px] px-2 py-3">
          <div className="flex items-center w-full">
            <p className="text-base font-bold leading-[160%] text-primary uppercase">
              PF-
            </p>
            <input className="w-full pl-1 text-base font-bold leading-[160%] text-primary uppercase" />
          </div>
        </div>

        <div className="flex border md:w-full w-[300px] sm:min-w-none min-w-[300px] md:mt-4 mt-0 sm:border-transparent border-white rounded items-center">
          <button
            onClick={() => setType('weekly')}
            className={`${
              type == 'weekly'
                ? 'bg-primary text-white'
                : 'md:bg-white bg-transparent md:text-primary text-white '
            } gotham text-[11px] font-medium uppercase  sm:font-medium sm:text-base rounded-l-md h-[40px] w-[50%]`}
          >
            fortnightly
          </button>
          <button
            onClick={() => setType('pif')}
            className={`${
              type == 'pif'
                ? 'bg-primary text-white'
                : 'md:bg-white bg-transparent md:text-primary text-white '
            }  gotham text-[11px] font-medium uppercase sm:font-medium sm:text-base rounded-r-md  h-[40px] w-[50%]`}
          >
            paid in full
          </button>
        </div>
      </div>
      {/* Memberships */}
      <div
        className={`grid mt-5 ${
          price?.[type]?.filter(
            (i) => i.brandId === '6dec4e5f-7a07-4a7e-a809-2c0c1df01366'
          ).length > 3
            ? 'lg:grid-cols-1 grid-cols-3'
            : 'grid-flow-col sm:grid-flow-row'
        }`}
      >
        {price?.[type]?.filter(
          (i) => i.brandId === '6dec4e5f-7a07-4a7e-a809-2c0c1df01366'
        ).length ? (
          ''
        ) : (
          <div className="text-white text-2xl uppercase my-3 ">sorry we don't have any membership now</div>
        )}
        {price?.[type]
          ?.filter((i) => i.brandId === '6dec4e5f-7a07-4a7e-a809-2c0c1df01366')
          ?.map((item: object, index: number) => {
            const currentData = price?.[type]?.filter(
              (i) => i.brandId === '6dec4e5f-7a07-4a7e-a809-2c0c1df01366'
            );
            return (
              <>
                <div
                  key={index}
                  className={`relative sm:pb-0 py-[33px] border sm:border-b-transparent border-gray  sm:rounded-bl-none sm:rounded-t sm:px-0  px-[50px] grid  justify-center w-full 
                  ${index == 2 ? 'rounded-tr' : ''}
                  ${index == 0 ? 'rounded-tl' : ''}
                  ${index + 1 == currentData.length ? 'rounded-br' : ''}
                  ${
                    currentData.length > 3
                      ? currentData.length % 3 == 1
                        ? index == currentData.length - 1 && 'rounded-bl'
                        : index == currentData.length - 2 && 'rounded-bl'
                      : ''
                  }
                  ${
                    currentData.length > 3
                      ? currentData.length % 3 == 1
                        ? index == currentData.length - 2 && 'rounded-br'
                        : index == currentData.length - 3 && 'rounded-br'
                      : ''
                  }
                  ${currentData.length == 1 && '!rounded'}
                  ${
                    changeMembership &&
                    item.id == info.memberDetails.membershipId
                      ? 'bg-[#414141]'
                      : 'bg-richBlack'
                  }`}
                >
                  <div
                    className={`max-w-[222px] sm:max-w-[264px] sm:w-[264px] flex flex-col ${
                      changeMembership && 'items-start'
                    }
                    ${
                      currentData.length > 3
                        ? 'w-[191px]'
                        : currentData.length == 2
                        ? 'w-[222px]'
                        : ''
                    }
                    `}
                  >
                    <h2
                      className={`uppercase heading2 sm:text-center text-white min-h-[45px] text-center ${
                        changeMembership && '!text-start'
                      }`}
                    >
                      {item.membershipName}{' '}
                      {age > 13 && age < 16 && (
                        <span className="heading2 text-[rgba(37,51,213,1)]">
                          YOUTH
                        </span>
                      )}
                    </h2>
                    {changeMembership &&
                      item.id == info.memberDetails.membershipId && (
                        <div className="absolute right-0 top-0 rounded-bl-md text-white uppercase h-[26px] text-center py-1 px-2 text-sm bg-primary font-semibold">
                          {item.id == info.memberDetails.membershipId &&
                            'your current plan'}
                        </div>
                      )}
                    <div
                      className={`mt-4 sm:text-center ${
                        type == 'pif' ? 'text-center' : 'text-center'
                      } ${changeMembership && '!text-start'}`}
                    >
                      <span
                        className={`bebas  sm:text-center text-[45px] text-white leading-[80%] ${
                          type == 'pif' && 'justify-self-center'
                        }`}
                      >
                        {' '}
                        $
                        {type !== 'pif'
                          ? (item.costPrice / 2).toFixed(2)
                          : item.costPrice.toFixed(2)}
                      </span>
                      {type != 'pif' && (
                        <span className="uppercase text-white  text-base font-medium ">
                          /{' '}
                          {item.paymentFrequency.type.charAt(
                            item.paymentFrequency.type.length - 1
                          ) == 's'
                            ? item.paymentFrequency.type.slice(0, -1)
                            : item.paymentFrequency.type.slice(0, -1)}
                        </span>
                      )}
                      <p
                        className={`mt-[6px] sm:text-base text-center h-[34px] mb-[7px] sm:text-center leading-[132%]  ${
                          type == 'weekly'
                            ? 'text-[rgba(255,255,255,0.6)] text-center text-xs font-normal'
                            : 'text-white text-center text-base font-medium'
                        }`}
                      >
                        {type == 'weekly' ? (
                          <>
                            ${item.costPrice.toFixed(2)} fortnightly Direct
                            Debit <br />{' '}
                            {item?.contractLength
                              ? item?.contractLength?.amount +
                                ' ' +
                                item?.contractLength?.type
                              : ''}
                          </>
                        ) : (
                          'SINGLE PAYMENT'
                        )}
                        {}
                      </p>
                    </div>
                    {/* <span className='gotham text-gray mt-2 text-[12px]'>$878.80 single payment</span> */}
                    <div className="border-t border-t-borderLine w-full">
                      <ul className="mb-[34px] sm:max-w-[239px] sm:mx-auto w-full flex flex-col pt-[14px] sm:gap-[8px] gap-[10px] ">
                        {[
                          ...item.description.split(','),

                          `Joining fee: $${item.joiningFee}`,
                          `Activation fee: $${item.joiningFee2}`,
                          `Card/fob fee: $${item.joiningFee3}`,
                        ].map((i) => {
                          return (
                            <li
                              className={`flex items-center gap-[14px] sm:gap-[10px] ${
                                changeMembership && 'gap-y-[14px]'
                              }`}
                            >
                              <span className="self-start w-[19px] h-[19px] flex-none rounded-[50%] flex items-center justify-center border border-white">
                                <img
                                  className="w-auto h-auto"
                                  src={tick}
                                  alt="icon"
                                />
                              </span>
                              <span
                                className="gotham text-start font-normal sm:leading-[16px] leading-4 text-white text-sm sm:uppercase sm:text-base sm:font-bold"
                                dangerouslySetInnerHTML={createMarkup(
                                  i.replace('\n', '<br/>')
                                )}
                              ></span>
                            </li>
                          );
                        })}
                        {/* <li className='flex items-center gap-[14px]'>
                                <span className='w-[19px] h-[19px] rounded-[50%] flex items-center justify-center border border-white'>
                                    <img className='w-auto h-auto' src={tick} alt="icon" />
                                </span>
                                <li className='gotham text-white text-sm'>24/7 access</li>
                            </li>
                            <li className='flex items-center gap-[14px]'>
                                <span className='w-[19px] h-[19px] rounded-[50%] flex items-center justify-center border border-white'>
                                    <img className='w-auto h-auto' src={tick} alt="icon" />
                                </span>
                                <li className='gotham text-white text-sm'>No Lock-In Contract</li>
                            </li> */}
                      </ul>
                    </div>
                    {(!changeMembership ||
                      (changeMembership &&
                        item.id != info.memberDetails.membershipId)) && (
                      <div className="sm:hidden w-full mt-auto">
                        <div
                          onClick={() => {
                            changeMembership
                              ? setInfo &&
                                setInfo({
                                  memberDetails: {
                                    ...info.memberDetails,
                                    membershipId: item.id,
                                    NewMembershipId: item.id,
                                    membershipLocationId:
                                      item?.membershipLocations?.items?.[0]?.id,
                                  },
                                  membershipDetails: { ...item },
                                })
                              : setInfo &&
                                setInfo({
                                  ...info,
                                  membershipId: item.id,
                                  membershipLocationId:
                                    item?.membershipLocations?.items?.[0]?.id,
                                });
                            if (
                              price?.['pif']
                                ?.filter(
                                  (i) =>
                                    i.brandId ===
                                    '6dec4e5f-7a07-4a7e-a809-2c0c1df01366'
                                )
                                .includes(item)
                            ) {
                              setMembershipType('pif');
                            } else {
                              setMembershipType('weekly');
                            }
                          }}
                        >
                          <div
                            className={`h-[37px] cursor-pointer transition-all w-full hover:bg-primary hover:!border-primary border border-white rounded-md flex gotham text-[12px] uppercase items-center justify-center text-white ${
                              info?.membershipId === item?.id ||
                              (changeMembership &&
                                item.id == info.memberDetails.NewMembershipId)
                                ? 'bg-primary text-white !border-primary'
                                : ''
                            }`}
                          >
                            {info?.membershipId === item?.id ||
                            (changeMembership &&
                              item.id == info.memberDetails.NewMembershipId)
                              ? 'selected'
                              : 'choose plan'}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {(!changeMembership ||
                  (changeMembership &&
                    item.id != info.memberDetails.membershipId)) && (
                  <div className="sm:block hidden w-full mt-auto">
                    <div
                      onClick={() => {
                        changeMembership
                          ? setInfo &&
                            setInfo({
                              memberDetails: {
                                ...info.memberDetails,
                                membershipId: item.id,
                                NewMembershipId: item.id,
                                membershipLocationId:
                                  item?.membershipLocations?.items?.[0]?.id,
                              },
                              membershipDetails: { ...item },
                            })
                          : setInfo &&
                            setInfo({
                              ...info,
                              membershipId: item.id,
                              membershipLocationId:
                                item?.membershipLocations?.items?.[0]?.id,
                            });
                        if (
                          price?.['pif']
                            ?.filter(
                              (i) =>
                                i.brandId ===
                                '6dec4e5f-7a07-4a7e-a809-2c0c1df01366'
                            )
                            .includes(item)
                        ) {
                          setMembershipType('pif');
                        } else {
                          setMembershipType('weekly');
                        }
                      }}
                    >
                      <div
                        className={`h-[40px] mb-[18px] sm:font-medium  font-bold cursor-pointer transition-all w-full hover:bg-primary border  rounded-b-md flex gotham text-[12px] uppercase items-center  justify-center ${
                          info?.membershipId === item?.id ||
                          (changeMembership &&
                            item.id == info.memberDetails.NewMembershipId)
                            ? 'bg-primary text-white border-primary'
                            : 'bg-white text-primary border-white'
                        }`}
                      >
                        {info?.membershipId === item?.id ||
                        (changeMembership &&
                          item.id == info.memberDetails.NewMembershipId)
                          ? 'selected'
                          : 'choose plan'}
                      </div>
                    </div>
                  </div>
                )}
              </>
            );
            // <div className='py-[33px] relative border border-gray border-l-0 rounded-tr rounded-br bg-richBlack px-[50px] flex flex-col justify-center w-full'>
            //     <h2 className='uppercase heading2 text-white'>flexi</h2>
            //     <div className='flex items-end gap-2 mt-4'>
            //         <h1 className='bebas text-[45px] text-white '>$13.90</h1>
            //         <span className='uppercase text-white bebas text-[16px] '>/ week</span>
            //     </div>
            //     <span className='gotham text-gray mt-2 text-[12px]'>$878.80 single payment</span>
            //     <ul className='mt-7 w-full flex flex-col pt-3  gap-[10px] border-t border-t-borderLine'>
            //         <li className='flex items-center gap-[14px]'>
            //             <span className='w-[19px] h-[19px] rounded-[50%] flex items-center justify-center border border-white'>
            //                 <img className='w-auto h-auto' src={tick} alt="icon" />
            //             </span>
            //             <li className='gotham text-white text-sm'>All clubs Australia wide</li>
            //         </li>
            //         <li className='flex items-center gap-[14px]'>
            //             <span className='w-[19px] h-[19px] rounded-[50%] flex items-center justify-center border border-white'>
            //                 <img className='w-auto h-auto' src={tick} alt="icon" />
            //             </span>
            //             <li className='gotham text-white text-sm'>24/7 access</li>
            //         </li>
            //         <li className='flex items-center gap-[14px]'>
            //             <span className='w-[19px] h-[19px] rounded-[50%] flex items-center justify-center border border-white'>
            //                 <img className='w-auto h-auto' src={tick} alt="icon" />
            //             </span>
            //             <li className='gotham text-white text-sm'>No Lock-In Contract</li>
            //         </li>
            //     </ul>
            //     <div className='mt-[34px] w-full'>
            //         <ChoosePlan />
            //     </div>
            //     <span className='absolute bg-primary flex w-[103px] h-[27px] text-[11px] font-bold tracking-widest items-center gotham uppercase top-0 right-0 justify-center text-white'>popular</span>
            // </div>
          })}
      </div>
      {/* Terms */}
      {/* <div className='mt-[34px] flex items-center gap-3'>
                <CheckboxActive />
                <span className='text-white gotham'>See junior or student rates (For persons under 17 only).</span>

            </div> */}

      {/* Buttons to actions */}
      <div className="flex items-center mt-[56px] justify-between">
        <div onClick={() => onPrev()}>
          <div className="md:hidden flex">
            <ArrowButton text="back" isRight={false} />
          </div>
          <div className="md:flex hidden">
            <ArrowButton text="" isRight={false} />
          </div>
        </div>
        <div
          onClick={() => {
            if (
              info?.membershipId ||
              (changeMembership && info.memberDetails.NewMembershipId)
            )
              onNext();
            else toastError('Please select a membership');
          }}
        >
          <div className="md:hidden flex">
            <ArrowButton text="next" isRight={true} />
          </div>
          <div className="md:flex hidden">
            <ArrowButton text="next" isRight={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Memberships;
