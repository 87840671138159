import React from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import moment from 'moment/moment';
const PayWithVivaPay =  ({callApiPayment,
                           cardRequestId,
                           paymentSuccess,
                           paymentError,
                           isPayment,
                           cost,
                           info,
                           handleNextOver18,
                           handleNextUnder18}) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);



  const iframeStyle = {
    width: "100%",
    height: "100%",
    border: "none",
    outline: "none",
    borderRadius: "10px",
    overflow: "hidden",
  };
  const iframeSrc = `${process.env.VIVAPAY_IFRAME}/${cardRequestId}`;
  function openModal() {

      setIsOpen(true);

  }

  function closeModal() {
    setIsOpen(false);
        if(moment(new Date()).diff(moment(info?.memberDetails?.dob), 'years') < 18){
          handleNextUnder18();
        }
        else{
          handleNextOver18();
        }

  }
   return (
    <div>
      <div onClick={openModal} className='h-[55px] w-[220px] bg-[#ebebeb] flex justify-between items-center rounded-lg cursor-pointer'>
        {isPayment?
          <div className='w-[100px] h-[55px]  flex items-center justify-evenly'>
            <div>
              <svg  fill='#059a00' xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path stroke='#059a00' d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"></path>
              </svg>
            </div>
            <div className='text-[#059a00]'>
              DONE
            </div>
          </div> :
          <div className='w-[92px] h-[55px] text-[#262262] text-[25px] flex items-center ml-[5px]'>
          ${cost}
          </div>
        }
      <div className='w-[100px] h-[55px]  flex items-center mt-[5px]'>
        <img  className='w-full h-full object-contain' src="https://pay-dev.vivapay.com.au/assets/viva-pay-logos/logo.svg"/>
      </div>
      </div>
      <Modal
        open={modalIsOpen}
        onClose={closeModal}
        center
        styles={{
          overlay: {
            background: "rgba(45, 45, 35, 0.4)",
          }}}
      >
        <iframe
          id="pay-with-vivapay"
          title="pay with vivapay"
          src={iframeSrc}
          style={iframeStyle}
        />
      </Modal>
    </div>
  );
};
export default PayWithVivaPay;
