import React, { useContext, useEffect, useState } from 'react';
// import timetableImg from '@/assets/images/dashboard/images/timetable.png'
// import timetableIcon from '@/assets/images/dashboard/icons/timetable.svg'

// import bookingsImg from '@/assets/images/dashboard/images/bookings.png'
// import bookingsIcon from '@/assets/images/dashboard/icons/bookings.svg'
import { navigate } from 'gatsby';
import { AuthContext } from '@/api/auth';
import { getMemberContractByMemberId } from '@/api/mutation';
import { useLazyQuery } from '@apollo/client';
import { Auth } from 'aws-amplify';
import useWindowSize from '@/hooks/useWindowSize';
import { breakpoints } from '@/constants';

function General() {
  const [getMembership] = useLazyQuery(getMemberContractByMemberId);
  const [userInfoMemberShip, setUserInfoMemberShip] = useState();
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        getMembership({
          variables: {
            memberId: user.attributes['custom:memberId'],
          },
        })
          .then((membership) => {
            const memberShipDataFilter =
              membership.data.getMemberContractByMemberId.items
                .filter((i) => {
                  if (i.expiryDateTime != null) {
                    if (
                      new Date(i.expiryDateTime).valueOf() <
                      new Date().valueOf()
                    ) {
                      return false;
                    }
                  }

                  if (i.endDateTime != null) {
                    if (
                      new Date(i.endDateTime).valueOf() < new Date().valueOf()
                    ) {
                      return false;
                    }
                  }

                  return true;
                })
                .map((i) => {
                  // if the susspension is currently active have to be in status approved
                  const suspensionItems = i.suspensions.items.filter(
                    (i) =>
                      i.cancelledDateTime === null &&
                      i.suspensionStatus === 'APPROVED'
                  );
                  if (
                    suspensionItems.length > 0 &&
                    suspensionItems.every(
                      (item) =>
                        new Date().valueOf() >
                          new Date(item.suspensionStartDateTime).valueOf() &&
                        new Date().valueOf() <
                          new Date(item.suspensionEndDateTime).valueOf()
                    )
                  ) {
                    return { ...i, isActive: true };
                  }
                  suspendMembership.push(i);
                  return { ...i, isActive: true };
                })
                .filter((i) => i);
            setUserInfoMemberShip(memberShipDataFilter);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((e) => navigate('/login', { replace: true }));
  }, []);
  const data = [
    {
      text: 'Timetable',
      img: '/images/Dashboard/timetable.png',
      icon: '/icons/Dashboard/timetable.svg',
      link: '/dashboard/class/timetable',
      display: true,
      disabled: false,
      description: 'Check out the latest class options from your local club.',
    },
    {
      text: 'My Bookings',
      img: '/images/Dashboard/bookings.png',
      icon: '/icons/Dashboard/bookings.svg',
      link: '/dashboard/class/bookings',
      display: true,
      disabled: false,
      description:
        'Keep up to date with what classes you’ve booked so you don’t miss a workout!',
    },
    {
      text: 'My Buddies',
      img: '/images/Dashboard/buddies.png',
      icon: '/icons/Dashboard/buddies.svg',
      link: '/dashboard/class/bookings',
      display: true,
      disabled: true,
      description:
        'Coming soon',
    },
    {
      text: 'My Profile',
      img: '/images/Dashboard/profile.png',
      icon: '/icons/Dashboard/profile.svg',
      link: '/dashboard/account',
      display: true,
      disabled: false,
      description: 'Manage, update, and view your profile.',
    },
    {
      text: 'Personal training',
      img: '/images/Dashboard/training.png',
      icon: '/icons/Dashboard/training.svg',
      link: '/dashboard/timetable',
      display: true,
      disabled: true,
      description:
        'Coming soon',
    },
    {
      text: 'Invite A Friend',
      img: '/images/Dashboard/friend.png',
      icon: '/icons/Dashboard/mail.svg',
      link: '/dashboard/timetable',
      display: true,
      disabled: true,
      description:
        'Coming soon',
    },
    // {
    //   text: 'Upgrade',
    //   img: '/images/dashboard/upgrade.png',
    //   icon: '/icons/dashboard/upgrade.svg',
    //   link: '/dashboard/timetable',
    //   display: true
    // },
    {
      text: 'Member benefits',
      img: '/images/Dashboard/benefit.png',
      icon: '/icons/Dashboard/benefit.svg',
      link: '/dashboard/timetable',
      display: false,
      disabled: true,
      description: '',
    },
  ];
  const userInfo = useContext(AuthContext).userInfo;
  const { width } = useWindowSize();
  return (
    <div className="bg-[#13181b] sm:bg-transparent sm:px-[14px] sm:pt-0 h-full pl-[60px] text-white pt-[67px] pr-[52px] ">
      <p className="text-white sm:hidden text-sm mb-[9px] font-medium tracking-widest ">
        Members Portal
      </p>
      <p className="uppercase !mb-[48px] !leading-[31px] sm:hidden text-white subheading font-bicyclette">
        HEY,{' '}
        {userInfo?.surname && userInfo?.givenName
          ? userInfo?.givenName + ' ' + userInfo?.surname
          : ''}
      </p>
      <div className="flex sm:flex-col sm:pb-[82px] flex-wrap sm:gap-y-[20px] gap-[15px] relative">
        {userInfoMemberShip?.length == 0 ? (
          <div className="h-full max-w-[1491px] gap-[5px] z-20 flex flex-col w-full absolute bg-[#0F0F0F] opacity-95 items-center justify-center sm:justify-start">
            <img
              src={'/images/promo-banner-short.png'}
              className="w-[473px] h-[138px] object-contain mb-[45px]"
            />
            <p className="text-[40px] font-bold font-bicyclette leading-[50px] gravity ">
              NO ACTIVATE MEMBERSHIP
            </p>
            <p className="text-sm tracking-[0.01em] gotham text-center font-medium leading-[200%]">
              UNFORTUNATELY WE CANNOT FIND ACTIVE MEMBERSHIP ON YOUR ACCOUNT.
              <br />
              LET’S GET YOU BACK IN THE GYM AND TRAINING AGAIN!{' '}
            </p>
            <div
              onClick={() => {
                navigate('/dashboard/memberships');
              }}
              className="mt-6 cursor-pointer px-[25px] py-[13px] border border-brand-clime rounded-md text-brand-clime gotham "
            >
              COMING SOON
            </div>
          </div>
        ) : (
          ''
        )}
        {data.map((data, index) => {
          return (
            <div
              key={index}
              className="group h-[269px] sm:h-[170px] sm:w-full w-[236px] text-white border sm:hover:border-primary border-[rgb(255,255,255,0.4)] sm:border-transparent rounded-[5px] relative "
              onClick={() => {
                width &&
                  width <= breakpoints.sm &&
                  !data.disabled &&
                  navigate(data.link);
              }}
            >
              <div className="absolute group-hover:block hidden sm:group-hover:hidden bg-primary opacity-60 h-full w-full"></div>
              <img
                src={data.img}
                className="h-full w-full object-cover rounded-md group-hover: "
              />

              <div className="grid grid-rows-3 group-hover:h-full flex-wrap flex-col absolute top-0 h-full w-full sm:pl-[16px] sm:pt-[15px] sm:pr-[16px] p-[15px] cursor-pointer">
                {/* <div className='w-full grid grid-cols-2'>
                    <div className='flex w-full  justify-between col-span-2'>
                      <div className='my-[5.5px]'><p className='text-white text-lg font-bold '>{data.text}</p></div>
                      <img className='h-[39px] w-[39px]' src={data.icon} />
                    </div>
                    <div className='group-hover:block hidden col-span-2'>aaaaaaaaaaaaaaa</div>
                  </div> */}
                <div className="w-full h-fit ">
                  <div className="flex justify-between pl-[3px] items-center">
                    <div className="my-[5.5px]">
                      <p className="text-white text-xl font-bold uppercase leading-[90%] font-bicyclette">
                        {data.text}
                      </p>
                    </div>
                    <img className="h-[39px] w-[39px]" src={data.icon} />
                  </div>
                </div>
                {/* <div className='w-full h-max'></div> */}
                <div className="group-hover:block hidden text-white pl-[3px] pr-[5px] sm:group-hover:hidden ">
                  {!data.disabled
                    ? data?.description
                    : data.description
                    ? data?.description
                    : 'Coming soon'}
                </div>
                {!data.disabled && (
                  <div
                    className="ml-[5px] mt-auto mb-[22px] sm:group-hover:hidden justify-self-start group-hover:flex hidden bg-transparent border border-white hover:border-black rounded-md px-1 py-1 text-primary bg-white hover:bg-black hover:text-white cursor-pointer w-[104px]  justify-center items-center h-[30px] uppercase font-medium font-bicyclette"
                    onClick={() => navigate(data.link)}
                  >
                    view
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className="mt-[82px] pb-[67px] sm:hidden">
        <p
          className={` mb-[9px] ${
            userInfoMemberShip?.length == 0
              ? 'text-[rgb(43,47,50)]'
              : 'text-white'
          }`}
        >
          Your membership includes:
        </p>
        <p
          className={`text-sm uppercase font-medium ${
            userInfoMemberShip?.length == 0
              ? 'text-[rgb(43,47,50)]'
              : 'text-primary'
          }`}
        >
          Open 24/7, Access 200 gyms Australia wide
        </p>
      </div>
    </div>
  );
}

export default General;
