import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { listMembershipId } from '@/api/mutation';
import useWindowSize from '@/hooks/useWindowSize';
import PayWithVivaPay from '@/common/payment/paymentWithVivaPay';
import axios from 'axios';
import moment from 'moment';
import InputBase from '@/common/inputs/InputBase';
import SelectOutline from '@/common/dropdowns/SelectOutline';
import ArrowButton from '@/common/buttons/ArrowButton';
import { toastError } from '@/common/toasts/toast';

interface SlideProps {
  onNext: () => void;
  onPrev: () => void;
}
interface PaymentProps {
  tokenId: string;
  prospectId: string;
  memberId: string;
  setContact: () => void;
  contact: any;
  isApiOver18Fail: boolean;
  setIsPaymentSuccess: (v) => void;
  membershipType:string;
}
const Payment: React.FC<SlideProps> = ({
  onNext,
  onPrev,
  info,
  setInfo,
  contact,
  handleCallApiUnder18,
  handleCallApiOver18,
  isApiSignUpFail,
  setIsPaymentSuccess,
  useBank,
  membershipType
}) => {
  const [isSameInfo, setIsSameInfo] = useState<boolean>(false);
  const [isPayment, setIsPayment] = useState<boolean>(false);
  const [paymentData, setPaymentData] = useState({
    tokenId: '',
    prospectId: '',
    memberId: '',
    membershipStartDate: '',
  });
  const handleNextUnder18 = async () => {
    try {
      const handleCallApiInput = {
        prospectId: paymentData?.prospectId,
        membershipStartDate: moment(info?.membershipStartDate).format(
          'YYYY-MM-DD'
        ),
      };
      makeApiRequest().then(async (res) => {
        if (res?.data?.success) {
          setIsPayment(true);
          setIsPaymentSuccess(true);
          setInfo(handleCallApiInput);
          await handleCallApiUnder18(handleCallApiInput);
        } else {
          setIsPayment(false);
          setIsPaymentSuccess(false);
          toastError('Please fill all of the payment info');
        }
      });
    } catch (e) {
      // toastError(e.message);
    } finally {
    }
  };
  const handleNextOver18 = async () => {
    try {
      const handleCallApiInput = {
        prospectId: paymentData?.prospectId,
        membershipStartDate: moment(info?.membershipStartDate).format(
          'YYYY-MM-DD'
        ),
      };
      makeApiRequest().then(async (res) => {
        if (res?.data?.success) {
          setIsPayment(true);
          setIsPaymentSuccess(true);

          await handleCallApiOver18(handleCallApiInput);
        } else {
          setIsPayment(false);
          setIsPaymentSuccess(false);
          toastError('Please fill all of the payment info');
        }
      });
    } catch (e) {
      // toastError(e.message);
    } finally {
    }
  };
  const [membershipData, setMembershipData] = useState([]);
  const [listMemberShipApi] = useLazyQuery(listMembershipId);

  const apiEndpoint = `${process.env.VIVAPAY_ENPOINT}/${paymentData?.tokenId}`;
  const makeApiRequest = async () => {
    try {
      const response = await axios.get(apiEndpoint);
      return response; // Return the response when received
    } catch (error) {
      return null; // Return null on error
    }
  };
  useEffect(() => {
    listMemberShipApi().then((membership) => {
      membership = membership.data;
      if (membership) {
        setMembershipData(membership?.getMembershipByBrandId.items);
      }
    });
    makeApiRequest().then((res) => {
      if (res?.data?.success) {
        setIsPayment(true);
      } else {
        setIsPayment(false);
      }
    });
  }, []);
  const paymentSuccess = () => {
    setIsPayment(true);
  };
  const paymentError = () => {
    setIsPayment(false);
  };
  const [membershipCurrent, setMembershipCurrent] = useState();
  useEffect(() => {
    if (info?.membershipId) {
      const currentMembership = membershipData
        ?.filter((i) => i.id === info.membershipId)
        .map((i) => {
          const costPriceFromLocation =
            i.membershipLocations?.items.filter(
              (item) => item.locationId === info?.memberDetails?.homeLocationId
            )[0]?.costPrice ?? -1;
          return { ...i, costPrice: costPriceFromLocation };
        })[0];
      setMembershipCurrent(currentMembership);
    }
  }, [info]);
  useEffect(() => {
    if (membershipCurrent?.paymentFrequency.type.toLowerCase() !== 'weeks') {
      setIsSameInfo(true);
    } else setIsSameInfo(false);
  }, [membershipCurrent]);
  useEffect(() => {
    const getLocal = localStorage.getItem('payment');
    if (getLocal) {
      setPaymentData(JSON.parse(getLocal));
    }
  }, [onNext]);
  return (
    <div
      id="scroll-hidden"
      className="h-full md:mx-0 overflow-y-scroll pb-[70px] sm:pb-[150px]"
    >
      {/* <div className="pb-[15px] mt-[44px] md:flex-col flex-row md:item-center item-start flex justify-between border-b border-b-primary sm:mt-8">
        <div className="flex items-center gap-2 md:mb-0 ">
          <h2 className="heading2 text-primary uppercase font-bicyclette">
            today’s payment
          </h2>
        </div>
        <div className="sm:hidden flex items-center gap-3 justify-between h-[18px]">
          <h2 className="uppercase gotham text-white text-sm -tracking-tight">
            total due today{' '}
          </h2>
          <h2 className="text-white uppercase font-bold gotham text-[20px] leading-[18px] font-bicyclette tracking-wider">
            $
            {membershipCurrent?.costPrice +
              membershipCurrent?.joiningFee +
              membershipCurrent?.joiningFee2 +
              membershipCurrent?.joiningFee3}
          </h2>
        </div>
      </div> */}
      <div className="pb-[8px] mt-[60px] md:flex-col flex-row md:item-center item-start  flex  justify-between border-b border-b-primary">
        <div className="flex gap-2 md:self-start self-end h-[29px] items-start">
          <h2 className="heading2 text-primary text-start uppercase font-bicyclette">
            today’s payment
          </h2>
        </div>
        <div className="sm:hidden flex items-center md:mt-0 mt-3 gap-3">
          <div className="flex items-end gap-x-2 h-[18px]">
            <h2 className="text-white text-sm tracking-[1.4px] uppercase">
              total due today{' '}
            </h2>
            <h2 className="text-white inline-block text-xl font-bold font-bicyclette uppercase leading-[18px]">
                                <span className="text-white inline-block text-xl font-bold font-gilroy uppercase leading-[18px]">$</span>

              {membershipCurrent?.costPrice +
                membershipCurrent?.joiningFee +
                membershipCurrent?.joiningFee2 +
                membershipCurrent?.joiningFee3}
            </h2>
          </div>
        </div>
      </div>
      <div className="sm:flex mt-[29px] hidden items-center gap-3 justify-between sm:mt-7">
        <h2 className="uppercase gotham text-white text-sm sm:text-base sm:font-bold">
          total due today{' '}
        </h2>
        <span className="text-white uppercase font-bold gotham text-sm sm:font-bold sm:text-lg sm:text-white">
        <span className="text-white uppercase font-bold gotham text-sm sm:font-bold sm:text-lg sm:text-white">$</span>

          {membershipCurrent?.costPrice +
            membershipCurrent?.joiningFee +
            membershipCurrent?.joiningFee2 +
            membershipCurrent?.joiningFee3}
        </span>
      </div>
      <div className=" mt-5 flex-row sm:flex-col">
        <div className="mt-8">
          <PayWithVivaPay
            info={info}
            callApiPayment={makeApiRequest}
            isPayment={isPayment}
            handleNextOver18={handleNextOver18}
            handleNextUnder18={handleNextUnder18}
            paymentSuccess={paymentSuccess}
            paymentError={paymentError}
            cardRequestId={paymentData?.tokenId}
            cost={
              membershipCurrent?.costPrice +
              membershipCurrent?.joiningFee +
              membershipCurrent?.joiningFee2 +
              membershipCurrent?.joiningFee3
            }
          />
          <p  className="text-start mt-[5px] text-xsm leading-[160%] text-textSecondary font-normal ">
            Click on the button to pay
          </p>
        </div>
        {!useBank && membershipType!=='pif' && (
          <div className="text-white text-start mt-[20px] flex">
            <input type="radio" checked={true} className="mr-2" />
            <p>
              Future payment will be proceeded with the card above as default
            </p>
          </div>
        )}
      </div>
      {/* Button to actions */}
      <div className="mt-[35px] w-full flex items-center justify-between">
        <div onClick={() => onPrev()}>
          <div className="md:hidden flex">
            <ArrowButton text="back" isRight={false} />
          </div>
          <div className="md:flex hidden">
            <ArrowButton text="" isRight={false} />
          </div>
        </div>
        {/*{isApiSignUpFail &&*/}
        {/*  <div*/}
        {/*    onClick={async () => {*/}
        {/*      if((moment(new Date()).diff(moment(contact?.dob), 'years') < 18))*/}
        {/*      {*/}
        {/*        await handleNextUnder18();*/}

        {/*      }*/}
        {/*      else{*/}
        {/*        await handleNextOver18();*/}
        {/*      }*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <div className="md:hidden flex">*/}
        {/*      <ArrowButton text="signup" isRight={true} />*/}
        {/*    </div>*/}
        {/*    <div className="md:flex hidden">*/}
        {/*      <ArrowButton text="pay now" isRight={true} />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*}*/}
      </div>
    </div>
  );
};

export default Payment;
