import React, { useContext, useEffect, useState } from 'react';
import {
  addMemberPaymentInformation,
  chargeSinglePaymentViaVivaPay, getMember,
} from '@/api/mutation';
import PrimaryOutlinedButton from '@/common/buttons/PrimaryOutlinedButton';
import { toastError, toastSuccess } from '@/common/toasts/toast';
import { useApolloClient, useMutation } from '@apollo/client';
import { Modal } from 'react-responsive-modal';
import InputBase from '@/common/inputs/InputBase';
import axios from 'axios';
import { Auth, Storage } from 'aws-amplify';
import { navigate } from 'gatsby';

interface SlideProps {
  userInfo: any;
  setModalMakePayment: (v: boolean) => void;
  amount: string;
  paymentType: string;
  changeMembershipData: {
    changeMembershipType: string;
    newMemberShipId: string;
    locationId: string;
  };
  setStep: (v: number) => void | undefined;
  openModalOutside: (v: boolean) => void;
  payloadData: any;
  isPIF:boolean;
  newLocationId;
}
const ModalMakePayment: React.FC<Partial<SlideProps>> = ({
  setModalMakePayment,
  amount,
  paymentType,
  changeMembershipData,
  setStep,
  openModalOutside,
  payloadData,
  isPIF= true,
  newLocationId
}) => {
  const [chargeSinglePayment] = useMutation(chargeSinglePaymentViaVivaPay);
  const [addMemberPaymentInfo] = useMutation(addMemberPaymentInformation);
  const [loading, setLoading] = useState(false);
  const [confirmPay, setConfirmPay] = useState(false);
  const [cardType, setCardType] = useState('cardOnFile');
  const [modalVivaPayIsOpen, setIsOpenVivaPay] = React.useState(false);
  const [cardPrimary, setCardPrimary] = useState({});
  const [paymentInfoBank, setPaymentInfoBank] = useState({
    holderName: '',
    accountNumber: '',
    bsb: '',
  });
  const [cardRequestId, setCardRequestId] = useState('');
  const [futureOption, setFutureOption] = useState('oldCardAsDefault');
  const [paymentInformation, setPaymentInformation] = useState('');
  const [loadingMakePayment, setLoadingMakePayment] = useState(false);
  const [loadingVivaPay, setLoadingVivaPay] = useState(false);
  const [useCreditCard, setUseCreditCard] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const client = useApolloClient();
  const iframeStyle = {
    width: '100%',
    height: '100%',
    border: 'none',
    outline: 'none',
    borderRadius: '10px',
    overflow: 'hidden',
  };
  const iframeSrc = `${process.env.VIVAPAY_IFRAME}/${cardRequestId}`;
  const apiEndpoint = `${process.env.VIVAPAY_ENPOINT}/${cardRequestId}`;
  useEffect(() => {
    userInfo?.paymentDetails?.items.map((item: any) => {
      if (item.primary && item.paymentType === 'CREDIT_CARD') {
        setCardPrimary(item);
      }
    });
  }, [userInfo]);
  const mountedFunction = () => {
    try {
      // Amplify.configure({
      //   Auth: {
      //     region: `${process.env.AWS_REGION}`,
      //     aws_project_region: `${process.env.AWS_REGION}`,
      //     aws_appsync_graphqlEndpoint: `${process.env.AWS_ENDPOINT}`,
      //     aws_appsync_region: `${process.env.AWS_REGION}`,
      //     aws_appsync_authenticationType: `${process.env.AWS_SIGNIN_AUTH_TYPE}`,
      //     aws_user_pools_id: `${process.env.AWS_USER_POOLS_ID}`,
      //     aws_user_pools_web_client_id: `${process.env.AWS_USER_POOLS_WEB_CLIENT_ID}`,
      //     aws_user_files_s3_bucket: `${process.env.AWS_S3_BUCKET_NAME}`,
      //     aws_user_files_s3_bucket_region: `${process.env.AWS_S3_BUCKET_REGION}`,
      //   },
      // });
      // const memberInfo = JSON.parse(localStorage.getItem("currentUserDetail"));
      Auth.currentAuthenticatedUser()
        .then((user) => {
          client
            .query({
              query: getMember,
              variables: {
                memberId: user.attributes['custom:memberId'],
              },
            })
            .then(async (res) => {
              const data = res.data.getMember;
              const imgUrl = data?.imageUrl
                ? await Storage.get(data.imageUrl)
                : '/icons/Dashboard/profile.svg';
              setUserInfo({ ...data, imageUrl: imgUrl });
              localStorage.setItem(
                'currentUserDetail',
                JSON.stringify(res.data.getMember)
              );
            });

        })
        .catch((e) => {});
    } catch (e) {
      console.log(e);
      navigate('/');
    }
  };
  useEffect(() => {
    mountedFunction();
  }, []);
  const makeApiRequest = async () => {
    try {
      const response = await axios.get(apiEndpoint);
      return response; // Return the response when received
    } catch (error) {
      return null; // Return null on error
    }
  };
  const handlePay = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setLoadingMakePayment(true);
      let futurePaymentOption = '';
      let operation = '';
      if (futureOption === 'bankAccount') {
        futurePaymentOption = 'BANK_ACCOUNT_IS_DEFAULT';
      } else if (
        (cardType === 'differentCard' && futureOption === 'oldCardAsDefault') ||
        (Object.keys(cardPrimary).length && futureOption === 'oldCardAsDefault')
      ) {
        futurePaymentOption = 'CARD_ON_FILE_IS_DEFAULT';
      } else if (
        (cardType === 'differentCard' && futureOption === 'newCardAsDefault') ||
        useCreditCard
      ) {
        futurePaymentOption = 'DIFFERENT_CARD_IS_DEFAULT';
      }

      if (paymentType === 'changeMembership') {
        if (
          changeMembershipData &&
          changeMembershipData.changeMembershipType === 'upGrade'
        ) {
          operation = 'MUU';
        } else if (
          changeMembershipData &&
          changeMembershipData.changeMembershipType === 'downGrade'
        ) {
          operation = 'MDO';
        }
      } else if (paymentType === 'outstanding') {
        operation = 'OSB';
      } else if (paymentType === 'SSC') {
        operation = 'SSC';
      } else operation = paymentType;
      const payload = {
        variables: {
          amount: amount,
          memberId: user.attributes['custom:memberId'],
          operation: operation,
          futurePaymentOption: futurePaymentOption,
          ...(paymentType === 'changeMembership' && {
            changeMembershipArgumentsInput: {
              newMembershipId:
                changeMembershipData && changeMembershipData.newMemberShipId,
                newLocationId: newLocationId && newLocationId
            },
          }),
          ...((paymentType === 'SSC' ||
            paymentType === 'SSU' ||
            paymentType === 'AMS' ||
            paymentType === 'MCU') &&
            payloadData),
          bankAccountDetails:
            futureOption === 'bankAccount' ? paymentInfoBank : undefined,
          paymentInformationId:
            Object.keys(cardPrimary).length !== 0 && cardType === 'cardOnFile'
              ? cardPrimary?.id
              : paymentInformation.paymentInformationId,
        },
      };
      const response = await chargeSinglePayment(payload);
      if (response.data.errors) {
        toastError(response.data.errors);
        typeof setModalMakePayment === 'function' && !loadingMakePayment && !loadingVivaPay && setModalMakePayment(false);
      } else {
        typeof openModalOutside === 'function'&& openModalOutside(false);
        typeof setModalMakePayment === 'function' && !loadingMakePayment && !loadingVivaPay && setModalMakePayment(false);
        typeof setStep === 'function' && setStep(6);
      }
    } catch (e) {
      toastError(e.message);
    } finally {
      !loadingMakePayment && !loadingVivaPay && setModalMakePayment(false);
    }
  };
  async function openModalVivaPay() {
    const user = await Auth.currentAuthenticatedUser();
    setIsOpenVivaPay(true);
    setLoading(true);
    try {
      const data = await addMemberPaymentInfo({
        variables: {
          input: {
            action: 'GET_SAVE_CARD_TOKEN_REQUEST',
            memberId: user.attributes['custom:memberId'],
            requestApplication: 'MEMBER_PORTAL',
            locationId: changeMembershipData.locationId,
          },
        },
      });
      if (data?.data.addMemberPaymentInformation.cardRequestId) {
        setLoading(false);
        setCardRequestId(data.data?.addMemberPaymentInformation.cardRequestId);
      }
    } catch (e) {
      toastError(e.message);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  }
  async function closeModalVivaPay() {
    setIsOpenVivaPay(false);
    try {
      const user = await Auth.currentAuthenticatedUser();
      setLoadingVivaPay(true);
      const data = await addMemberPaymentInfo({
        variables: {
          input: {
            action: 'ADD_CREDIT_CARD_REQUEST',
            memberId: user.attributes['custom:memberId'],
            cardRequestId: cardRequestId,
            requestApplication: 'MEMBER_PORTAL',
            locationId: changeMembershipData.locationId,
          },
        },
      });
      setPaymentInformation(data.data?.addMemberPaymentInformation);
      makeApiRequest().then((res) => {
        if (res?.data?.success) {
          toastSuccess('Save card successfully');
          setCardRequestId('');
        } else {
          if(!useCreditCard){
            setCardType('cardOnFile')
          }
          else{
            setUseCreditCard(false)
          }
          toastError('Save card fail');
          setCardRequestId('');
        }
      });
    } catch (e) {
      toastError(e.message);
      setLoadingVivaPay(false);
    } finally {
      setLoadingVivaPay(false);
    }
  }
  const setDataBank = (e) => {
    setPaymentInfoBank({ ...paymentInfoBank, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <Modal
        showCloseIcon={true}
        open={true}
        onClose={() =>!loadingMakePayment && !loadingVivaPay && setModalMakePayment(false)}
        styles={{
          overlay: {
            background: 'rgba(45, 45, 35, 0.4)',
          },
          modal: {
            background: 'black',
            height: 'auto',
            width: 'auto',
            padding: 0,
          },
          modalContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
        }}
      >
        <>
          {loadingMakePayment && (
            <div className="z-50 absolute h-full w-full justify-center flex bg-[#303846e6]">
              <div className="row examples m-auto ">
                <div className="  ">
                  <div className=" relative " data-title="dot-pulse">
                    <div className="stage w-[200px] m-auto !pb-5">
                      <div className="dot-pulse"></div>
                    </div>
                    <div className='mt-3 text-center text-2xl text-[#7681ff]'>Processing your payment</div>
                    <div className="mt-5 text-center  text-white tracking-[1px]">
                      Processing time may take up to 60 seconds
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {loadingVivaPay &&
            <div className="z-50 absolute h-full w-full justify-center flex bg-[#303846e6]">
              <div className="row examples m-auto ">
                <div className="  ">
                  <div className=" relative " data-title="dot-pulse">
                    <div className="stage w-[200px]">
                      <div className="dot-pulse"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          {!confirmPay && (
            <div className="text-white px-[33px] py-[33px]  text-center text-2xl ">
              <p>
                {paymentType === 'outstanding' && 'Out Standing Balance Fee'}
                {paymentType === 'changeMembership'
                  && changeMembershipData
                  && changeMembershipData.changeMembershipType==='upGrade'
                  && 'Upgrade Membership Fee'}
                {paymentType === 'changeMembership'
                  && changeMembershipData
                  && changeMembershipData.changeMembershipType==='downGrade'
                  && 'Downgrade Membership Fee'}
                {paymentType === 'SSU' && 'Edit Suspension Fee'}
                {paymentType === 'MCU' && 'Edit Medical Suspension Fee'}
                {/* {paymentType === 'MCU' && 'Cancel suspension'} */}
                {paymentType === 'SSC' && 'Create Suspension Fee'}
                {paymentType === 'AMS' && 'Add Membership'}
                :{' '}
                <span className="text-primary text-2xl">${amount}</span>
              </p>
              <div className="grid grid-cols-2 mt-6 gap-6">
                <PrimaryOutlinedButton
                  onClick={() => {
                    setConfirmPay(true);
                  }}
                  className="rounded-md"
                >
                  PAY NOW
                </PrimaryOutlinedButton>
                <PrimaryOutlinedButton
                  className="hover:bg-textError hover:text-white rounded-md hover:border-none"
                  onClick={() => {
                    setModalMakePayment(false);
                  }}
                >
                  CANCEL
                </PrimaryOutlinedButton>
              </div>
            </div>
          )}
          {confirmPay && (
            <div className="text-white px-[33px] py-[33px]  ">
              <p className=" pb-6 text-[35px] mr-auto ml-auto text-center w-[482px] ">
                MAKE PAYMENT
              </p>
              <div className="grid grid-cols-3 gap-[24px]">
                <p className="col-span-3">MEMBER DETAILS</p>
                <div>
                  <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                    Member No:
                  </p>
                  <p className="text-white h-[18px] border-b border-b-white text-sm leading-[90%]">
                    {userInfo?.aliasMemberId}
                  </p>
                </div>
                <div>
                  <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                    Member Name:
                  </p>
                  <p className="text-white h-[18px] border-b border-b-white text-sm leading-[90%]">
                    {userInfo?.givenName}  {userInfo?.surname}
                  </p>
                </div>
                <div>
                  <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                    Date Of Birth:
                  </p>
                  <p className="text-white border-b h-[18px] border-b-white text-sm leading-[90%]">
                    {userInfo?.dob?.split('-').reverse()?.join('/')}
                  </p>
                </div>
                <p className="col-span-3">
                  {paymentType === 'outstanding' && 'Outstanding'}
                  {paymentType === 'changeMembership' &&
                    'Change membership'}{' '}
                  Payments
                </p>

                <div className="col-span-3 grid grid-cols-2">
                  <div>
                    <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                      Amount:
                    </p>
                    <p className="text-white border-b h-[18px] border-b-white text-sm leading-[90%]">
                      {amount}
                    </p>
                  </div>
                </div>
                <p className="text-center text-white text-sm col-span-3">
                  Credit card surcharge will automatically apply to all payments
                  <br />
                  (VISA/MASTSERCARD: 1.4%, AMEX:2.5%)
                </p>
                {Object.keys(cardPrimary).length !== 0 ? (
                  <div className="col-span-3 text-center text-sm flex justify-evenly cursor-pointer">
                    <div
                      className="justify-evenly flex"
                      onClick={(event) => {
                        setCardType('cardOnFile');
                      }}
                    >
                      <input
                        checked={cardType === 'cardOnFile'}
                        className="mr-[10px]"
                        type="radio"
                      />
                      <p>USE CARD ON FILE</p>
                    </div>
                    <div
                      className="justify-evenly flex cursor-pointer"
                      onClick={() => {
                        setCardType('differentCard');
                        openModalVivaPay();
                      }}
                    >
                      <input
                        className="mr-[10px]"
                        type="radio"
                        checked={cardType === 'differentCard'}
                      />
                      <p>USE DIFFERENT CARD</p>
                    </div>
                  </div>
                ) : (
                  <div className="col-span-2">
                    <div
                      className=" flex cursor-pointer"
                      onClick={() => {
                        openModalVivaPay();
                        setUseCreditCard(true);
                      }}
                    >
                      <input
                        className="mr-[10px]"
                        type="radio"
                        checked={useCreditCard}
                      />
                      <p>PAY WITH CREDIT CARD</p>
                    </div>
                  </div>
                )}

                {/*Use Card On File*/}
                {(cardType === 'differentCard' || useCreditCard) &&
                  paymentInformation.cardNumber && (
                    <div className="grid grid-cols-3 col-span-3 gap-x-7">
                      <div className="col-span-1">
                        <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                          Type:
                        </p>
                        <p className="text-white border-b border-b-white text-sm h-[18px] leading-[90%]">
                          {paymentInformation?.cardType
                            ? paymentInformation?.cardType
                            : paymentInformation?.accountName}
                        </p>
                      </div>

                      <div className="col-span-1">
                        <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                          Card:
                        </p>
                        <p className="text-white border-b border-b-white  h-[18px] text-sm leading-[90%]">
                          {paymentInformation?.cardNumber}
                        </p>
                      </div>
                      <div className="col-span-1">
                        <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                          Expiry:
                        </p>
                        <p className="text-white border-b border-b-white h-[18px] text-sm leading-[90%]">
                          {paymentInformation?.cardExpiryDate
                            ? paymentInformation?.cardExpiryDate
                            : paymentInformation?.accountNumber}
                        </p>
                      </div>
                    </div>
                  )}
                {cardType === 'cardOnFile' &&
                  Object.keys(cardPrimary).length !== 0 && (
                    <div className="grid grid-cols-3 col-span-3 gap-x-7">
                      <div className="col-span-1">
                        <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                          Type:
                        </p>
                        <p className="text-white border-b border-b-white text-sm h-[18px] leading-[90%]">
                          {cardPrimary?.cardType
                            ? cardPrimary?.cardType
                            : cardPrimary?.accountName}
                        </p>
                      </div>

                      <div className="col-span-1">
                        <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                          Card:
                        </p>
                        <p className="text-white border-b border-b-white  h-[18px] text-sm leading-[90%]">
                          {cardPrimary?.cardNumber
                            ? cardPrimary?.cardNumber
                            : cardPrimary?.bsb}
                        </p>
                      </div>
                      <div className="col-span-1">
                        <p className="text-[#bfbfbf] mb-[5px]  text-sm leading-[90%]">
                          Expiry:
                        </p>
                        <p className="text-white border-b border-b-white h-[18px] text-sm leading-[90%]">
                          {cardPrimary?.cardExpiryDate
                            ? cardPrimary?.cardExpiryDate
                            : cardPrimary?.accountNumber}
                        </p>
                      </div>
                    </div>
                  )}
                {/*End Use Card On File*/}
                {paymentType === 'changeMembership' && !isPIF &&(
                  <div className="col-span-3">
                    <p>FUTURE PAYMENT OPTION</p>
                    <div className="flex mt-[16px]">
                      <input
                        className="mr-[10px]"
                        type="radio"
                        checked={futureOption === 'oldCardAsDefault'}
                        onClick={() => setFutureOption('oldCardAsDefault')}
                      />
                      <p className="tracking-[0.5px]">
                        Future payment will be proceeded with the card above as
                        default
                      </p>
                    </div>
                    {Object.keys(cardPrimary).length !== 0 &&
                      cardType !== 'cardOnFile' && (
                        <div className="mt-[16px] flex">
                          <input
                            className="mr-[10px]"
                            type="radio"
                            checked={futureOption === 'newCardAsDefault'}
                            onClick={() => setFutureOption('newCardAsDefault')}
                          />
                          <p className="tracking-[0.5px]">
                            Select this option if you would like to pay your
                            subsequent fees with the new card same as above
                            rather than the card on file
                          </p>
                        </div>
                      )}
                    <div className="mt-[16px] flex">
                      <input
                        className="mr-[10px]"
                        type="radio"
                        checked={futureOption === 'bankAccount'}
                        onClick={() => setFutureOption('bankAccount')}
                      />
                      <p className="tracking-[0.5px]">
                        Select this option if you would like to enter
                        Bank/Savings Account number to pay your subsequent fees
                        rather than the above credit card
                      </p>
                    </div>
                  </div>
                )}

                {futureOption === 'bankAccount' && (
                  <div className=" col-span-3 grid w-full md:grid-cols-1 grid-cols-2 gap-x-9 gap-y-[13px]">
                    <InputBase
                      holder="Acount name*"
                      nameInput="holderName"
                      style="lg:w-full"
                      isRequired={true}
                      onchange={(e) => setDataBank(e)}
                    />
                    <InputBase
                      holder="Account number*"
                      style="lg:w-full"
                      nameInput="accountNumber"
                      isRequired={true}
                      onchange={(e) => setDataBank(e)}
                    />
                    <InputBase
                      holder="BSB*"
                      style="lg:w-full"
                      nameInput="bsb"
                      isRequired={true}
                      onchange={(e) => setDataBank(e)}
                    />
                  </div>
                )}
                <div className="col-span-3 flex items-center justify-center gap-6">
                  <PrimaryOutlinedButton
                    onClick={() => {
                      handlePay();
                    }}
                    className="rounded-md"
                  >
                    MAKE PAYMENT
                  </PrimaryOutlinedButton>
                  <PrimaryOutlinedButton
                    className="hover:bg-textError hover:text-white rounded-md hover:border-textError"
                    onClick={() => {
                      setModalMakePayment(false);
                    }}
                  >
                    CANCEL
                  </PrimaryOutlinedButton>
                </div>
              </div>
            </div>
          )}
        </>
      </Modal>
      {modalVivaPayIsOpen && (
        <div>
          <Modal
            open={cardType === 'differentCard' || useCreditCard}
            onClose={closeModalVivaPay}
            center
            styles={{
              overlay: {
                background: 'rgba(45, 45, 35, 0.4)',
              },
              modal: {
                height: '62%',
              },
            }}
          >
            {loading ? (
              <div className="z-50 absolute top-[50%] left-[30%] ">
                <div className="row examples m-auto ">
                  <div className="  ">
                    <div className=" relative " data-title="dot-pulse">
                      <div className="stage w-[200px]">
                        <div className="dot-pulse"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <iframe
                id="pay-with-vivapay"
                title="pay with vivapay"
                src={iframeSrc}
                style={iframeStyle}
              />
            )}
          </Modal>
        </div>
      )}
    </div>
  );
};
export default ModalMakePayment;
