import React, { useContext, useEffect } from 'react';
import { navigate } from 'gatsby';
import { accessRoute, accessKey } from '@/constants';
import { getCookie } from '@/helpers/cookie.helper';
import { Auth, Storage } from 'aws-amplify';
import { AuthContext, AuthProvider } from '@/api/auth';
import { useApolloClient } from '@apollo/client';
import { getMember } from '@/api/mutation';

const PrivateRoute = ({
  component: Component,
  location,
  ...rest
}: {
  component: React.FunctionComponent<any>;
  location: any;
}) => {
  const setUserInfo = useContext(AuthContext).setUserInfo;
  const userInfo = useContext(AuthContext).userInfo;
  useEffect(() => {
    // if (!getCookie(accessKey) && location.pathname !== accessRoute) {
    //   navigate(accessRoute);
    // }
    if(!userInfo){signIn();}
  }, []);
  const getToken = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      return true;
    } catch (e) {
      return false;
    }
  };
  const client = useApolloClient();

  const signIn = async () => {
    const token = await getToken();
    if (!token) {
      navigate('/login');
    } else {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          client
            .query({
              query: getMember,
              variables: {
                memberId: user.attributes['custom:memberId'],
              },
            })
            .then(async (res) => {
              const data = res.data.getMember;
              const imgUrl = data?.imageUrl
                ? await Storage.get(data.imageUrl)
                : '/icons/Dashboard/profile.svg';
              setUserInfo({ ...data, imageUrl: imgUrl });
              localStorage.setItem(
                'currentUserDetail',
                JSON.stringify(res.data.getMember)
              );
            });
        })
        .catch((e) => {});
    }
  };
  return <Component {...rest} />;
};

export default PrivateRoute;
