import { gql } from '@apollo/client';

export const getMember = gql`
  query getMember($memberId: ID!) {
    getMember(memberId: $memberId) {
      memberId
      isBlocked
      aliasMemberId
      email
      secondaryEmail
      title
      givenName
      middleName
      surname
      gender
      dob
      mobileNumber
      outstandingBalance
      workPhone
      homePhone
      emergencyContactName
      emergencyContactEmail
      emergencyContactNumber
      emergencyContactRelation
      emergencyContactName2
      emergencyContactEmail2
      emergencyContactNumber2
      emergencyContactRelation2
      imageUrl
      address
      suburb
      state
      postCode
      country
      joinDate
      getLinksSms
      accepted
      brandId
      type
      role
      staffKind
      isBlocked
      homeLocationId
      brandDetails {
        id
        name
        __typename
      }
      homeLocationDetails {
        id
        name
        __typename
      }
      contracts {
        items {
          id
          description
          membershipName
          startDateTime
          isActive
          locationId
          membershipId
          costPriceFromMembership
          membershipLocationId
          expiryDateTime
          endDateTime
          paymentFrequency {
            type
            amount
            __typename
          }
          # joinedLocationDetail {
          #   name
          #   shortName
          #   __typename
          # }

          accessGroup {
            items {
              name
              accessGroupAccessPoints {
                items {
                  locationId
                }
              }
            }
          }
          billings {
            items {
              isProcessed
              debitDate
              debitAmount
              currency
              id
              membershipAmount
              description
              locationId
              memberContractId
              memberId
              membershipId
              membershipLocationId
              payLocationId
              paymentType
              orderId
              # directDebitLog {
              #   items {
              #     id
              #     billingId
              #     updatedBy
              #     description
              #     debitAmountNew
              #     debitAmountOriginal
              #     reason
              #     createdAt
              #     updaterDetails {
              #       memberId
              #       createdAt
              #       givenName
              #       surname
              #       __typename
              #     }
              #     __typename
              #   }
              #   __typename
              # }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      paymentDetails {
        items {
          accountNumber
          accountName
          bsb
          cardCvv
          cardExpiryDate
          cardHolderName
          cardNumber
          cardType
          id
          paymentType
          primary
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const listClassSchedules = gql`
  query listClassSchedules {
    listClassSchedules {
      items {
        id
        brandId
        bookingMessage
        classScheduleDateTime
        classScheduleEndDateTime
        instructorDetails {
          instructorDisplayName
          givenName
        }
        isActive
        locationId
        maxBooking
        maxPerTeam
        note
        studioDetails {
          studioName
        }
        classTemplateDetails {
          className
          hasVirtualClass
          # isBookable
          classDescription

          isActive
        }
      }
    }
    # listClassSchedules {
    #   items {
    #     classScheduleDateTime
    #     classScheduleEndDateTime
    #     brandId
    #     instructorDetails {
    #       givenName
    #       surname
    #       instructorDisplayName
    #     }
    #     maxBooking
    #     studioDetails {
    #       studioName
    #       locationId
    #     }
    #         isActive
    #     locationId
    #     maxBooking
    #     maxPerTeam
    #     studioId
    #     locationId
    #     classTemplateDetails {
    #       className
    #       hasVirtualClass
    #       isBookable
    #       classDescription

    #       isActive
    #     }
    #   }
    # }
  }
`;

export const getClassScheduleByLocationId = gql`
  query getClassScheduleByLocationId(
    $locationId: ID!
    $classScheduleDateTime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    getClassScheduleByLocationId(
      locationId: $locationId
      classScheduleDateTime: $classScheduleDateTime
      sortDirection: $sortDirection
    ) {
      items {
        id
        locationId
        bookingMessage
        classScheduleEndDateTime
        classScheduleDateTime
        classTemplateId
        isActive
        isBookable
        maxBooking
        isThisSeries

        bookings {
          items {
            id
            cancelledDateTime
          }
        }
        bookingCount
        classTemplateDetails {
          id
          className
          brandId
          classDescription
          classDuration
        }
        instructorDetails {
          id
          givenName
          surname
          instructorFullName
          instructorDisplayName
        }
        studioDetails {
          id
          studioName
        }
      }
    }
  }
`;

export const listMemberships = gql`
  query {
    listMemberships {
      items {
        id
        brandId
        membershipName
        description
        costPrice
        isActive
        description
        joiningFee
        joiningFee2
        joiningFee3
        isDeleted
        joiningFee
        paymentFrequency {
          type
          amount
        }
        activationDates {
          items {
            createdAt
            endDateTime
            id
            isActive
            membershipId
            startDateTime
            updatedAt
          }
        }
        membershipLocations {
          items {
            id
            locationId
            membershipId
            createdAt
          }
        }
        brandDetails {
          id
          locations {
            items {
              id
            }
          }
        }
      }
    }
  }
`;

export const listMembershipId = gql`
  query getMembershipByBrandId {
    getMembershipByBrandId(brandId: "6dec4e5f-7a07-4a7e-a809-2c0c1df01366") {
      items {
        id
        brandId
        membershipName
        description
        costPrice
        isActive
        description
        joiningFee
        joiningFee2
        joiningFee3
        isDeleted
        joiningFee
        recurring
        contractLength {
          amount
          type
        }
        paymentFrequency {
          type
          amount
        }
        activationDates {
          items {
            createdAt
            endDate
            id
            isActive
            membershipId
            startDateTime
            updatedAt
          }
        }
        membershipLocations {
          items {
            costPrice
            id
            locationId
            membershipId
            isActive
            createdAt
          }
        }
        brandDetails {
          id
          locations {
            items {
              id
            }
          }
        }
      }
    }
  }
`;

export const getMembershipById = gql`
  query getMembership($id: ID!) {
    getMembership(id: $id) {
      id
      brandId
      membershipName
      description
      costPrice
      isActive
      description
      joiningFee
      joiningFee2
      joiningFee3
      isDeleted
      joiningFee
      paymentFrequency {
        type
        amount
      }
      activationDates {
        items {
          createdAt
          endDate
          id
          isActive
          membershipId
          startDate
          updatedAt
        }
      }
      membershipLocations {
        items {
          id
          locationId
          membershipId
          createdAt
        }
      }
      brandDetails {
        id
        locations {
          items {
            id
            abn
          }
        }
      }
    }
  }
`;
// query {
//   listMemberships {
//     items {
//       brandDetails {
//         id
//         locations {
//           items {
//             id
//           }
//         }
//       }
//       id
//       brandId
//       membershipName
//       costPrice
//       isActive
//       description
//       joiningFee
//       joiningFee2
//       joiningFee3
//       isDeleted
//       joiningFee
//       paymentFrequency {
//         type
//       }
//     }
//   }
// }

export const getClassBookingByMemberId = gql`
  query getClassBookingsByMemberId($memberId: ID!) {
    getClassBookingsByMemberId(memberId: $memberId) {
      items {
        id
        bookedDateTime
        cancelledDateTime
        hasAttended
        waitlistClassScheduleId
        waitlistClassScheduleDetails {
          id
          classScheduleDateTime
          classScheduleEndDateTime
          instructorDetails {
            instructorDisplayName
            givenName
          }
          classTemplateDetails {
            id
            classDescription
            className
          }
          locationId
        }
        classScheduleDetails {
          id
          classScheduleDateTime
          classScheduleEndDateTime

          instructorDetails {
            instructorDisplayName
            givenName
          }
          bookings {
            items {
              classScheduleId
              confirmedDateTime
              hasAttended
              memberClassBookingList {
                items {
                  waitlistClassScheduleId
                }
              }
            }
          }
          classTemplateDetails {
            id
            classDescription
            className
          }
          locationId
        }
      }
    }
  }
`;

export const getListLocation = gql`
  query {
    listLocations {
      items {
        id
        clubUrl
        clubImage
        email
        name
        phoneNumber
        is24Hour
        address {
          address
          city
          suburb
          state
          postCode
        }
        brandId
        clubPageUrl
      }
    }
  }
`;

export const getBrandByName = gql`
  query {
    getBrandByName(name: "Plus Fitness") {
      nextToken
      items {
        id
      }
    }
  }
`;

export const getLocationByBrandId = gql`
  query getLocationByBrandId($brandId: ID!) {
    getLocationByBrandId(brandId: $brandId) {
      items {
        city
        clubImage
        clubPageUrl
        clubUrl
        country
        email
        geoLocation {
          latitude
          longitude
        }
        address {
          address
          city
          suburb
          state
          postCode
        }
        hasCreche
        id
        is24Hour
        locationAccessTimes {
          items {
            closeTime
            openTime
            day
            type
            phDate
          }
        }
        locationFeatures {
          items {
            featureId
            featureDetails {
              description
              imageUrl
              name
            }
          }
        }
        brandId
        name
        phoneNumber
        state
        status
        suburb
        summary
        walk3DURL
      }
    }
  }
`;

export const listLocationEmail = gql`
  query {
    listLocations {
      items {
        id
        email
        clubUrl
        clubImage
        name
        phoneNumber
        is24Hour
        address {
          address
          city
          suburb
          state
          postCode
        }
        brandId
        clubPageUrl
      }
    }
  }
`;

export const getMemberContractByMemberId = gql`
  query getMemberContractByMemberId($memberId: ID!) {
    getMemberContractByMemberId(memberId: $memberId) {
      items {
        id
        description
        membershipName
        startDateTime
        endDateTime
        recurring
        isActive
        locationId
        isPending
        membershipId
        costPrice
        costPriceFromMembership
        membershipLocationId
        expiryDateTime
        expiryDate
        recurring
        paymentFrequency {
          type
          amount
        }
        autoRenew
        joinedLocationDetail {
          name
          shortName
          memberships {
            items {
              costPrice
              locationDetails {
                address {
                  address
                }
                name
              }
            }
          }
        }
        billings {
          items {
            isProcessed
            debitDate
            debitAmount
            currency
            id
            membershipAmount
            description
            locationId
            memberContractId
            memberId
            membershipId
            membershipLocationId
            payLocationId
            paymentType
          }
        }
        joiningFee3
        joiningFee2
        joiningFee
        accessGroup {
          items {
            name
            accessGroupAccessPoints {
              items {
                locationId
              }
            }
          }
        }
        suspensions {
          items {
            id
            suspensionStatus
            # isActive
            cancelledDateTime
            suspensionEndDateTime
            suspensionStartDateTime
            suspensionType
            locationId
            memberContractId
          }
        }
      }
    }
  }
`;

export const fullMemberData = gql`
  query getMember($memberId: ID!) {
    getMember(memberId: $memberId) {
      isBlocked
      memberId
      outstandingBalance
      aliasMemberId
      email
      secondaryEmail
      title
      givenName
      middleName
      surname
      gender
      dob
      mobileNumber
      workPhone
      homePhone
      emergencyContactName
      emergencyContactEmail
      emergencyContactNumber
      emergencyContactRelation
      emergencyContactName2
      emergencyContactEmail2
      emergencyContactNumber2
      emergencyContactRelation2
      imageUrl
      address
      suburb
      state
      postCode
      country
      joinDate
      getLinksSms
      accepted
      brandId
      type
      role
      staffKind
      isBlocked
      homeLocationId
      brandDetails {
        id
        name
        __typename
      }
      homeLocationDetails {
        id
        name
        __typename
      }
      contracts {
        items {
          id
          description
          membershipName
          startDate
          isActive
          locationId
          membershipId
          costPriceFromMembership
          membershipLocationId
          paymentFrequency {
            type
            amount
            __typename
          }
          # joinedLocationDetail {
          #   name
          #   shortName
          #   __typename
          # }
          billings {
            items {
              isProcessed
              debitDate
              debitAmount
              currency
              id
              membershipAmount
              description
              locationId
              memberContractId
              memberId
              membershipId
              membershipLocationId
              payLocationId
              paymentType
              transactionId
              orderId
              # directDebitLog {
              #   items {
              #     id
              #     billingId
              #     updatedBy
              #     description
              #     debitAmountNew
              #     debitAmountOriginal
              #     reason
              #     createdAt
              #     updaterDetails {
              #       memberId
              #       createdAt
              #       givenName
              #       surname
              #       __typename
              #     }
              #     __typename
              #   }
              #   __typename
              # }
              __typename
            }
            __typename
          }
          __typename
        }
        __typename
      }
      # paymentDetails {
      #   items {
      #     accountNumber
      #     accountName
      #     bsb
      #     cardCvv
      #     cardExpiryDate
      #     cardHolderName
      #     cardNumber
      #     cardType
      #     id
      #     paymentType
      #     __typename
      #   }
      #   __typename
      # }
      __typename
    }
  }
`;

export const getClassBookingsByClassScheduleId = gql`
  query getClassBookingsByClassScheduleId($classScheduleId: ID!) {
    getClassBookingsByClassScheduleId(classScheduleId: $classScheduleId) {
      items {
        hasAttended
        brandId
        confirmedDateTime
        classTemplateId
        locationId
        memberId
      }
    }
  }
`;

export const updateMemberInfo = gql`
  mutation updateMemberInfo($input: UpdateMemberInput!) {
    updateMember(input: $input) {
      dob
      gender
      givenName
      homeLocationId
      imageUrl
      joinLocationId
      memberId
      postCode
      state
      suburb
      surname
      title
      workPhone
      secondaryEmail
      middleName
      mobileNumber
      emergencyContactRelation2
      emergencyContactRelation
      emergencyContactNumber2
      emergencyContactNumber
      emergencyContactName2
      emergencyContactName
      emergencyContactEmail2
      emergencyContactEmail
      country
      address
    }
  }
`;

export const chargeSinglePaymentViaVivaPay = gql`
  mutation chargeSinglePaymentViaVivaPay(
    $amount: Float!
    $memberId: ID!
    $futurePaymentOption: FUTURE_PAYMENT_OPTION!
    $operation: TRANSACTION_TYPE_MAPPING!
    $paymentInformationId: ID!
    $changeMembershipArgumentsInput: ChangeMembershipArgumentsInput
    $bankAccountDetails: BankAccountInput
    $suspensionArguments: SuspensionArgumentsInput
    $addMembershipArguments: AddMembershipArguments
  ) {
    chargeSinglePaymentViaVivaPay(
      amount: $amount
      memberId: $memberId
      futurePaymentOption: $futurePaymentOption
      operation: $operation
      paymentInformationId: $paymentInformationId
      changeMembershipArguments: $changeMembershipArgumentsInput
      bankAccountDetails: $bankAccountDetails
      suspensionArguments: $suspensionArguments
      addMembershipArguments: $addMembershipArguments
    ) {
      statusCode
      message
    }
  }
`;
export const addMemberPaymentInformation = gql`
  mutation addMemberPaymentInformation(
    $input: AddMemberPaymentInformationRequest!
  ) {
    addMemberPaymentInformation(input: $input) {
      cardRequestId
      paymentInformationId
      cardNumber
      cardType
      cardHolderName
      cardExpiryDate
      statusCode
      message
      error
    }
  }
`;
export const suspendMembershipMedical = gql`
  mutation suspendMedicalMembership(
    $memberContractId: ID
    $suspensionId: ID
    $suspendFrom: AWSDate
    $suspendTo: AWSDate
    $suspensionReason: String
    $imageUrl: String
    $operation: MEDICAL_SUSPENSION_OPERATION!
    $suspensionEndDate: AWSDate
  ) {
    suspendMedicalMembership(
      suspensionId: $suspensionId
      memberContractId: $memberContractId
      suspendFrom: $suspendFrom
      suspendTo: $suspendTo
      suspensionReason: $suspensionReason
      imageUrl: $imageUrl
      operation: $operation
      suspensionEndDate: $suspensionEndDate
    ) {
      statusCode
      data {
        message
      }
    }
  }
`;

export const SignupMemberOver18 = gql`
  mutation SignupMemberViaWebOver18($input: SignupMemberViaWebRequest!) {
    signupMemberViaWebOver18(input: $input) {
      memberId
      message
    }
  }
`;
export const registerMutationUnder18 = gql`
  mutation signupMemberViaWebUnder18(
    $input: SignupMemberViaWebUnder18Request!
  ) {
    signupMemberViaWebUnder18(input: $input) {
      memberId
      message
      error
    }
  }
`;
export const preRegisterMutation = gql`
  mutation signupMemberViaWebPreparePaymentOver18(
    $input: SignupMemberViaWebPreparePaymentRequest!
  ) {
    signupMemberViaWebPreparePaymentOver18(input: $input) {
      memberId
      tokenId
      prospectId
      message
    }
  }
`;
export const preRegisterMutationUnder18 = gql`
  mutation signupMemberViaWebPreparePaymentUnder18(
    $input: SignupMemberViaWebPreparePaymentUnder18Request!
  ) {
    signupMemberViaWebPreparePaymentUnder18(input: $input) {
      memberId
      tokenId
      prospectId
      message
    }
  }
`;
export const suspendMemberShip = gql`
  mutation suspendMembership(
    $memberContractId: ID!
    $suspendFrom: AWSDate!
    $suspendTo: AWSDate!
    $suspensionAmount: Float!
    $suspensionReason: String
    $imageUrl: String
    $suspensionType: SUSPENSION_TYPE!
  ) {
    suspendMembership(
      operation: CREATE_SUSPENSION
      memberContractId: $memberContractId
      suspendFrom: $suspendFrom
      suspendTo: $suspendTo
      suspensionAmount: $suspensionAmount
      suspensionReason: $suspensionReason
      imageUrl: $imageUrl
      suspensionType: $suspensionType
    ) {
      statusCode
    }
  }
`;
export const suspendMemberShipMedical = gql`
  mutation suspendMedicalMembership(
    $memberContractId: ID
    $suspensionId: ID
    $suspendFrom: AWSDate
    $suspendTo: AWSDate
    $suspensionReason: String
    $imageUrl: String
    $operation: MEDICAL_SUSPENSION_OPERATION!
    $suspensionEndDate: AWSDate
  ) {
    suspendMedicalMembership(
      suspensionId: $suspensionId
      memberContractId: $memberContractId
      suspendFrom: $suspendFrom
      suspendTo: $suspendTo
      suspensionReason: $suspensionReason
      imageUrl: $imageUrl
      operation: $operation
      suspensionEndDate: $suspensionEndDate
    ) {
      statusCode
      data {
        message
      }
    }
  }
`;

export const cancelMemberShip = gql`
  mutation cancelMemberShip(
    $memberId: ID!
    $memberContractId: ID!
    $endDate: AWSDate!
    $updatedBy: ID
  ) {
    cancelMembership(
      memberId: $memberId
      memberContractId: $memberContractId
      endDate: $endDate
      updatedBy: $updatedBy
    )
  }
`;

export const listBrands = gql`
  query listBrands {
    listBrands {
      items {
        id
        description
        name
      }
    }
  }
`;

export const bookAClass = gql`
  mutation bookClass($classScheduleId: ID!, $memberId: ID!) {
    bookClass(classSchedule: $classScheduleId, memberId: $memberId) {
      bookingId
      message
    }
  }
`;

export const cancelBooking = gql`
  mutation MyMutation($bookingId: ID!) {
    cancelBooking(bookingId: $bookingId)
  }
`;
export const revokeMembershipCancellation = gql`
  mutation revokeMembershipCancellation(
    $memberContractId: ID!
    $memberId: ID!
  ) {
    revokeMembershipCancellation(
      memberContractId: $memberContractId
      memberId: $memberId
    )
  }
`;

export const changeMembership = gql`
  mutation changeMembership(
    $memberId: ID!
    $membershipLocationId: ID!
    $newMembershipId: ID!
    $oldMemberContractId: ID!
    $getInformation: Boolean!
  ) {
    changeMembership(
      memberId: $memberId
      membershipLocationId: $membershipLocationId
      newMembershipId: $newMembershipId
      oldMemberContractId: $oldMemberContractId
      getInformation: $getInformation
    ) {
      message
      newMemberContractId
      prorata
      downgradeFee
      debitCost
      upfrontPaymentAmount
      firstDDDate
    }
  }
`;
// export const getWaitlistClassBookingsByClassScheduleId = gql`
// query getWaitlistClassBookingsByClassScheduleId(waitlistClassScheduleId: "e32d8526-070b-4d12-97dc-89285dbf45a4") {
//   items {
//     cancelledDateTime
//     memberId
//   }
// }`;
export const revokeMembershipSuspension = gql`
  mutation revokeMembershipSuspension(
    $reason: String
    $suspensionEndDate: AWSDate!
    $suspensionId: ID!
  ) {
    revokeMembershipSuspension(
      reason: $reason
      suspensionEndDate: $suspensionEndDate
      suspensionId: $suspensionId
    ) {
      statusCode
      message
    }
  }
`;
export const changeSuspensionsDate = gql`
  mutation suspendMembership($suspensionEndDate: AWSDate!, $suspensionId: ID!) {
    suspendMembership(
      suspensionEndDate: $suspensionEndDate
      operation: EDIT_SUSPENSION
      suspensionId: $suspensionId
      suspensionReason: "userSuspend"
    ) {
      statusCode
      data {
        amount
        message
      }
    }
  }
`;
export const cancelSuspension = gql`
  mutation suspendMembership($suspensionId: ID!) {
    suspendMembership(
      suspensionId: $suspensionId
      operation: CANCEL_SUSPENSION
    ) {
      statusCode
    }
  }
`;
export const consentForm = gql`
  mutation submitConsentForm(
    $arequestionsAnswered: Boolean!
    $memberId: ID!
    $signature: String!
  ) {
    submitConsentForm(
      arequestionsAnswered: $arequestionsAnswered
      memberId: $memberId
      operationName: "submitConsentForm"
      signature: $signature
    )
  }
`;
export const healthQuestionsByBrandId = gql`
  query getHealthQuestionnairesByBrandId {
    getHealthQuestionnairesByBrandId(
      brandId: "6dec4e5f-7a07-4a7e-a809-2c0c1df01366"
      filter: { isDeleted: { eq: false }, isActive: { eq: true } }
    ) {
      items {
        createdAt
        brandId
        expectedAnswer
        id
        isActive
        isDeleted
        question
        mandatory
        answerType
        sectionId
        sectionLabel
      }
      nextToken
    }
  }
`;
export const UPFRONT_SUSPENSION = gql`
  mutation suspendMembership(
    $operation: SUSPENSION_OPERATION!
    $suspensionId: ID!
    $suspensionEndDate: AWSDate
  ) {
    suspendMembership(
      operation: $operation
      suspensionId: $suspensionId
      suspensionEndDate: $suspensionEndDate
    ) {
      statusCode
      data {
        amount
        message
      }
    }
  }
`;
export const UPFRONT_SUSPENSION_MEDICAL = gql`
  mutation suspendMedicalMembership(
    $operation: MEDICAL_SUSPENSION_OPERATION!
    $suspensionId: ID!
    $suspensionEndDate: AWSDate
  ) {
    suspendMedicalMembership(
      operation: $operation
      suspensionId: $suspensionId
      suspensionEndDate: $suspensionEndDate
    ) {
      statusCode
      data {
        amount
        message
      }
    }
  }
`;

export const LIST_TRANSACTION_BY_MEMBERID = gql`
  query getTransactionByMemberId($memberId: ID!) {
    getTransactionByMemberId(memberId: $memberId) {
      nextToken
      items {
        amount
        billingCreditId
        createdAt
        currency
        id
        orderId
        locationId
        memberContractId
        memberContractSuspensionId
        memberId
        membershipId
        message
        paymentGatewayLogId
        status
        transactionId
        type
        gatewayCode
      }
    }
  }
`;

export const receiptDownload = gql`
  mutation MyMutation($billingId: ID!, $brandId: ID!, $memberId: ID!) {
    downloadReceipt(
      downloadReceiptArguments: {
        billingId: $billingId
        brandId: $brandId
        memberId: $memberId
      }
    ) {
      message
      receiptKey
    }
  }
`;
