import React, { useState, useEffect, useContext, useMemo } from 'react';
// import arrowLeftShort from "../../../assets/images/icons/arrowLeftShort.svg";
import TableBase from '@/common/tables/TableBase';
import DashBoardLayout from '@/components/layout/DashBoardLayout';
import InputBase from '@/common/inputs/InputBase';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_green.css';
import CheckboxActive from '@/common/buttons/CheckboxActive';
import { Link } from 'gatsby';
// import { jsFileDownloader } from "js-client-file-downloader";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import plusLogoBlack from '@/assets/images/plus-logo-black.png';
import { AuthContext } from '@/api/auth';
import {
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  LIST_TRANSACTION_BY_MEMBERID,
  getMembershipById,
  receiptDownload,
} from '@/api/mutation';
import { toastError } from '@/common/toasts/toast';
import { Storage } from 'aws-amplify';

function Biling(props) {
  const userInfo = useContext(AuthContext).userInfo;

  const [startDate, setStartDate] = useState<string>('');

  useEffect(() => {
    console.log({ startDate });
  }, [startDate]);

  const [year, setYear] = useState('');
  // const [typeOfPayment, setTypeOfPayment] = useState('card');

  const [dataBodyPast, setDataBodyPast] = useState([
    {
      date: '08-Jul 2021',
      item: 'Direct debit account ending in **** 4453',
      amount: '$50.80',
    },
    {
      date: '08-Jul 2021',
      item: 'Direct debit account ending in **** 4453',
      amount: '$50.80',
    },
    {
      date: '08-Jul 2021',
      item: 'Direct debit account ending in **** 4453',
      amount: '$50.80',
    },
  ]);
  // const [isUpdate, setUpdate] = useState(false);
  // const [userData, setUserData] = useState();
  const [getMembershipByIdApi] = useLazyQuery(getMembershipById);
  const client = useApolloClient();

  const [listTransactionByMemberId] = useLazyQuery(
    LIST_TRANSACTION_BY_MEMBERID
  );

  const [downloadReceipt] = useMutation(receiptDownload);
  const dataHeadPast = [
    'date',
    'order Id',
    'amount',
    {
      label: 'action',
      elm: (i) => {
        const downPDF = async () => {
          try {
            const data = await downloadReceipt({
              variables: {
                billingId: i.id,
                brandId: '6dec4e5f-7a07-4a7e-a809-2c0c1df01366',
                memberId: userInfo.memberId,
              },
            });
            console.log({ data });
            const file = await Storage.get(
              data.data.downloadReceipt.receiptKey
            );
            const link = document.createElement('a');
            link.href = file;
            link.target = '_blank';
            link.download = 'receipt';
            link.click();
          } catch (e) {
            //  {
            //   const checkDay = () => {
            //     let dateList = [...i.data.billings.items];
            //     dateList = dateList
            //       .filter((i) => i.isProcessed)
            //       .sort(
            //         (a, b) =>
            //           new Date(a.debitDate).valueOf() -
            //           new Date(b.debitDate).valueOf()
            //       )
            //       .map((i) => i.debitDate);
            //     const isFirst = dateList.findIndex((item) => item === i.date);
            //     if (dataBodyPast.length == 1) return true;
            //     if (dataBodyPast.length > 1 && isFirst == 0) return true;
            //     return false;
            //   };
            //   const firstDate = checkDay();
            //   const dataTransaction = await listTransactionByMemberId({
            //     variables: { memberId: userInfo.memberId },
            //   });

            //   // const orderId =
            //   //   dataTransaction.data.getTransactionByMemberId.items.filter(
            //   //     (item) => item.id === i.currentItem.transactionId
            //   //   )[0];
            //   const data = (
            //     await getMembershipByIdApi({
            //       variables: { id: i.data.membershipId },
            //     })
            //   ).data.getMembership;
            //   client.refetchQueries({
            //     include: ['getMembership'],
            //   });
            //   const abn = data.brandDetails.locations.items.filter(
            //     (item) => item.id === i.data.locationId
            //   )[0].abn;
            //   const doc = new jsPDF({
            //     orientation: 'p',
            //     unit: 'px',
            //     format: ['276', firstDate ? '475' : '448'],
            //     putOnlyUsedFonts: true,
            //   });
            //   // doc.addFont('ArialMS', 'Arial', 'normal');
            //   // doc.setFont('Arial');
            //   doc.addImage(plusLogoBlack, 95, 12, 90, 35, '', false);
            //   doc.setFontSize(10);
            //   //   doc.textEx('Hi How are you', 75, 90, {
            //   //     align: 'right',
            //   // } );
            //   doc.text('Viva Leisure Operations Pty Limited', 138, 60, {
            //     align: 'center',
            //   });

            //   doc.setFontSize(11);
            //   doc.text('Tax Invoice', 138, 80, {
            //     align: 'center',
            //   });
            //   doc.text('ABN: ' + abn, 138, 100, {
            //     align: 'center',
            //   });
            //   doc.text('Original', 138, 120, {
            //     align: 'center',
            //   });
            //   doc.text(
            //     'Receipt date ' + i.date.split('-').reverse().join('/'),
            //     16,
            //     140
            //   );

            //   doc.text('Node No 99', 16, 161);

            //   doc.text('Receipt No 489966', 16, 181);

            //   doc.setLineWidth(1.0);
            //   doc.line(16, 192, 260, 192);
            //   // var splitTitle = doc.splitTextToSize(i.item, 18);
            //   let y = 210;
            //   doc.text('1x ' + data.membershipName, 16, y, { maxWidth: 200 });
            //   const price = data?.costPrice;
            //   doc.text('$' + price, 215, y);
            //   y += 20;

            //   doc.text('1x Joinining fee', 16, y);
            //   doc.text('$' + data.joiningFee, 215, y);
            //   y += 20;

            //   doc.text('1x Activation fee', 16, y);
            //   doc.text('$' + data.joiningFee2, 215, y);
            //   y += 20;
            //   doc.text('1x Card/Fob fee', 16, y);
            //   doc.text('$' + data.joiningFee3, 215, y);
            //   y += 9;
            //   doc.setLineWidth(1.0);
            //   doc.line(16, y, 260, y);
            //   y += 14;
            //   doc.text('Total Sales', 16, y);
            //   doc.text('' + i.amount, 215, y);
            //   y += 20;
            //   doc.text('Total GST', 16, y);
            //   doc.text('$00.00', 215, y);
            //   y += 20;
            //   doc.text('Paid By MASTERCARD', 16, y);
            //   // doc.text('$00.00', 215, y)
            //   y += 20;
            //   doc.text('Change Due', 16, y);
            //   doc.text('$00.00', 215, y);
            //   y += 11;
            //   doc.setLineWidth(1.0);
            //   doc.line(16, y, 260, y);
            //   y += 16;
            //   doc.setFontSize(10);
            //   doc.text(
            //     'Name: ' + userInfo.givenName + ' ' + userInfo.surname,
            //     16,
            //     y
            //   );
            //   y += 20;
            //   doc.text('Contract number: ' + userInfo.memberId, 16, y);
            //   y += 20;
            //   doc.text('Member # ' + userInfo.aliasMemberId, 16, y);
            //   y += 20;
            //   doc.text('Order ID ' + i.currentItem.orderId, 16, y);
            //   y += 20;
            //   doc.text('Online Payment IP 2000:8003:5632:fgt23:5693', 16, y);
            //   // y+26 =height
            //   doc.save('Receipt-' + i.date.split('-').reverse().join('/'));
            // }
            toastError(
              'There is some error when grab data about your billing please try again later'
            );
            console.log(e.message);
          }
        };
        return (
          <div
            onClick={() => {
              downPDF();
            }}
          >
            {' '}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-arrow-bar-to-down"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              fill="none"
              strokeLinecap="round"
              stroke-linejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <line x1="4" y1="20" x2="20" y2="20" />
              <line x1="12" y1="14" x2="12" y2="4" />
              <line x1="12" y1="14" x2="16" y2="10" />
              <line x1="12" y1="14" x2="8" y2="10" />
            </svg>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    const data = props.userInfo.contracts?.items.map((i) =>
      i.billings.items
        .filter((i) => i.isProcessed)
        .map((item) => {
          return {
            id: item.id,
            date: item.debitDate.split('-').reverse().join('/'),
            'order Id': item.orderId,
            amount: '$' + item.debitAmount,
            data: { ...i },
            currentItem: { ...item },
          };
        })
    );
    setDataBodyPast(data?.flat());
  }, [props.userInfo]);
  return (
    <div className="text-white">
      {/* <div className="mt-[38px]  pb-[32px] lg:h-[442px] border border-gray rounded  relative">
        <div className="h-full w-full flex justify-center items-center absolute z-10 bg-gray ">
          <div className="text-3xl text-brand-clime gotham">
            No info at the moment
          </div>
        </div>
        <div className="h-[57px] px-[27px]  border-b border-b-white text-white tracking-widest gotham text-sm  flex items-center uppercase">
          payment method
        </div>
        <div className="mt-[29px] flex lg:flex-row flex-col lg:items-center items-start   justify-between">
          <div className="flex items-center lg:w-auto lg:pl-[27px] w-full">
            <button
              onClick={() => setTypeOfPayment("card")}
              className={` uppercase h-[40px] rounded-l lg:w-[150px] w-[50vw] text-[11px] gotham font-bold  ${typeOfPayment !== "card"
                ? "border border-white text-white"
                : "border-white bg-brand-clime text-black pt-[3px]"
                } border-t border-l  border-r`}
            >
              BANK ACCOUNT
            </button>
            <button
              onClick={() => setTypeOfPayment("bank")}
              className={` uppercase h-[40px] rounded-r lg:w-[150px] w-[50vw] text-[11px] gotham font-bold  ${typeOfPayment !== "bank"
                ? "border border-white text-white"
                : "border-white text-black bg-brand-clime pt-[3px]"
                } border-t border-l  border-r`}
            >
              CREDIT CARD
            </button>
          </div>
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-y-[14px]  mt-[33px] px-[27px] ">
          <InputBase
            isDashboard={true}
            holder="Account name*"
            style="lg:w-full"
            isRequired={true}
          />
          <InputBase
            isDashboard={true}
            holder="Bank name*"
            style="lg:w-full"
            isRequired={true}
          />
          <InputBase
            isDashboard={true}
            holder="BOB*"
            style="lg:w-full"
            isRequired={true}
          />
          <InputBase
            isDashboard={true}
            holder="Account number*"
            style="lg:w-full"
            isRequired={true}
          />
        </div>
        <div className="mt-[37px] flex items-center pl-[27px] gap-[14px]">
          <div className="w-max">
            <CheckboxActive isDashboard={true} />
          </div>

          <p className="text-white gotham font-normal w-[542px] text-[11px]">
            I understand that these new details will replace the account details
            in my Direct Debit Request Service agreement with Viva Leisure
            Group.
          </p>
        </div>
        <button className="mt-[25px] text-[11.2px] uppercase rounded border  transition-all ml-[27px] border-gray text-white w-[146px] h-[36px] hover:bg-white hover:text-black">
          update
        </button>
      </div> */}

      <div className="mt-[45px] sm:mt-[32px]  pb-[30px] sm:border-none sm:bg-transparent border-[rgba(255,255,255,0.4)] border-[1.5px] mb-[90px] rounded">
        <div className="h-[57px] font-bicyclette px-[27px] font-medium text-sm tracking-[1.4px] sm:border-primary sm:text-primary sm:px-0 sm:h-[28px] sm:text-[20px] sm:pb-[10px] sm:mx-6 border-b border-b-white text-white  flex items-center uppercase">
          purchase history
        </div>
        <div className="">
          <div className="mt-[34px] sm:mt-[20px] lg:flex-col  flex-row flex  items-center justify-between mx-[30.5px]">
            <label>
              <div
                className={`w-[400px] lg:w-full border-b flex sm:h-[50px]  h-[59px] pb-[2px]  relative ${
                  startDate !== '' ? 'border-b-gray' : 'border-b-gray'
                } `}
              >
                <div className="absolute billingFP  bottom-[6px] w-full">
                  <Flatpickr
                    // placeHolder=""
                    onChange={(dateObj, dateStr) => setStartDate(dateObj)}
                    options={{
                      dateFormat: 'd/m/Y',
                      mode: 'range',
                      disableMobile: 'true',
                      enableTime: false,
                      time_24hr: false,
                      allowInput: false,
                    }}
                  />
                </div>
                <label
                  className={`gotham text-[12px] absolute left-0 ${
                    startDate !== ''
                      ? 'text-white top-[2px]'
                      : 'text-gray bottom-[14px]'
                  }`}
                >
                  Date range
                </label>
                <div className="absolute  z-10 right-3 bottom-3">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="19"
                    viewBox="0 0 20 19"
                    fill="none"
                  >
                    <g clipPath="url(#clip0_1_1209)">
                      <path
                        d="M4.09005 5.14832H4.09376C4.70159 5.14832 5.19434 4.65957 5.19434 4.05669L5.19434 1.08795C5.19434 0.485065 4.70159 -0.00366497 4.09376 -0.00366497H4.09005C3.48222 -0.00366497 2.98947 0.485065 2.98947 1.08795L2.98947 4.05669C2.98947 4.65957 3.48222 5.14832 4.09005 5.14832Z"
                        fill="white"
                      />
                      <path
                        d="M16.0813 5.14832H16.085C16.6928 5.14832 17.1855 4.65957 17.1855 4.05669V1.08795C17.1855 0.485065 16.6928 -0.00366497 16.085 -0.00366497H16.0813C15.4734 -0.00366497 14.9807 0.485065 14.9807 1.08795V4.05669C14.9807 4.65957 15.4734 5.14832 16.0813 5.14832Z"
                        fill="white"
                      />
                      <path
                        d="M1.52086 18.9999H18.4906C18.8923 18.9989 19.2772 18.84 19.5608 18.5579C19.8445 18.2758 20.0039 17.8937 20.0039 17.4953V3.57717C20.0039 3.17875 19.8445 2.79659 19.5608 2.51452C19.2772 2.23245 18.8923 2.07349 18.4906 2.07251H1.52086C1.11917 2.07349 0.734266 2.23245 0.450575 2.51452C0.166884 2.79659 0.00756715 3.17875 0.00756836 3.57717V17.4953C0.00756715 17.8937 0.166884 18.2758 0.450575 18.5579C0.734266 18.84 1.11917 18.9989 1.52086 18.9999V18.9999ZM1.72908 6.82989H18.2823V16.979H1.72164L1.72908 6.82989Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_1209">
                        <rect width="20" height="19" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </label>
            
          </div>
          <div className="sm:hidden mx-[30.5px] mt-[35px]">
            <TableBase
              data="download"
              headerCells={dataHeadPast}
              bodyCells={dataBodyPast}
            />
          </div>
          <div className="sm:block hidden mx-6 mt-[36px]">
            {dataBodyPast.map((i, index: number) => (
              <div
                key={index}
                className="grid items-center grid-cols-2 border-b border-textSecondary py-[12px]"
              >
                <div className="text-[16px] font-bold h-[26px] flex items-center">
                  {i.date.split('-').reverse().join('/')}
                </div>
                <div className="text-end font-bold h-[26px]flex items-center">
                  {i.amount}
                </div>
                <div className="col-span-2 py-[5px] flex items-center min-h-[26px] text-textSecondary">
                  {i.item}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Biling;
